import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Calendar } from 'src/app/utils/Calendar';

@Component({
  selector: 'app-frequency-editor',
  templateUrl: './frequency-editor.component.html',
  styleUrls: ['./frequency-editor.component.scss']
})
export class FrequencyEditorComponent implements OnInit {

  @Input() currentSearch: any;
  @Input() title = "Frequencia"
  publishSearchSetup: any = {
    never_expires: false,
    frequency_group: "dates"
  };

  search_setup;

  constructor() { }

  updateListener = () => {}

  ngOnInit(): void {

    this.search_setup = this.currentSearch.search_setup;
    let frequency_type = this.search_setup.frequency_type;


    switch (frequency_type) {
      case "indeterminate":

        this.publishSearchSetup = Object.assign({
          frequency_type: frequency_type,
          init_date: "",
          end_date: "",
          never_expires: false,
          frequency_group: "dates"
        });

        this.publishSearchSetup.init_date = Calendar.getFormattedDateToInputDateFormat(new Date(this.search_setup.init_date));
        this.publishSearchSetup.end_date = Calendar.getFormattedDateToInputDateFormat(new Date(this.search_setup.end_date));
        this.publishSearchSetup.never_expires = this.search_setup.never_expires;

        break;
      case "date_range":

        this.publishSearchSetup = Object.assign({
          frequency_type: frequency_type,
          init_date: "",
          end_date: "",
          never_expires: false,
          frequency_group: "dates"
        });

        this.publishSearchSetup.init_date = Calendar.getFormattedDateToInputDateFormat(new Date(this.search_setup.init_date));
        this.publishSearchSetup.end_date = Calendar.getFormattedDateToInputDateFormat(new Date(this.search_setup.end_date));
        this.publishSearchSetup.never_expires = this.search_setup.never_expires;

        break;
      case "custom":
        this.publishSearchSetup = Object.assign({
          frequency_type: frequency_type,
          never_expires: false,
          frequency_group: "custom",
          custom_frequency_list: []
        });
        delete this.search_setup.init_date;
        delete this.search_setup.end_date;
        this.publishSearchSetup.custom_frequency_list = this.search_setup.custom_frequency_list;

        break
    }

  }

  getFormattedDateToInputDateFormat(value){
    return Calendar.getFormattedDateToInputDateFormat(new Date(value))
  }

  ngAfterViewChecked() {

    this.setCheckedExpiresCheckBox();

  }


  firstChecked = false;

  setCheckedExpiresCheckBox() {

    if (this.firstChecked) {
      return
    }

    let checkboxExpires = <HTMLInputElement>document.getElementById("checkboxExpires");

    if (!checkboxExpires) {
      return;
    };

    let endDateExpires = <HTMLInputElement>document.getElementById("endDateExpires");

    checkboxExpires.checked = this.publishSearchSetup.never_expires;
    endDateExpires.disabled = this.publishSearchSetup.never_expires;

    this.firstChecked = true;


  }

  updateFrequency(frequency_type) {


    this.publishSearchSetup.frequency_type = frequency_type;

    this.search_setup.frequency_type = frequency_type;

    switch (frequency_type) {
      case "indeterminate":
        this.publishSearchSetup.frequency_group = "dates";
        this.publishSearchSetup.never_expires = true;
        break
      case "date_range":
        this.publishSearchSetup.frequency_group = "dates";
        this.publishSearchSetup.never_expires = false;
        break
      case "custom":
        this.publishSearchSetup.frequency_group = "custom";
        
        if (!this.publishSearchSetup.custom_frequency_list)
          this.publishSearchSetup.custom_frequency_list = [];
        this.updateListener();
        return;
    }

    this.search_setup.frequency_group = this.publishSearchSetup.frequency_group;

    let checkboxExpires = <HTMLInputElement>document.getElementById("checkboxExpires");

    if (checkboxExpires == null) {
      this.firstChecked = false;
    }

    if (checkboxExpires) {

      this.firstChecked = false;

      this.setCheckedExpiresCheckBox()

      this.setExpires(checkboxExpires);
    }

    this.updateListener();

  }


  setInitDate(value) {

    this.search_setup.init_date = Calendar.getDateFromInputDateFormat(value).getTime();

    this.updateListener();

  }

  setEndDate(value) {

    this.search_setup.end_date = Calendar.getDateFromInputDateFormat(value).getTime();

    this.updateListener();

  }

  setExpires(input: HTMLInputElement) {

    let endDateExpires = <HTMLInputElement>document.getElementById("endDateExpires");

    let never_expires = input.checked;

    if (never_expires) {
      this.search_setup.frequency_type = "indeterminate";

    } else {

      this.search_setup.frequency_type = "date_range";

    }

    endDateExpires.disabled = never_expires;

    this.publishSearchSetup.never_expires = never_expires;
    this.publishSearchSetup.frequency_type = this.search_setup.frequency_type;

    this.search_setup.never_expires = never_expires;


  }

  
  checkValids() {

    let errors = 0;

    let search_setup = this.currentSearch.search_setup;

    if (search_setup.frequency_type == "indeterminate") {
      if (!Calendar.isValidDate(new Date(search_setup.init_date))) {
        errors++;
      }
    }

    if (search_setup.frequency_type == "date_range") {
      if (!Calendar.isValidDate(new Date(search_setup.init_date))) {
        errors++;
      }
      if (!Calendar.isValidDate(new Date(search_setup.end_date))) {
        errors++;
      }
    }

    console.log("frequencyEditorErrors",errors);


    return errors;

  }

  onPublishListener(publishSearchSetup) {

    console.log(publishSearchSetup);

    this.publishSearchSetup = publishSearchSetup;

    this.currentSearch.search_setup = JSON.parse(JSON.stringify(publishSearchSetup));

    console.log(this.currentSearch.search_setup);

  }

  

}
