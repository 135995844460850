import { Component, EventEmitter, Injectable, Input, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import * as Excel from 'exceljs/dist/exceljs.min.js';

@Component({
    selector: 'import-modal',
    templateUrl: './import-modal.component.html',
    styleUrls: [
        './import-modal.component.scss'
    ]
})
export class ImportModalComponent {
    @Output() after = new EventEmitter();
    @ViewChild('content') content;

    @Input() headers = [];
    @Input() descriptions = [];
    @Input() name = 'Itens';

    submitting = false;

    workbook: any;
    items = [];
    sheets = [];
    importType = '';

    constructor(
        private modal: NgbModal,
        private toastr: ToastrService,
    ) { }


    open() {
        this.items = [];
        this.importType = '';
        return this.modal.open(this.content, { centered: true }).result;
    }

    loadFile(file) {
        if (file) {
            this.workbook = new Excel.Workbook();
            this.sheets = [];

            new Response(file).arrayBuffer().then(data => {
                this.workbook.xlsx.load(data).then(() => {
                    this.workbook.eachSheet((sheet, index) => {
                        this.sheets.push({ id: index, name: sheet.name });
                    });
                });
            });
        }
    }

    selectSheet(sheetId) {
        this.items = [];
        if (sheetId != '') {
            let firstRow = true;
            const sheet = this.workbook.getWorksheet(+sheetId);
            sheet.eachRow(row => {
                if (firstRow) {
                    firstRow = false;
                } else {
                    let index = 1;
                    let item = {};
                    for (let header of this.headers) {
                        if (row.getCell(index).value && row.getCell(index).value.result) {

                            item[header.key] = row.getCell(index).value.result;

                        } else {

                            item[header.key] = row.getCell(index).value;

                        }
                        index++;
                    }
                    this.items.push(item);
                }
            });
        }
    }

    changeImportType(importType) {
        this.importType = importType;
    }

    submit() {
        this.submitting = true;

        let clear = false;
        if (this.importType == 'clear') {
            clear = true;
        }

        if (this.items.length) {
            this.after.emit({ clear, items: this.items });
        } else {
            this.submitting = false;
            this.toastr.error('Arquivo não carregado, tente novamente', 'Ocorreu um erro');
        }
    }
    dismiss() {
        this.modal.dismissAll();
    }
}