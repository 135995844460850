import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { ListWithSearchPaginatedFilterComponent } from './list-with-search-paginated-filter.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [ListWithSearchPaginatedFilterComponent],
    declarations: [ListWithSearchPaginatedFilterComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ListWithSearchPaginatedFilterModule { }
