<div class="marcascontainer">
    <div class="indicators-container">
        <indicator 
            title="Jornada"
            [value]="headerData ? headerData.journey.display_value : ''"
            [loading]="loadingIndicators">
        </indicator>
        <indicator 
            title="Tempo PDV"
            [value]="headerData ? headerData.time_per_pdv.display_value : ''"
            [loading]="loadingIndicators">
        </indicator>
        <indicator 
            title="Tempo Deslocamento"
            [value]="headerData ? headerData.time_displacement.display_value : ''"
            [loading]="loadingIndicators">
        </indicator>
        <indicator 
            title="Deslocamento Km"
            [value]="headerData ? headerData.displacement.display_value : ''"
            [loading]="loadingIndicators">
        </indicator>
    </div>
    <h1>Rotina de Trabalho</h1>
    <!-- <filter people="true"></filter> -->
    <form [formGroup]="formGroup" (ngSubmit)="searchRotina()">
        <div class="filters">
            <div class="filtro" *ngIf="filtersData.filters.directors">
                <label>Diretor</label>
                <div class="field-select" *ngIf="!loadingFilters">
                    <select name="director" class="fields" formControlName="director" #d (change)="onChange(d.name)">
                        <option value="">Todos</option>
                        <ng-container *ngFor="let item of filtersData.filters.directors">
                            <option [value]="item.user_id">{{item.name}}</option>
                        </ng-container>
                    </select>
                    <span class="select-range">0 / {{filtersData.filters.directors.length}}</span>
                    <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
                </div>
                <div class="loadingMaterial" *ngIf="loadingFilters">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p>Carregando</p>
                </div>
            </div>
            <div class="filtro" *ngIf="filtersData.filters.regions">
                <label>Regional</label>
                <div class="field-select" *ngIf="!loadingFilters">
                    <select name="region" class="fields" formControlName="region" #r (change)="onChange(r.name)">
                        <option value="">Todos</option>
                        <ng-container *ngFor="let item of filtersData.filters.regions">
                            <option [value]="item.name">{{item.name}}</option>
                        </ng-container>
                    </select>
                    <span class="select-range">0 / {{filtersData.filters.regions.length}}</span>
                    <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
                </div>
                <div class="loadingMaterial" *ngIf="loadingFilters">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p>Carregando</p>
                </div>
            </div>
            <div class="filtro" *ngIf="filtersData.filters.filials">
                <label>Filial</label>
                <div class="field-select" *ngIf="!loadingFilters">
                    <select name="filial" class="fields" formControlName="filial" #f (change)="onChange(f.name)">
                        <option value="">Todos</option>
                        <ng-container *ngFor="let item of filtersData.filters.filials">
                            <option [value]="item.name">{{item.name}}</option>
                        </ng-container>
                    </select>
                    <span class="select-range">0 / {{filtersData.filters.filials.length}}</span>
                    <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
                </div>
                <div class="loadingMaterial" *ngIf="loadingFilters">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p>Carregando</p>
                </div>
            </div>
            <div class="filtro" *ngIf="filtersData.filters.managers">
                <label>Gestor</label>
                <div class="field-select" *ngIf="!loadingFilters">
                    <select name="manager" class="fields" formControlName="manager" #g (change)="onChange(g.name)">
                        <option value="">Todos</option>
                        <ng-container *ngFor="let item of filtersData.filters.managers">
                            <option [value]="item.user_id">{{item.name}}</option>
                        </ng-container>
                    </select>
                    <span class="select-range">0 / {{filtersData.filters.managers.length}}</span>
                    <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
                </div>
                <div class="loadingMaterial" *ngIf="loadingFilters">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p>Carregando</p>
                </div>
            </div>
            <div class="filtro" *ngIf="filtersData.filters.coordinators">
                <label>Coordenador</label>
                <div class="field-select" *ngIf="!loadingFilters">
                    <select name="coordinator" class="fields" formControlName="coordinator" #c
                        (change)="onChange(c.name)">
                        <option value="">Todos</option>
                        <ng-container *ngFor="let item of filtersData.filters.coordinators">
                            <option [value]="item.user_id">{{item.name}}</option>
                        </ng-container>
                    </select>
                    <span class="select-range">0 / {{filtersData.filters.coordinators.length}}</span>
                    <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
                </div>
                <div class="loadingMaterial" *ngIf="loadingFilters">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p>Carregando</p>
                </div>
            </div>
            <div class="filtro" *ngIf="filtersData.filters.supervisors">
                <label>Supervisor</label>
                <div class="field-select" *ngIf="!loadingFilters">
                    <select name="supervisor" class="fields" formControlName="supervisor" #s
                        (change)="onChange(s.name)">
                        <option value="">Todos</option>
                        <ng-container *ngFor="let item of filtersData.filters.supervisors">
                            <option [value]="item.user_id">{{item.name}}</option>
                        </ng-container>
                    </select>
                    <span class="select-range">0 / {{filtersData.filters.supervisors.length}}</span>
                    <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
                </div>
                <div class="loadingMaterial" *ngIf="loadingFilters">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p>Carregando</p>
                </div>
            </div>
            <div class="filtro" *ngIf="filtersData.filters.promoters">
                <label>Promotor</label>
                <div class="field-select" *ngIf="!loadingFilters">
                    <select name="promoter" class="fields" formControlName="promoter" #p (change)="onChange(p.name)">
                        <option value="">Todos</option>
                        <ng-container *ngFor="let item of filtersData.filters.promoters">
                            <option [value]="item.user_id">{{item.name}}</option>
                        </ng-container>
                    </select>
                    <span class="select-range">0 / {{filtersData.filters.promoters.length}}</span>
                    <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
                </div>
                <div class="loadingMaterial" *ngIf="loadingFilters">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p>Carregando</p>
                </div>
            </div>
            <!-- <div class="filtro" *ngIf="filtersData.filters.networks">
                <label>Rede</label>
                <div class="field-select" *ngIf="!loadingFilters">
                    <select name="network" class="fields" formControlName="network" #n (change)="onChange(n.name)">
                        <option value="">Todos</option>
                        <ng-container *ngFor="let item of filtersData.filters.networks">
                            <option [value]="item.name">{{item.name}}</option>
                        </ng-container>
                    </select>
                    <span class="select-range">0 / {{filtersData.filters.networks.length}}</span>
                    <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
                </div>
                <div class="loadingMaterial" *ngIf="loadingFilters">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p>Carregando</p>
                </div>
            </div>
            <div class="filtro" *ngIf="filtersData.filters.markets">
                <label>Lojas</label>
                <div class="field-select" *ngIf="!loadingFilters">
                    <select name="market" class="fields" formControlName="market" #mark (change)="onChange(mark.name)">
                        <option value="">Todos</option>
                        <ng-container *ngFor="let item of filtersData.filters.markets">
                            <option [value]="item.name">{{item.name}}</option>
                        </ng-container>
                    </select>
                    <span class="select-range">0 / {{filtersData.filters.markets.length}}</span>
                    <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
                </div>
                <div class="loadingMaterial" *ngIf="loadingFilters">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p>Carregando</p>
                </div>
            </div> -->

            <div class="filtro" *ngIf="tabSelected !== 'Visão Detalhada' ">
                <label>Filtrar por</label>
                <div class="field-select">
                    <select id="period" class="fields" formControlName="periods" (change)="changeType()">
                        <option value="">Selecione</option>
                        <option id="dia" value="dia">Dias</option>
                        <option id="periodos" value="periodos">Período</option>
                    </select>
                    <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
                </div>
            </div>
            <div class="filtro" *ngIf="period">
                <label for="data">Data Início</label>
                <input type="date" id="data" class="campos" formControlName="startDate" />
            </div>
            <div class="filtro" *ngIf="period">
                <label for="data">Data Final</label>
                <input type="date" id="data" class="campos" formControlName="finishDate" />
            </div>
            <div class="filtro" *ngIf="date">
                <label>Data</label>
                <div class="field-select">
                    <select name="" id="period" class="fields" formControlName="dataPeriodo">
                        <option value="">Selecione</option>
                        <option value="hoje">Hoje</option>
                        <option value="ontem">Ontem</option>
                        <option value="ultimosseven">Últimos 7 dias</option>
                        <option value="ultimosdias">Últimos 30 dias</option>
                    </select>
                    <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
                </div>
            </div>
            <div class="filtro">
                <label for="search">Pesquisar</label>
                <div class="field">
                    <input type="text" id="search" placeholder="Pesquisar" formControlName="search" class="campos" />
                    <img src="../../../assets/icons/search.svg" alt="Ícone de lupa">
                </div>
            </div>
            <div class="filtro">
                <button type="submit" [disabled]="loadingFilters ? 'disabled': null">
                    <span class="material-icons">search</span>
                    Procurar
                </button>
            </div>
        </div>
    </form>
    <div class="marcas-screen">
        <ul class="tab">
            <li class="tab-item" *ngFor="let selected of tabs" [ngClass]="{'active': tabSelected === selected}">
                <a (click)="openTabList(selected)" class="tab-link">{{ selected }}</a>
                <div class="divisoria"></div>
            </li>
        </ul>
        <ng-container *ngIf="tabSelected === 'Visão Detalhada' ">
            <div class="tableData">
                <div class="loading" *ngIf="loadingCards">
                    <div class="spinner-border text-warning" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div class="content-cards" *ngIf="!loadingCards">
                    <div class="cards"
                        *ngFor="let cardIt of dataCard.table_data | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                        <div class="card-rotina">
                            <div class="infos-pessoais">
                                <!-- <img src="../../../assets/img/foto.svg" alt="Foto do promotor" *ngIf="expression"> -->
                                <img [src]="cardIt.avatar_url" alt="Foto do promotor">
                                <p *ngIf="cardIt.status=='clearance'">Afastado</p>
                                <p *ngIf="cardIt.status=='active'">Ativo</p>
                                <p *ngIf="cardIt.status=='vocation'">Férias</p>
                                <p>{{cardIt.hiear}}</p>
                                <p id="regiao">{{cardIt.region}}</p>
                            </div>
                            <div class="dados-iniciais">
                                <div class="header">
                                    <div class="nome">
                                        <h5>{{cardIt.full_name}}</h5>
                                        <!-- <div class="status" *ngFor="let item of labelsstatus">
                                            <div class="itemstatus" [ngClass]="iconStatus(item)"></div>
                                            {{ cardIt }}
                                        </div> -->
                                    </div>
                                    <div class="mais" (click)="menuCard($event.target)">
                                        <svg width="6" height="18" viewBox="0 0 6 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1.59375 13.5938C2 13.1875 2.46875 12.9844 3 12.9844C3.53125 12.9844 4 13.1875 4.40625 13.5938C4.8125 14 5.01562 14.4688 5.01562 15C5.01562 15.5312 4.8125 16 4.40625 16.4062C4 16.8125 3.53125 17.0156 3 17.0156C2.46875 17.0156 2 16.8125 1.59375 16.4062C1.1875 16 0.984375 15.5312 0.984375 15C0.984375 14.4688 1.1875 14 1.59375 13.5938ZM1.59375 7.59375C2 7.1875 2.46875 6.98438 3 6.98438C3.53125 6.98438 4 7.1875 4.40625 7.59375C4.8125 8 5.01562 8.46875 5.01562 9C5.01562 9.53125 4.8125 10 4.40625 10.4062C4 10.8125 3.53125 11.0156 3 11.0156C2.46875 11.0156 2 10.8125 1.59375 10.4062C1.1875 10 0.984375 9.53125 0.984375 9C0.984375 8.46875 1.1875 8 1.59375 7.59375ZM4.40625 4.40625C4 4.8125 3.53125 5.01562 3 5.01562C2.46875 5.01562 2 4.8125 1.59375 4.40625C1.1875 4 0.984375 3.53125 0.984375 3C0.984375 2.46875 1.1875 2 1.59375 1.59375C2 1.1875 2.46875 0.984375 3 0.984375C3.53125 0.984375 4 1.1875 4.40625 1.59375C4.8125 2 5.01562 2.46875 5.01562 3C5.01562 3.53125 4.8125 4 4.40625 4.40625Z"
                                                fill="#50555A" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="indicadores">
                                    <div class="indic">
                                        <p>Visitas</p>
                                        <circle-progress [percent]="(cardIt.visits.real/cardIt.visits.meta)*100"
                                            [title]="cardIt.visits.display_value" [showSubtitle]="false"
                                            [showUnits]="false" [titleFontSize]="13" [titleColor]="'#50555A'"
                                            [titleFontWeight]="'600'" [radius]="30" [outerStrokeWidth]="5"
                                            [outerStrokeColor]="'#ED7624'" [innerStrokeWidth]="4"
                                            [innerStrokeColor]="'#F0F0F0'" [space]="1" [animation]="true"
                                            [animationDuration]="1000"></circle-progress>
                                    </div>
                                    <!-- <div class="indic">
                                        <p>Tarefas</p>
                                        <circle-progress [percent]="(cardIt.tasks.real/cardIt.tasks.meta)*100"
                                            [title]="cardIt.tasks.display_value" [showSubtitle]="false"
                                            [showUnits]="false" [titleFontSize]="13" [titleColor]="'#50555A'"
                                            [titleFontWeight]="'600'" [showUnits]="false" [radius]="30"
                                            [outerStrokeWidth]="5" [innerStrokeWidth]="4" [outerStrokeColor]="'#ED7624'"
                                            [innerStrokeColor]="'#F0F0F0'" [space]="1" [animation]="true"
                                            [animationDuration]="300"></circle-progress>
                                    </div> -->
                                    <!-- <div class="indic">
                                        <p>IMEX</p>
                                        <circle-progress [percent]="cardIt.pdv_ideal.total_points"
                                            [showSubtitle]="false" [titleColor]="'#50555A'" [titleFontSize]="'13'"
                                            [titleFontWeight]="'600'" [radius]="30" [outerStrokeWidth]="5"
                                            [innerStrokeWidth]="4" [outerStrokeColor]="'#ED7624'"
                                            [innerStrokeColor]="'#F0F0F0'" [space]="1" [animation]="true"
                                            [animationDuration]="300"></circle-progress>
                                    </div> -->
                                </div>
                            </div>
                            <div id="menu_card" class="menu-card" *ngIf="menuCardConfig.visible" [ngStyle]="{'top': menuCardConfig.top, 'left':menuCardConfig.left}">
                                <a>
                                    <span class="material-icons graycolor">chat_bubble</span>
                                    Smart Chat
                                </a>
                                <!-- <a (click)="modalDados(cardIt.user_id)">
                                    <span class="material-icons graycolor">remove_red_eye</span>
                                    Rotina dia
                                </a> -->
                                <a>
                                    <span class="material-icons graycolor">map</span>
                                    Roteiro
                                </a>
                            </div>
                        </div>
                        <div class="finish-card">
                            <div class="finish-information">
                                <p class="header">Jornada Dia</p>
                                <p>{{cardIt.journey_day}}</p>
                            </div>
                            <div class="finish-information">
                                <p class="header">Tempo PDV Dia</p>
                                <p>{{cardIt.time_of_pdv_day}}</p>
                            </div>
                            <div class="finish-information">
                                <p class="header">Deslocamento Dia</p>
                                <p>{{cardIt.displacement_day}}</p>
                            </div>
                            <ng-container>
                                <svg width="18" height="12" viewBox="0 0 18 12" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" (click)="modalDados(cardIt.user_id)">
                                    <path
                                        d="M7.41797 4.41797C7.86328 3.97266 8.39062 3.75 9 3.75C9.60938 3.75 10.1367 3.97266 10.582 4.41797C11.0273 4.86328 11.25 5.39062 11.25 6C11.25 6.60938 11.0273 7.13672 10.582 7.58203C10.1367 8.02734 9.60938 8.25 9 8.25C8.39062 8.25 7.86328 8.02734 7.41797 7.58203C6.97266 7.13672 6.75 6.60938 6.75 6C6.75 5.39062 6.97266 4.86328 7.41797 4.41797ZM6.32812 8.67188C7.07812 9.39844 7.96875 9.76172 9 9.76172C10.0312 9.76172 10.9102 9.39844 11.6367 8.67188C12.3867 7.92188 12.7617 7.03125 12.7617 6C12.7617 4.96875 12.3867 4.08984 11.6367 3.36328C10.9102 2.61328 10.0312 2.23828 9 2.23828C7.96875 2.23828 7.07812 2.61328 6.32812 3.36328C5.60156 4.08984 5.23828 4.96875 5.23828 6C5.23828 7.03125 5.60156 7.92188 6.32812 8.67188ZM3.97266 1.92188C5.47266 0.890625 7.14844 0.375 9 0.375C10.8516 0.375 12.5273 0.890625 14.0273 1.92188C15.5273 2.95312 16.6055 4.3125 17.2617 6C16.6055 7.6875 15.5273 9.04688 14.0273 10.0781C12.5273 11.1094 10.8516 11.625 9 11.625C7.14844 11.625 5.47266 11.1094 3.97266 10.0781C2.47266 9.04688 1.39453 7.6875 0.738281 6C1.39453 4.3125 2.47266 2.95312 3.97266 1.92188Z"
                                        fill="#909498" />
                                </svg>
                            </ng-container>
                        </div>
                        <p class="month-title">Acumulado Mês</p>
                        <div class="info-resum">
                            <div class="month-item">
                                <p class="title">Jornada Media</p>
                                <p>{{cardIt.accumulated_journey}}</p>
                            </div>
                            <div class="month-item">
                                <p class="title">Tempo PDV</p>
                                <p>{{cardIt.time_of_pdv}}</p>
                            </div>
                            <div class="month-item">
                                <p class="title">Deslocamento Médio</p>
                                <p>{{cardIt.displacement}}</p>
                            </div>
                            <div class="month-item">
                                <ng-container>
                                    <p class="title">Carteira</p>
                                    <p>{{cardIt.wallet_size}}</p>
                                </ng-container>
                            </div>
                            <div class="month-item">
                                <ng-container>
                                    <p class="title">Imex</p>
                                    <p>{{cardIt.pdv_ideal.display_value}}</p>
                                </ng-container>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="finishTable" *ngIf="!loadingCards">
                    <h3>Total: {{dataCard.total_values}}</h3>
                    <div class="paginator" id="paginator">
                        <pagination-controls (pageChange)="p = $event" nextLabel="Próximo" previousLabel="Anterior">
                        </pagination-controls>
                    </div>
                    <div></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="tabSelected === 'Relatório 1' ">
            <div class="error" *ngIf="!loadingTable && tableError">
                <div class="row">
                    <div class="col" id="error">
                        <p>
                            <span class="material-icons redcolor">info</span>
                            Erro ao buscar dados
                        </p>
                        <button (click)="getData()">Recarregar</button>
                    </div>
                </div>
            </div>
            <div class="tableData" *ngIf="!tableEmpty && !errorTable">
                <div class="loading">
                    <div class="spinner-border text-warning" role="status" *ngIf="loadingTable">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <ng-container *ngIf="rowsData.total_values > 0">
                    <div class="table" *ngIf="!loadingTable && !errorTable">
                        <div class="table1">
                            <table>
                                <thead>
                                    <tr>
                                        <th (click)="sortData('dia')">
                                            Data
                                            <img src="../../../assets/icons/arrow-table.svg" alt="ícone de seta para ordenar tabela" [class.sortIcon]="!item.sort" />
                                        </th>
                                        <th (click)="sortData('gestor')" >
                                            Nome do Gestor
                                            <img src="../../../assets/icons/arrow-table.svg" alt="ícone de seta para ordenar tabela" [class.sortIcon]="!item.sort" />
                                        </th>
                                        <th (click)="sortData('promotor')">
                                            Promotor
                                            <img src="../../../assets/icons/arrow-table.svg" alt="ícone de seta para ordenar tabela" [class.sortIcon]="!item.sort" />
                                        </th>
                                        <th>Filial</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr [attr.id]="'tr1_'+i"
                                        *ngFor="let item of rowsData.table_data  | paginate: { itemsPerPage: 10, currentPage: p }; let i = index"
                                        [ngClass]="{await: item.status == 'away_from_work' }">
                                        <td>{{item.date}}</td>
                                        <!--Data-->
                                        <td>{{item.manager}}</td> <!-- Nome do gestor-->
                                        <td>{{item.full_name}}</td> <!-- Promotor-->
                                        <td>{{item.filial}}</td> <!-- Filial-->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table2">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Início do Trabalho</th>
                                        <th>Fim do Expediente</th>
                                        <th>Primeiro Check-in</th>
                                        <th>Último Check-out</th>
                                        <th>Roteiro dia</th>
                                        <th>Realizado</th>
                                        <th>Fora de Rota</th>
                                        <th>Justificado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr [attr.id]="'tr2_'+i"
                                        *ngFor="let item of rowsData.table_data   | paginate: { itemsPerPage: 10, currentPage: p }; let i = index"
                                        [ngClass]="{await: item.status == 'away_from_work' }">
                                        {{resizeTr(i)}}
                                        <td>{{item.start_work}}</td> <!-- inicio do trabalho-->
                                        <td>{{item.end_work}}</td> <!-- fim do expediente-->
                                        <td>{{item.fist_checkin}}</td> <!-- primeiro check-in -->
                                        <td>{{item.last_checkout}}</td> <!-- ultimo check-out-->
                                        <td>{{item.route_of_day}}</td> <!-- roteiro dia-->
                                        <td>{{item.released}}</td> <!-- realizado -->
                                        <td>{{item.out_of_route}}</td> <!-- fora de rota-->
                                        <td>{{item.justified}}</td> <!-- Justificado-->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="finishTable">
                        <h3>Total: {{rowsData.total_values}}</h3>
                        <div class="paginator" id="paginator">
                            <pagination-controls (pageChange)="p = $event" nextLabel="Próximo" previousLabel="Anterior">
                            </pagination-controls>
                        </div>
                        <button (click)="exportTable()">
                            <img src="../../../../../../assets/icons/downloadIcon.svg" alt="Ícone de Download" />
                            Exportar Excel
                        </button>
                    </div>
                </ng-container>

                <div class="title-empty" *ngIf="!rowsData.total_values && !loadingTable">
                    <h3>Sem registros nessa data</h3>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="tabSelected === 'Relatório 2'">
            <div class="error" *ngIf="!loadingTable && tableError">
                <div class="row">
                    <div class="col" id="error">
                        <p>
                            <span class="material-icons redcolor">info</span>
                            Erro ao buscar dados
                        </p>
                        <button (click)="getData()">Recarregar</button>
                    </div>
                </div>
            </div>
            <div class="tableData">
                <div class="loading">
                    <div class="spinner-border text-warning" role="status" *ngIf="loadingTable">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div class="table" *ngIf="!loadingTable && !tableError && rowsData.total_values > 0">
                    <div class="table1">
                        <table>
                            <thead>
                                <tr>
                                    <th (click)="sortData('dia')">
                                        Data
                                        <img src="../../../assets/icons/arrow-table.svg" alt="ícone de seta para ordenar tabela" [class.sortIcon]="!item.sort" />
                                    </th>
                                    <th (click)="sortData('gestor')" >
                                        Nome do Gestor
                                        <img src="../../../assets/icons/arrow-table.svg" alt="ícone de seta para ordenar tabela" [class.sortIcon]="!item.sort" />
                                    </th>
                                    <th (click)="sortData('promotor')">
                                        Promotor
                                        <img src="../../../assets/icons/arrow-table.svg" alt="ícone de seta para ordenar tabela" [class.sortIcon]="!item.sort" />
                                    </th>
                                    <th>Filial</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr [attr.id]="'tr1_'+i"
                                    *ngFor="let item of rowsData.table_data  | paginate: { itemsPerPage: 10, currentPage: p }; let i = index"
                                    [ngClass]="{await: item.status == 'away_from_work' }">
                                    <td>{{item.date}}</td>
                                    <!--Data-->
                                    <td>{{item.manager}}</td> <!-- Nome do gestor-->
                                    <td>{{item.full_name}}</td> <!-- Promotor-->
                                    <td>{{item.filial}}</td> <!-- Filial-->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table2">
                        <table>
                            <thead>
                                <tr>
                                    <th>Produtividade</th>
                                    <th>Aderência IMEX</th>
                                    <th>Jornada TT Média</th>
                                    <th>Tempo PDV</th>
                                    <th>Previstos / PDVS Atendidos</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr [attr.id]="'tr2_'+i"
                                    *ngFor="let item of rowsData.table_data  | paginate: { itemsPerPage: 10, currentPage: p }; let i = index"
                                    [ngClass]="{await: item.status == 'away_from_work' }">
                                    {{resizeTr(i)}}
                                    <td>{{item.productivity}}</td> <!-- produtividade-->
                                    <td>{{item.pdv_ideal}}</td> <!-- Aderência IMEX-->
                                    <td>{{item.journey}}</td> <!-- jornada tt -->
                                    <td>{{item.time_of_pdv}}</td> <!-- tempo PDV-->
                                    <td>{{item.predicted_and_accomplished}}</td> <!-- previstos / pdvs -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="finishTable" *ngIf='rowsData.total_values > 0'>
                    <h3>Total: {{rowsData.table_data.length.toLocaleString('pt-BR')}}</h3>
                    <div class="paginator" id="paginator">
                        <pagination-controls (pageChange)="p = $event" nextLabel="Próximo" previousLabel="Anterior">
                        </pagination-controls>
                    </div>
                    <button (click)="exportTable()">
                        <img src="../../../../../../assets/icons/downloadIcon.svg" alt="Ícone de Download" />
                        Exportar Excel
                    </button>
                </div>

                <div class="title-empty" *ngIf="!rowsData.total_values && !loadingTable && tabSelected === 'Relatório 2'">
                    <h3>Sem registros nessa data</h3>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- Modais -->

<app-modal *ngIf="dadosModal" (closeModal)="setCloseModal()" widthContent="900">

    <div class="loading" *ngIf="modalLoading">
        <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <ng-container *ngIf="!modalLoading">
        <ng-container *ngFor="let item of modalData.table_data">
            <div class="content">
                <div class="infoDados">
                    <div class="boxInfos">
                        <label>Data</label>
                        <p>{{item.date}}</p>
                    </div>
                    <div class="boxInfos">
                        <label>Nome do Gestor</label>
                        <p>{{item.manager}}</p>
                    </div>
                    <div class="boxInfos finishCollumn">
                        <label>Promotor</label>
                        <p>{{item.full_name}}</p>
                    </div>
                </div>
                <div class="infoDados">
                    <div class="boxInfos">
                        <label>Roteiro dia</label>
                        <p>{{item.route_of_day}}</p>
                    </div>
                    <div class="boxInfos finishCollumn">
                        <label>Realizado</label>
                        <p>{{item.released}}</p>
                    </div>
                    <div class="boxInfos">
                        <label>Justificado</label>
                        <p>{{item.justified}}</p>
                    </div>
                </div>
                <div class="infoDados">
                    <div class="boxInfos">
                        <label>Check-in</label>
                        <p>{{item.checkins}}</p>
                    </div>
                    <div class="boxInfos">
                        <label>Check-out</label>
                        <p>{{item.checkouts}}</p>
                    </div>
                    <div class="boxInfos finishCollumn">
                        <label>Fora de rota</label>
                        <p>{{item.out_of_route}}</p>
                    </div>
                </div>
                <div class="infoDados">
                    <div class="boxInfos">
                        <label>Deslocamento Total</label>
                        <p>{{item.displacement.display_value}}</p>
                    </div>
                    <div class="boxInfos">
                        <label>Tempo no PDV</label>
                        <p>{{item.time_of_pdv.display_value}}</p>
                    </div>
                    <div class="boxInfos">
                        <label>Efetividade Check-in</label>
                        <p>{{item.checkin_effective}}</p>
                    </div>
                </div>
                <div class="infoDados">
                    <div class="boxInfos" style="flex: none; margin-right: 97px;">
                        <label>Efetividade Check-out</label>
                        <p>{{item.checkout_effective}}</p>
                    </div>
                    <div class="boxInfos">
                        <label>Efetividade + Justificado</label>
                        <p>{{item.effective_and_justifieds}}</p>
                    </div>
                </div>
                <div class="infoDados">
                    <div class="boxInfos">
                        <label>Primeiro Check-in</label>
                        <p>{{item.fist_checkin}}</p>
                    </div>
                    <div class="boxInfos">
                        <label>Último Check-out</label>
                        <p>{{item.last_checkout}}</p>
                    </div>
                    <div class="boxInfos finishCollumn">
                        <label>Jornada Total</label>
                        <p>{{item.journey.display_value}}</p>
                    </div>
                </div>
            </div>
            <div class="finish-modal">
                <h4>Visitas PDV por ordem</h4>
                <div class="map google-maps">
                    <agm-map class="google-maps" #mps [styles]='styles' [latitude]='lat' [longitude]='long' [mapTypeId]='googleMapType' id="map" [fitBounds]="true" (mapReady)="getteste($event)" >
                        <ng-container *ngFor="let marker of markers">
                            <agm-marker [latitude]="marker.getPosition().lat()" [longitude]="marker.getPosition().lng()" [iconUrl]="marker.icon" [agmFitBounds]="true" ></agm-marker>
                        </ng-container>
                    </agm-map>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <!-- <section class="error" *ngIf="!loading && error">
    <span
      ><img src="../../../assets/timeline/Information.svg" alt="" /> Erro ao
      buscar dados</span
    >
    <button (click)="getData()">Recarregar</button>
  </section> -->

</app-modal>