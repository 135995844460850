<h6 class="card-subtitle mb-2 text-muted">Informe a pontuação de cada resposta (Sim ou Não)</h6>
<div class="row">
    <div class="col-auto">
        <label for="basic-url" class="form-label">Pontos para (Sim)</label>
        <div class="input-group mb-3">
            <input (mousedown)="$event.stopPropagation()"  type="number" class="form-control" id="input_2" aria-describedby="input_2_span" (change)="setYesPointsFromInput($event)" value="{{component.okay_points ? component.okay_points : 0}}">
        </div>
    </div>

    <div class="col-auto">
        <label for="basic-url" class="form-label">Pontos para (Não)</label>
        <div class="input-group mb-3">
            <input (mousedown)="$event.stopPropagation()"  type="number" class="form-control" id="input_2" aria-describedby="input_2_span" (change)="setNoPointsFromInput($event)" value="{{component.not_okay_points ? component.not_okay_points : 0}}">
        </div>
    </div>
</div>