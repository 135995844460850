import {
    Directive,
    EventEmitter,
    HostListener,
    Output,
    ElementRef,
    Input
  } from '@angular/core';
  
  @Directive({
    selector: '[clickOutside]'
  })
  export class ClickOutsideDirective {
    constructor(private _elementRef :ElementRef) { }
  
      @Input() public exception: any;
  
      @Output() public clickOutside = new EventEmitter();
  
      @HostListener('document:click', ['$event.target'])
      public onClick(targetElement) {
        const notificationElement = document.getElementById('modal-notification');
        const clickedInside = this._elementRef.nativeElement.contains(targetElement) || notificationElement && notificationElement.contains(targetElement);
  
        if (!clickedInside) {
          this.clickOutside.emit(null);
        }
      }
  
      @HostListener('document:touchend', ['$event.target'])
      public onTouch(targetElement) {
        const notificationElement = document.getElementById('modal-notification');
        const clickedInside = this._elementRef.nativeElement.contains(targetElement) || notificationElement && notificationElement.contains(targetElement);
  
        if (!clickedInside) {
          this.clickOutside.emit(null);
        }
      }
  }
  