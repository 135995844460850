import { Component, DoBootstrap, Input, OnInit } from '@angular/core';
import { ComponentView } from '../ComponentView';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent extends ComponentView implements OnInit {


  constructor(
  ) {
    super()
  }

  ngOnInit(): void {

    if (!this.component.title) {
      this.component.title = "";
    }

    if (!this.component.conditional_points) {
      this.component.conditional_points = [];
    }

    if (this.component.value == undefined) {
      this.component.value = "";
    }

    this.onCreated();
  }

  ngAfterViewInit() {

  }

}
