import { Component, Input, OnInit } from '@angular/core';
import { ComponentView } from '../ComponentView';

@Component({
  selector: 'app-drop-down-items',
  templateUrl: './drop-down-items.component.html',
  styleUrls: ['./drop-down-items.component.scss']
})
export class DropDownItemsComponent extends ComponentView implements OnInit {


  constructor() {
    super()
   }

  ngOnInit(): void {

    if (!this.component.title) {
      this.component.title = "";
    }

    this.onCreated();
  }

}
