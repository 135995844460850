import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class WorkRotineService {

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService
  ) { }

  filterRoutine(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/work_routine/filter`, data, {headers: this.environment.headers});
  }

  tableRoutine(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/work_routine/data/report`, data, {headers: this.environment.headers});
  }

  headerRoutine(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/work_routine/data/header`, data, {headers: this.environment.headers});
  }
  
  cardRoutine(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/work_routine/data`, data, {headers: this.environment.headers});
  }

  cardHeaderRoutine(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/work_routine/data/indicators_day`, data, {headers: this.environment.headers});
  }

  cardFooterRoutine(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/work_routine/data/indicators_month`, data, {headers: this.environment.headers});
  }


  getHeader(): any{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "x-access-token":  "m0G7WB4ZzXqgM1Twq5MzKcc6aLzN6sBi81eLwFxSQgbARqOb3Ulceh2dhXQWpSXqXD"
    });

    return headers;
  }
}
