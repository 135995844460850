

<div class="card w-100 mt-3" [ngClass]="{'border-danger': component.title ==''}" cdkDrag [cdkDragDisabled]="inputFocus">
    
    <div class="card-header d-flex" (click)="toggleView($event.target.parentElement.parentElement)">
        <h2 class="m-0 w-100" *ngIf="component.title != ''">{{component.title}}</h2>
        <h2 class="m-0 w-100 error-label" *ngIf="component.title == ''">Digite a pergunta</h2>
        <h2 class="w-100 m-0" style="text-align: end;user-select: none;">#{{component.component_id}}</h2>
    </div>
    <div class="card-body">

        <h5 class="card-title">Pergunta de Seleção única</h5>
        <label for="basic-url" class="form-label">Pergunta</label>
        <div class="input-group mb-3">
            <span class="input-group-text" id="input_{{position}}_span">?</span>
            <input (mousedown)="$event.stopPropagation()"  id="input_title_{{position}}" type="text" class="form-control"  aria-describedby="input_1_span">
        </div>

        <h5 class="card-title">Respostas</h5>

        <div class="d-flex">
            <div>
                <div class="row mb-3">
                    <div class="col-auto">
                        <span>Resposta</span>
                        <div class="mt-2">
                            <input (mousedown)="$event.stopPropagation()"  placeholder="Digite a resposta" class="form-control" type="text">
                        </div>
                    </div>
                    <div class="col-auto">
                        <span>Pontos</span>
                        <div class="mt-2">
                            <input (mousedown)="$event.stopPropagation()"  placeholder="" value="0" class="form-control" type="text">
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="margin-top: 25px;">
                            <button class="btn btn-primary material-icons">delete</button>
                        </div>
                    </div>
                </div>
                
                <div class="d-flex w-100">
                    <div style="margin-left: auto;">
                        <button class="btn btn-primary"><span class="material-icons mr-3"
                                style="vertical-align: bottom;">add</span> Nova Resposta</button>
                    </div>
                </div>

            </div>

        </div>
        
    </div>
</div>