<div class="wiz-modal" [ngClass]="{'noCenter': alignContentModal}" (click)="closeAllModal($event)">
    <div class="content" #contentModal [style]="setWidth()">
        <div class="modal-header d-flex w-100">
            <h2 class="modal-title" *ngIf="title !== ''">{{title}}</h2>
            <img src="../../../assets/icons/iconX.svg" alt="Ícone de fechar" *ngIf="cancelable" (click)="onCloseModal()" class="close">
            
        </div>
        <div class="container">
            <ng-content>
                
            </ng-content>
        </div>
    </div>
</div>