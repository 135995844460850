import { AgmMap } from '@agm/core';
import { Component, OnDestroy, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ExportService } from 'src/app/core/services/data_service/export/export.service';
import { WorkRotineService } from 'src/app/core/services/workRotine/work-rotine.service';
import { CompareNames } from 'src/app/utils/CompareNames';

@Component({
  templateUrl: './work-routine.page.html',
  styleUrls: ['./work-routine.page.scss']
})
export class WorkRoutinePage implements OnInit, OnDestroy {

  googleMapType = 'roadmap';
  @ViewChild(AgmMap, { static: true }) mps: AgmMap;
  map: google.maps.Map;
  lat = -10.732767191116217;
  long = -52.697142642029476;
  markers: google.maps.Marker[] = [];
  displacementMap: Array<any> = [];
  percent = 100;
  titleIndicator = "IMEX"
  total = 0;
  finished = 0;
  percentImex = 0;
  totaltarefas = 0;
  finishedtarefas = 0;

  period = false;
  date = false;
  dadosModal: boolean = false;
  menuCardConfig = {
    visible: false,
    top: "0px",
    left: "0px",
    target: ""
  }
  tabSelected: any;
  dateDay: any;

  tabs = ['Visão Detalhada', 'Relatório 1', 'Relatório 2'];
  fakeBack = ['teste', 'teste']

  labelsstatus = ['Online'];
  public status: any = [
    { label: 'O', value: 'online' },
    { label: 'A', value: 'atendimento' },
    { label: 'X', value: 'ofline' },
    { label: null, value: 'default' }
  ];

  filters = {
    onchange: "",
    keys: {
      "director": "",
      "manager": "",
      "region": "",
      "filial": "",
      "coordinator": "",
      "supervisor": "",
      "promoter": "",
      "network": "",
      "market": ""
    }
  }

  exportData = {
    keys: {
      "full_name": "Nome",
      "manager": "Supervisor",
      "hiear": "Cargo",
      "filial": "Filial",
      "status": "Status",
      "start_work": "Início do Trabalho",
      "end_work": "Fim do Expediente",
      "fist_checkin": "Primeiro Check-in",
      "last_checkout": "Último Check-out",
      "route_of_day": "Roteiro dia",
      "released": "Realizado",
      "out_of_route": "Fora de Rota",
      "justified": "Justificado",
      "productivity": "Produtividade",
      "pdv_ideal": "Aderência IMEX",
      "journey": "Jornada TT Média",
      "time_of_pdv": "Tempo PDV",
      "predicted_and_accomplished": "Previstos / PDVS Atendidos"
    },
    data: []
  };

  sub: Subscription[] = [];
  subFilters: Subscription[] = [];
  errorFilters: boolean = false;
  loadingFilters: boolean = false;
  loadingTable: boolean = false;
  errorTable: boolean = false;
  loadingCards: boolean = false;
  errorCards: boolean = false;
  filtersData: any = {
    filters: {}
  };
  dateActually: any;
  rowsData: any;
  dataCard: any;
  dataCardFooter: any;
  dataCardIndicators: any;
  tableEmpty: boolean = false;
  modalData: any;
  modalLoading: boolean = false;
  errorModal: boolean = false;
  loadingIndicators: boolean = false;
  errorHeader: boolean = false;
  headerData: any;
  strDate: any;
  endDate: any;
  headerPromoter: any;
  journeyDay: string;
  displacementDay: string;
  excelReport = [];

  item: any = {
    sort: true,
  };
  ascPromoter = true;
  ascGestor = true;
  ascDia = true;

  formGroup = new FormGroup({
    director: new FormControl(""),
    region: new FormControl(""),
    filial: new FormControl(""),
    manager: new FormControl(""),
    coordinator: new FormControl(""),
    supervisor: new FormControl(""),
    promoter: new FormControl(""),
    network: new FormControl(""),
    market: new FormControl(""),
    search: new FormControl(""),
    periods: new FormControl(""),
    startDate: new FormControl(),
    finishDate: new FormControl(),
    dataPeriodo: new FormControl("")
  });

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {

      if (event.target.id != "menu_card") {
        if (this.menuCardConfig.visible && this.menuCardConfig.target == "opened") {
          this.menuCardConfig.visible = !this.menuCardConfig.visible;
          this.menuCardConfig.target = "closed";
        }
      }
    }
  }

  constructor(
    private routineService: WorkRotineService,
    private toastr: ToastrService,
    private eRef: ElementRef,
    private exportService: ExportService
  ) { }

  ngOnInit(): void {
    this.tabSelected = this.tabs[0];
    this.dateActually = new Date();
    this.getData(this.filters);
  }

  getData(filters) {
    this.loadingFilters = true;
    this.loadingIndicators = true;
    this.loadingTable = true;
    this.loadingCards = true;

    try {
      this.subFilters.push(
        this.routineService.filterRoutine(JSON.stringify(filters)).subscribe((response) => {
          this.filtersData = response;
          this.formGroup.get('startDate').setValue(this.getFormattedDate(new Date()));
          this.formGroup.get('finishDate').setValue(this.getFormattedDate(new Date()));
          let header = {
            promoters: this.filtersData.filters.promoters,
            from_date: this.dateActually.getTime(),
            to_date: this.dateActually.getTime(),
            markets: this.filtersData.filters.markets,
            networks: this.filtersData.filters.networks
          }
          let headerCard = this.filtersData.filters.promoters
          this.getCards(headerCard);
          this.getHeaders(header);
          this.tabData(header);
          
          this.loadingFilters = false;
        }, (error) => {
          if (error.status === 401) {
            this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado! 😕', { timeOut: 4000 });
            this.errorFilters = true;
          } else {
            this.toastr.error('Tente novamente', 'Houve um erro inesperado! 😵', { timeOut: 4000 });
            this.errorFilters = true;
          }
          console.error(error);
        })
      );
    } catch (error) {
      if (error.status === 401) {
        this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado!', { timeOut: 4000 });
        this.errorFilters = true;
      } else {
        this.toastr.error('Tente novamente', 'Houve um erro inesperado!', { timeOut: 4000 });
        this.errorFilters = true;
      }
      console.error(error);
    }
  }

  getFormattedDate(date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
  }

  getHeaders(header) {

    try {
      this.sub.push(
        this.routineService.headerRoutine(header).subscribe((responseHeaders) => {
          this.headerData = responseHeaders;
          this.loadingIndicators = false;

        }, (error) => {
          if (error.status === 401) {
            this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado! 😕', { timeOut: 4000 });
            this.errorHeader = true;
          } else {
            this.toastr.error('Tente novamente', 'Houve um erro inesperado! 😵', { timeOut: 4000 });
            this.errorHeader = true;
          }
          this.loadingIndicators = false;
          console.error(error);
        })
      )
    } catch (error) {
      if (error.status === 401) {
        this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado!', { timeOut: 4000 });
        this.errorFilters = true;
      } else {
        this.toastr.error('Tente novamente', 'Houve um erro inesperado!', { timeOut: 4000 });
        this.errorFilters = true;
      }
      console.error(error);
    }
  }

  tabData(headerObj) {
    this.loadingTable = true;
    try {
      this.sub.push(
        this.routineService.tableRoutine(headerObj).subscribe((responseTable) => {
          let tratamentoDados = responseTable;

          //pesquisar
          let campoSearch = this.formGroup.get('search').value.toLowerCase();
          // console.log(campoSearch)
          if (campoSearch !== "") {
            this.rowsData.table_data = tratamentoDados.table_data.filter(e => (e.full_name.toLowerCase().includes(campoSearch) || e.manager.toLowerCase().includes(campoSearch)))
          } else {
            this.rowsData = tratamentoDados
          }

          this.loadingTable = false;
        }, (error) => {
          this.loadingTable = false;
          if (error.status === 401) {
            this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado! 😕', { timeOut: 4000 });
          } else {
            this.toastr.error('Tente novamente', 'Houve um erro inesperado! 😵', { timeOut: 4000 });
            this.errorTable = true;
          }
          console.error(error);
        })
      )
    } catch (error) {
      this.loadingTable = false;
      if (error.status === 401) {
        this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado!', { timeOut: 4000 });

      } else {
        this.toastr.error('Tente novamente', 'Houve um erro inesperado!', { timeOut: 4000 });
        this.errorTable = true;
      }
      console.error(error);
    }
  }

  makeExportData() {

    this.exportData.data = [];

    for (var key in this.rowsData.table_data) {

      const c_table = this.rowsData.table_data[key];
      const c_table_data_card = this.dataCard.table_data[key];

      //Um objeto para ser exportado da tabela
      const c_export_item: any = {};

      for (var key2 in c_table) {

        //Verificar se em keys do export_data existe a key do dado da tabela.
        //Se existir vamos adicionar essa linha para o objeto de exportação.

        if (this.exportData.keys[key2]) {

          let value = c_table[key2];

          if(key2 === 'status'){

            if(value === 'active'){
              value = 'Ativo';
            }
            
            if(value === 'away_from_work'){
              value = 'Afastado';
            };
          }

          c_export_item[key2] = String(value).toLocaleUpperCase();
        }
      }

      for(let key3 in c_table_data_card){

        if (this.exportData.keys[key3]) {

          const value = c_table_data_card[key3];

          if(key3 == 'hiear'){

            c_export_item[key3] = String(value).toLocaleUpperCase();
          }
        }
      }

      this.exportData.data.push(c_export_item);
    }
  }

  exportExcel: boolean = false;
  exportTable() {
    this.exportExcel = true;
    this.makeExportData();

    this.exportService.exportTable(JSON.stringify(this.exportData)).subscribe((response) => {
      this.saveBlob(response, `Rotina de trabalho ${new Date().getTime()}.xlsx`);
      this.exportExcel = false;
    })
  }

  //salvar o arquivo em uma TAG A e baixalo.
  saveBlob(blob, fileName) {
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent('click'));
  }

  getCards(dataCards) {

    try {
      this.sub.push(
        //endpoint de montagem dos Cards
        this.routineService.cardRoutine(dataCards).subscribe((responseCard) => {
          let tratamentoDados = responseCard;

          //pesquisar
          let campoSearch = this.formGroup.get('search').value.toLowerCase();
          // console.log(campoSearch)
          if (campoSearch !== "") {
            this.dataCard.table_data = tratamentoDados.table_data.filter(e => (e.full_name.toLowerCase().includes(campoSearch) || e.manager.toLowerCase().includes(campoSearch)))
          } else {
            this.dataCard = tratamentoDados
          }

          this.dataCard.table_data = this.dataCard.table_data.sort((a, b) => {
            return new CompareNames().compare(b.visits.real, a.visits.real, true)
          })

          for (let index = 0; index < responseCard.length; index++) {
            const element = responseCard[index];
            this.finished = element.visits.real;
            this.total = element.visits.meta;
          }
          this.loadingCards = false;

        }, (error) => {
          if (error.status === 401) {
            this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado! 😕', { timeOut: 4000 });
          } else {
            this.toastr.error('Tente novamente', 'Houve um erro inesperado! 😵', { timeOut: 4000 });
            this.errorCards = true;
          }
          console.error(error);
        })
      )
    } catch (error) {
      if (error.status === 401) {
        this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado!', { timeOut: 4000 });
      } else {
        this.toastr.error('Tente novamente', 'Houve um erro inesperado!', { timeOut: 4000 });
        this.errorCards = true;
      }
      console.error(error);
    }
  }

  getCardsIndicators(cardObj) {

    try {
      this.sub.push(
        this.routineService.cardHeaderRoutine(cardObj).subscribe((responseIndicators) => {
          this.dataCardIndicators = responseIndicators;
          //indicador gráfucos card
          for (let index = 0; index < this.dataCardIndicators.cards.length; index++) {
            const element = this.dataCardIndicators.cards[index];
            this.total = element.visits.meta;
            this.finished = element.visits.real;
            this.percentImex = element.pdv_ideal.total_points;
            this.totaltarefas = element.tasks.meta;
            this.finishedtarefas = element.tasks.real;
            this.journeyDay = element.journey;
            this.displacementDay = element.displacement;
          }
          // total = 10 = meta;
          // finished = 4 = real;
          // totaltarefas = 36 = meta;
          // finishedtarefas = 26 = real;
        })
      )
    } catch (error) {
      if (error.status === 401) {
        this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado! 😕', { timeOut: 4000 });
      } else {
        this.toastr.error('Tente novamente', 'Houve um erro inesperado! 😵', { timeOut: 4000 });
        this.errorCards = true;
        this.loadingCards = false;
      }
      console.error(error);
    }
  }

  getCardsFooter(cardObj) {
    try {
      this.sub.push(
        this.routineService.cardFooterRoutine(cardObj).subscribe((responsefooter) => {
          // this.dataCardFooter = responsefooter;
          for (let index = 0; index < responsefooter.table_data.length; index++) {
            const element = responsefooter.table_data[index];
            this.dataCardFooter = element;
          }
          this.loadingCards = false;
        }, (error) => {
          if (error.status === 401) {
            this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado!', { timeOut: 4000 });
          } else {
            this.toastr.error('Tente novamente', 'Houve um erro inesperado!', { timeOut: 4000 });
            this.errorCards = true;
            this.loadingCards = false;
          }
          console.error(error);
        })
      )
    } catch (error) {
      if (error.status === 401) {
        this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado! 😕', { timeOut: 4000 });
      } else {
        this.toastr.error('Tente novamente', 'Houve um erro inesperado! 😵', { timeOut: 4000 });
        this.errorCards = true;
      }
      console.error(error);
    }
  }

  onChange(field) {
    this.loadingFilters = true;

    let finalFilters = {
      onchange: field,
      keys: this.formGroup.value
    }
    // debugger

    try {
      this.subFilters.push(
        this.routineService.filterRoutine(JSON.stringify(finalFilters)).subscribe((responsefilter) => {
          this.filtersData = responsefilter;

          setTimeout(() => {
            // this.formGroup.get('gestor_text').setValue(finalFilters.keys.gestor_text);
            this.formGroup.get('director').setValue(finalFilters.keys.director);
            this.formGroup.get('manager').setValue(finalFilters.keys.manager);
            this.formGroup.get('coordinator').setValue(finalFilters.keys.coordinator);
            this.formGroup.get('supervisor').setValue(finalFilters.keys.supervisor);
            this.formGroup.get('promoter').setValue(finalFilters.keys.promoter);
            this.formGroup.get('filial').setValue(finalFilters.keys.filial);
            this.formGroup.get('region').setValue(finalFilters.keys.region);
            this.formGroup.get('network').setValue(finalFilters.keys.network);
            this.formGroup.get('market').setValue(finalFilters.keys.market);
            this.loadingFilters = false;
          }, 2000);
        }, (error) => {
          if (error.status === 401) {
            this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado!', { timeOut: 4000 });
            this.errorFilters = true;
          } else {
            this.toastr.error('Tente novamente', 'Houve um erro inesperado!', { timeOut: 4000 });
            this.errorFilters = true;
          }
          this.loadingFilters = false;
          console.error(error);
        })
      )
    } catch (error) {
      this.toastr.error('Tente novamente mais tarde', 'Houve um erro inesperado!', { timeOut: 4000 });
      console.log(error)
      if (error.status === 401) {
        this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado!', { timeOut: 4000 });
        this.errorFilters = true;
      } else {
        this.toastr.error('Tente novamente', 'Houve um erro inesperado!', { timeOut: 4000 });
        this.errorFilters = true;
      }
      console.error(error);
      this.loadingFilters = false;
    }

  }

  openTabList(tab) {
    this.tabSelected = tab;
  }

  iconStatus(item: any): void {
    return this.status.map(status => status.label === item.typeStatus ? status.value : null).filter(item => item != null)[0];;
  }

  modalDados(userId) {
    this.dadosModal = true;
    this.modalLoading = true;
    this.markers = [];

    console.log('usedId', userId);

    let header = {
      promoters: [{ user_id: userId }],
      from_date: this.dateActually.getTime(),
      to_date: this.dateActually.getTime()
    }
    try {
      this.sub.push(
        this.routineService.tableRoutine(header).subscribe((responseModal) => {
          this.modalData = responseModal;

          for (let index = 0; index < responseModal.table_data.length; index++) {
            const element = responseModal.table_data[index];
            this.displacementMap = element.displacement_order;
          
          }

          this.modalLoading = false;

          /* setTimeout(() => {
            this.getMap(this.displacementMap);
          }, 1000); */

        }, (error) => {
          if (error.status === 401) {
            this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado! 😕', { timeOut: 4000 });
          } else {
            this.toastr.error('Tente novamente', 'Houve um erro inesperado! 😵', { timeOut: 4000 });
            this.errorModal = true;
            this.modalLoading = false;
          }
          console.error(error);
        })
      )
    } catch (error) {
      if (error.status === 401) {
        this.toastr.error('Efetue o login novamente', 'Tempo de sessão expirado!', { timeOut: 4000 });
      } else {
        this.toastr.error('Tente novamente', 'Houve um erro inesperado!', { timeOut: 4000 });
        this.errorModal = true;
        this.modalLoading = false;
      }
      console.error(error);
    }

  }

  getMap(locations) {
    const bounds = new google.maps.LatLngBounds();

    console.log(locations);
    console.log('bounds', bounds)

    for (var key in locations) {
      const indicators = locations[key];
      bounds.extend(new google.maps.LatLng(location[0], location[1]))
      const marker = new google.maps.Marker(
        {
          position: new google.maps.LatLng(indicators.latlng[0], indicators.latlng[1]),
          icon: `https://raioxdemercado.com.br:8081/api/utils/icon/${parseInt(key) + 1}`,
          map: this.map
        }
      )
      this.markers.push(marker);
    }
    this.modalLoading = false;
  }

  setCloseModal() {
    this.dadosModal = false;
  }

  menuCard(element) {

    this.menuCardConfig.top = `${parseInt(this.offset(element).top)}px`;
    this.menuCardConfig.left = `${parseInt(this.offset(element).left) - 160}px`;

    this.menuCardConfig.visible = !this.menuCardConfig.visible;

    if (this.menuCardConfig.visible) {
      setTimeout(() => {
        this.menuCardConfig.target = "opened";
      }, 200);
    } else {
      this.menuCardConfig.target = "closed";
    }


  }

  offset(element): any {
    var rect = element.getBoundingClientRect(), scrollLeft = window.pageXOffset || document.documentElement.scrollLeft, scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  formatSubtitle = (real: number, meta: number) => {
    // return `${this.finished}/${this.total}`;
    console.log(real, meta);
    // return `${real}/${meta}`;
    return ['adada'];

  }

  formatSubtitletarefas = (percent: number) => {
    return `${this.finishedtarefas}/${this.totaltarefas}`;
  }

  changeType() {
    let selectvalue = this.formGroup.get('periods').value;

    this.strDate = this.formGroup.get('startDate').setValue('');
    this.endDate = this.formGroup.get('finishDate').setValue('');

    if (selectvalue === 'dia') {
      this.date = true;
      this.period = false;
    } else if (selectvalue === 'periodos') {
      this.period = true;
      this.date = false;
    }
    else {
      this.period = false;
      this.date = false;
    }

  }

  searchRotina() {
    this.loadingTable = true;
    this.loadingCards = true;
    this.onSubData();

    let select_period = this.formGroup.get('periods').value;

    //pegando os valores dos selects de Data inicial e Data Final
    this.strDate = this.formGroup.get('startDate').value;
    this.endDate = this.formGroup.get('finishDate').value;
    this.dateDay = this.formGroup.get('dataPeriodo').value;

    if (select_period === 'periodos' && (this.endDate < this.strDate || this.endDate == '' || this.strDate == '')) {
      this.toastr.error('O período filtrado está incorreto 📅!', 'Período Inválido!', { timeOut: 4000 });
      this.loadingTable = false;
      this.loadingCards = false;
      return;
    }

    //Pegando o valor do select nome do promotor
    const promoterForm = this.formGroup.get('promoter').value;
    const marketForm = this.formGroup.get('market').value;
    const networkForm = this.formGroup.get('network').value;

    let params = {
      promoters: this.filtersData.filters.promoters,
      from_date: this.dateActually.getTime(),
      to_date: this.dateActually.getTime(),
      markets: this.filtersData.filters.markets,
      networks: this.filtersData.filters.networks
    }


    if (promoterForm !== "") {
      params.promoters = [{ user_id: promoterForm }]
    }

    if (marketForm != "") {
      params.markets = [{ pdv_id: marketForm }]
    }

    if (networkForm != "") {
      params.networks = [{ name: networkForm }]
    }

    if (this.dateDay !== null && this.dateDay != "") {
      var date = new Date()
      if (this.dateDay == "hoje") {
        params.from_date = this.dateActually.getTime();
        params.to_date = this.dateActually.getTime();
      }
      if (this.dateDay == "ontem") {
        params.from_date = date.setDate(date.getDate() - 1);
        params.to_date = date.setDate(date.getDate());
      }
      if (this.dateDay == "ultimosseven") {
        params.from_date = date.setDate(date.getDate() - 7);
      }
      if (this.dateDay == "ultimosdias") {
        params.from_date = date.setDate(date.getDate() - 30);
      }
    }

    //verificando se a data estiver preenchida 
    if (this.strDate !== null && this.endDate !== null && this.dateDay === "") {
      params.from_date = this.formatDate(this.strDate).getTime();
      params.to_date = this.formatDate(this.endDate).getTime();
    }

    this.dateDay = this.formGroup.get('dataPeriodo').setValue('');

    this.tabData(params);
    this.getCards(params.promoters);
  }

  formatDate(value) {
    let c_split = value.split("-");
    let date = new Date(parseInt(c_split[0]), parseInt(c_split[1]) - 1, parseInt(c_split[2]));

    return date;
  }

  resizeTr(index) {
    const heightTr1 = document.getElementById('tr1_' + index).offsetHeight;
    const heightTr2 = document.getElementById('tr2_' + index).offsetHeight;
    if (heightTr1 > heightTr2) {
      document.getElementById('tr2_' + index).style.height = heightTr1 + 'px';
    } else {
      document.getElementById('tr1_' + index).style.height = heightTr2 + 'px';
    }
  }

  sortData(orderName: string) {
    this.item = { ...this.item, sort: !this.item.sort }


    if (orderName == 'promotor') {
      this.rowsData.table_data.sort(function (a, b) {
        var nameA = a.full_name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.full_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      if (this.ascPromoter) {
        this.rowsData.table_data.reverse();
      }
      this.ascPromoter = !this.ascPromoter;
    }

    if (orderName == 'gestor') {
      this.rowsData.table_data.sort(function (a, b) {
        var nameA = a.manager.toUpperCase();
        var nameB = b.manager.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      if (this.ascGestor) {
        this.rowsData.table_data.reverse();
      }
      this.ascGestor = !this.ascGestor;
    }

    if (orderName == 'dia') {
      this.rowsData.table_data.sort(function (a, b) {
        var dateA = a.date;
        var dateB = b.date;
        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }

        return 0;
      });
      if (this.ascDia) {
        this.rowsData.table_data.reverse();
      }
      this.ascDia = !this.ascDia;
    }

  }

  onSubData() {
    this.sub.forEach(s => s.unsubscribe());
  }

  ngOnDestroy() {
    this.sub.forEach(s => s.unsubscribe());
    this.subFilters.forEach(sf => sf.unsubscribe());
    // console.log('component destruido')
  }


}
