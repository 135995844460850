<div class="indicator">
    <div class="header" style="display: flex; align-items: center; justify-content: space-around;">
        <p class="indicator-title lg">{{title}}</p>
        <span placement="top" [ngbTooltip]="description"><fieldset></fieldset>
            <span class="material-icons" style="color: #b1bebf;">help</span>
        </span>
    </div>
    <div class="body">
        <p class="indicator-value" id="actived_promoters_value"
            *ngIf="!loading">{{value}}</p>
        <!-- <p class="indicator-description">Cadastrados / Ativos</p> -->
        <div class="spinner-border" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>