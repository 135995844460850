import { Component, Input, OnInit } from '@angular/core';
import { ComponentView } from '../ComponentView';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent extends ComponentView implements OnInit {


  constructor() { 
    super()
  }

  ngOnInit(): void {

    if (!this.component.title) {
      this.component.title = "";
    }

    if (this.component.value == undefined) {
      this.component.value = "";
    }
    
    this.onCreated();
  }

}
