import { Component, Input } from "@angular/core";

@Component({
    selector: 'box-item',
    templateUrl: './box-item.component.html',
    styleUrls: ['./box-item.component.scss']
})
export class BoxItemComponent {
    @Input() name;
    @Input() color;
    @Input() icon;
    @Input() description;
    @Input() route;
    constructor() {

    }
}