import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public isLogged = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService
  ) { }

  login(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}endpoint/auth/login`, data);
  }

  newLogin(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/login`, data, {headers: this.environment.headers});
  }

  logout(): Observable<any>{
    return this.http.get(`${this.environment.smartPerformanceApi}/logout`, {headers: this.environment.headers});
  }

  public getToken(): string {
    return localStorage.getItem('workgroup');
  }

  public setIsLogged(value: boolean): void {
    this.isLogged.emit(value);
  }

  getUsers(page, perPage, sortBy, sort, search, filters = {}): Observable<any> {
    let url = `${this.environment.apiUrl}/users?page=${page}&perPage=${perPage}&sortBy=${sortBy}&sort=${sort}&search=${search}`
    
    for(let key in filters) {
        url += `&${key}=${filters[key]}`;
    }

    return this.http.get(url, {headers: this.environment.headers})
  }
  updateUser(user_id, data): Observable<any> {
      return this.http.post(`${this.environment.apiUrl}/users/${user_id}`, data, {headers: this.environment.headers});
  }
  deleteUser(user_id): Observable<any> {
      return this.http.delete(`${this.environment.apiUrl}/users/${user_id}`, {headers: this.environment.headers});
  }
  createUser(data): Observable<any> {
      return this.http.post(`${this.environment.apiUrl}/users`, data, {headers: this.environment.headers});
  }
  exportUsers(search, filters = {}): Observable<any> {
    let url = `${this.environment.apiUrl}/users/export?search=${search}`;
    for(let key in filters) {
      url += `&${key}=${filters[key]}`;
    }
    return this.http.get(url, {headers: this.environment.headers, responseType: 'blob'});
  }
}
/*

base_url:
https://minalbasmartperformance.uc.r.appspot.com/api

login_url:
https://minalbasmartperformance.uc.r.appspot.com/api/login
header :  "x-access-token"  : "m0G7WB4ZzXqgM1Twq5MzKcc6aLzN6sBi81eLwFxSQgbARqOb3Ulceh2dhXQWpSXqXD"
login: admin@smartapp.com.br
password: trT5lmfUzlrD0OkkZejCfKOur1k9nHrY

*/