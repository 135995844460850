import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class SearchManagmentService {

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService
  ) { }


  getHeader(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "x-access-token": "m0G7WB4ZzXqgM1Twq5MzKcc6aLzN6sBi81eLwFxSQgbARqOb3Ulceh2dhXQWpSXqXD"
    });
    return headers;
  }

  management_custom_search_list_config():Observable<any>{
    return this.http.get(`${this.environment.smartPerformanceApi}/spot_search/management/custom_search_list_config`,this.getHeader());
  }

  management_spot_create(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/spot_search/management/create`,data,this.getHeader());
  }

  management_spot_save(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/spot_search/management/save`,data,this.getHeader());
  }

  //Management apis
  management_spot_all(): Observable<any>{
    return this.http.get(`${this.environment.smartPerformanceApi}/spot_search/management/list/data`,this.getHeader());
  }

  management_coaching_all(): Observable<any>{
    return this.http.get(`${this.environment.smartPerformanceApi}/coaching/management/list/data`,this.getHeader());
  }
  
  management_read_spot_search(data): Observable<any> {
    return this.http.post(`${this.environment.smartPerformanceApi}/spot_search/management/read`, data, this.getHeader());
  }

  management_read_coaching(data): Observable<any> {
    return this.http.post(`${this.environment.smartPerformanceApi}/spot_search/management/read`, data, this.getHeader());
  }

  management_read_filter_spot_search(): Observable<any> {
    return this.http.get(`${this.environment.smartPerformanceApi}/spot_search/management/read/filters`, this.getHeader());
  }

  management_publish_spot_search(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/spot_search/management/publish`,data,this.getHeader());
  }
}
