import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentView } from '../ComponentView';

@Component({
  selector: 'app-conditional-points',
  templateUrl: './conditional-points.component.html',
  styleUrls: ['./conditional-points.component.scss']
})
export class ConditionalPointsComponent extends ComponentView implements OnInit,OnDestroy {

  constructor() { 
    super();
  }

  ngOnInit(): void {
    this.parent.points = 0;

    if(Object.keys(this.component).length == 0){
      this.component = {
      
        type:"conditional_points",
        okay_points:0,
        not_okay_points:0
      
      }
    }

    this.onCreated();
    
  }

  setYesPointsFromInput(event){
    let points = parseInt(event.target.value);

    this.setOkayPoints(points);


  }

  setNoPointsFromInput(event){
    let points = parseInt(event.target.value);

    this.setNotOkayPoints(points);
  }

  ngOnDestroy(): void {
      delete this.parent.points;
      delete this.parent.conditional_points;
  }

}
