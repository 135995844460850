import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ListWithSearchPaginatedFilterModule } from '../list-with-search-paginated-filter/list-with-search-paginated-filter.module';

import { ModalListWithPaginatedFilterComponent } from './modal-list-with-paginated-filter.component';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        ListWithSearchPaginatedFilterModule
    ],
    exports: [ModalListWithPaginatedFilterComponent],
    declarations: [ModalListWithPaginatedFilterComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalListWithPaginatedFilterModule { }
