import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { MapsComponent } from './maps.component';

@NgModule({
    imports: [
        CommonModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAB3sd8hSP8jNeFJTsPSPNkdy149KA-dfA'
        }),
    ],
    exports: [MapsComponent],
    declarations: [MapsComponent],
    providers: [],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class MapsModule { }
