import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { ExportService } from 'src/app/core/services/data_service/export/export.service';
import { resizeTable } from 'src/app/utils/table.utils';
import { CustomDataModel } from './CustomDataModel';
import { DefaultDataModel } from './DefaultDataModel';
import { Feature } from './FeatureModel';
import { EventClickModel } from './features/EventClickModel';
import { TableConfigModel } from './TableConfigModel';
import { TableDataModel } from './TableDataModel';

export interface ExportConfig {
  exportTo: "pptx" | "excel",
  ppt: boolean,
  excel: boolean,

}
@Component({
  selector: 'app-paginated-table',
  templateUrl: './paginated-table.component.html',
  styleUrls: ['./paginated-table.component.scss']
})
export class PaginatedTableComponent implements OnInit {

  tableContainItems: boolean = false;
  @Input() tableConfig;
  @Input() exportConfig = <ExportConfig>{ exportTo: "pptx", ppt: false, excel: false };
  @Input() loadingData: Boolean;
  @Input() errorData: Boolean;
  @Output() onFeatureEventListener = new EventEmitter();
  @Input() exportEvent = (data: any) => () => { };

  item: any = {
  }

  default_data: DefaultDataModel = {
    headers: [],
    data: []
  };

  custom_data: CustomDataModel = {
    headers: [],
    data: []
  }

  features: Feature = {
    show: false,
    align: undefined,
    buttons: {
      edit: false,
      copy: false,
      delete: false
    }
  };

  modal_view: boolean;

  data = [];

  dump_data = {
    default: [],
    custom: []
  }

  //Modelo de produtos minalba
  exportData = {
    keys: {

    },
    data: []
  };

  modals = {
    table_images: false,
    show_modal_collects: false
  }


  @Output() onExportCustomData = new EventEmitter();
  @Output() onExportEvent = new EventEmitter();
  @Output() onExportDataCreated = new EventEmitter();

  custom_data_exists = false;

  uniqueKey: any = "";

  constructor(
    private renderer: Renderer2,
    private exportService: ExportService
  ) { }

  ngOnInit() {

    if (this.tableConfig) {
      this.default_data = this.tableConfig.default_data;
      this.custom_data = this.tableConfig.custom_data;

      if (this.tableConfig.features) {
        this.features = this.tableConfig.features;
      }
      this.modal_view = this.tableConfig.modal_view;

      if (this.tableConfig.custom_data && this.tableConfig.custom_data.data && this.tableConfig.custom_data.data.length) {
        this.custom_data_exists = true;
      }

      let { data, headers_export } = this.getTableData(this.default_data, this.custom_data);
      this.data = data;
      this.setHeadersExport(headers_export)

      this.dumpData();
    }

    this.uniqueKey = new Date().getTime();

  }

  onDataSet(response: TableConfigModel) {//
    //console.log("paginated-table")

    this.default_data = response.default_data;
    this.custom_data = response.custom_data;//

    if (response.features) {
      this.features = response.features;
    }
    this.modal_view = response.modal_view;

    if (response.custom_data && response.custom_data.data && response.custom_data.data.length) {
      this.custom_data_exists = true;
    }

    let { data, headers_export } = this.getTableData(this.default_data, this.custom_data);

    this.data = data;
    this.setHeadersExport(headers_export)

    this.dumpData();
  }

  getTableData(defaultData: any, customData: any) {

    let default_data = JSON.parse(JSON.stringify(defaultData));
    let custom_data = JSON.parse(JSON.stringify(customData));

    let merge_data = [];
    let data = [];

    let headers_export = [];

    for (let i in default_data.headers) {

      //export data
      //this.exportData.keys[`key_${i}`] = default_data.headers[i];

      headers_export.push(default_data.headers[i]);
    }

    for (let i in default_data.data) {
      let object = {};

      let arr = [];

      for (let key in default_data.data[i]) {
        object[`key_${key}`] = default_data.data[i][key];

        arr.push(default_data.data[i][key]);
      }

      merge_data.push(arr);

    }


    for (let i in custom_data.headers) {

      //export data
      //this.exportData.keys[`key_${i}`] = custom_data.headers[i];

      headers_export.push(custom_data.headers[i]);
    }

    for (let i in custom_data.data) {

      let object = {};

      let arr = [];

      for (let key in custom_data.data[i]) {
        object[`key_${key}`] = custom_data.data[i][key];


        arr.push(custom_data.data[i][key]);
      }

      merge_data[i].push(...arr);

    }



    for (let key in merge_data) {

      let c_merge = merge_data[key];

      let object = {};

      for (let key2 in c_merge) {

        let c_item = c_merge[key2];

        object[`key_${key2}`] = c_item;

      }

      data.push(object);
    }

    this.data.sort((a, b,) => {
      return this.compareNames(a.date, b.date, false);
    });


    return {
      data,
      headers_export
    };

  }

  setHeadersExport(headers_export: string[]) {

    this.exportData.keys = {};

    for (let i in headers_export) {

      let key = `key_${i}`;

      this.exportData.keys[key] = headers_export[i];;

    }
  }

  search(input: string) {
    //redefinir os valores de pdvs
    let { data, headers_export } = this.getTableData(this.default_data, this.custom_data);
    this.data = data;
    this.setHeadersExport(headers_export)

    //a constante do angular pdv recebe os valores encontrados
    this.data = this.filterSearch(this.data, input);

    this.dumpData();
  }

  filterSearch(value, input) {
    let array = [];

    //for no array de objetos
    for (let i = 0; i < value.length; i++) {

      let object_complete = value[i];


      //busca somente valores do objeto e converter em array
      let convert_values_objet_array: { value: string }[] = Object.values(value[i]);

      //checa se cada valor do objeto possui o valor pesquisado, se isto for verdadeiro é feito o push para variavel pdvs
      for (let j = 0; j < convert_values_objet_array.length; j++) {

        let value_only_object = this.stringNormalize(convert_values_objet_array[j].value);

        // Verificando se ele o input existe
        if (value_only_object.includes(this.stringNormalize(input))) {

          if (!array.includes(object_complete)) {
            array.push(object_complete)
          }
        }
      }
    }

    return array;
  }

  //método para remover acentos e converter letras em minúsculas
  stringNormalize(value) {
    return String(value).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }

  sortData(field) {

    this.item[field] = { ...this.item[field], sort: !this.item[field].sort }

    this.data.sort((a, b,) => {
      return this.compareNames(a[field].value, b[field].value, !this.item[field].sort);
    });


    this.dumpData();

  }

  //compara nomes para setar ordem alfabética
  compareNames(a, b, sort: boolean) {
    a = String(a).toLowerCase();
    b = String(b).toLowerCase();

    if (!sort) {
      return (a > b) ? -1 : (a < b) ? 1 : 0;
    }

    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  dumpData() {
    this.dump_data.default = [];
    this.dump_data.custom = [];
    this.tableContainItems = false;

    for (let key in this.data) {

      let c_data = this.data[key];

      let dump_default_keys = Object.keys(c_data).slice(0, this.default_data.headers.length);
      let dump_custom_keys = Object.keys(c_data).slice(this.default_data.headers.length, Object.keys(c_data).length);

      let dump_object_default: TableDataModel[] = [];
      let dump_object_custom: TableDataModel[] = [];

      for (let i in dump_default_keys) {

        let key = dump_default_keys[i];

        dump_object_default.push({
          key: key,
          sort: true,
          value: c_data[key].value,
          type: c_data[key].type,
          images: c_data[key].images ? c_data[key].images : undefined,
          table_config: c_data[key].table_config ? c_data[key].table_config : undefined,
          _id: c_data[key]._id ? c_data[key]._id : false
        });

        //item
        if (!this.item[key]) {
          this.item[key] = { sort: true }
        }

      }

      for (let i in dump_custom_keys) {

        let key = dump_custom_keys[i];

        let { type } = c_data[key];

        if (type == "items" && !this.tableContainItems) {
          // console.log(type, "table contain items: true")
          this.tableContainItems = true;
        }

        dump_object_custom.push({
          key: key,
          value: c_data[key].value,
          sort: undefined,
          type: c_data[key].type,
          images: c_data[key].images ? c_data[key].images : undefined,
          table_config: c_data[key].table_config ? c_data[key].table_config : undefined,
          _id: c_data[key]._id ? c_data[key]._id : false
        });

      }

      this.dump_data.default.push(dump_object_default);
      this.dump_data.custom.push(dump_object_custom);
    }

    if (!this.tableContainItems) {
      this.exportData.data = this.makeExportData(this.exportData.keys, this.data);
    } else {

      let paginatedTableComponent = new PaginatedTableComponent(this.renderer, this.exportService);

      let all_data = [];

      for (let item of this.dump_data.custom) {

        for (let item2 of item) {

          let { table_config } = item2;

          if (table_config) {

            let { default_data, custom_data } = table_config;

            let { data, headers_export } = paginatedTableComponent.getTableData(default_data, custom_data);
            this.setHeadersExport(headers_export);
            all_data.push(...data);



          }
        }



      }

      let exportData = paginatedTableComponent.makeExportData(this.exportData.keys, all_data);

      this.exportData.data = exportData;

      // console.log(this.exportData);

    }

  }

  resizeTr() {

    if (this.custom_data_exists) {

      resizeTable(document)
    }

  }


  makeExportData(keys: any, data: any[]) {

    let exportData = []

    for (var key in data) {

      var c_table = data[key];

      //Um objeto para ser exportado da tabela
      var c_export_item = {};

      for (var key2 in c_table) {
        //Verificar se em keys do export_data existe a key do dado da tabela.
        //Se existir vamos adicionar essa linha para o objeto de exportação.
        if (keys[key2]) {

          var value = "";

          if (c_table[key2].images) {
            c_export_item[key2] = {
              images: c_table[key2].images,
              type: "images"
            };


          } else {
            value = c_table[key2].value;
            //Substituir booleanos
            value = typeof value == "boolean" ? (value ? "Sim" : "Não") : typeof value == "string" ? value.split(",")[0] : "";
            value = typeof value == "number" ? "" + value : value;

            value = String(value).toLocaleUpperCase();

            c_export_item[key2] = value;
          }

        }
      }


      exportData.push(c_export_item);

    }

    if (this.onExportDataCreated)
      this.onExportDataCreated.emit(exportData);

    return exportData;

  }

  onFeatureClickEvent(event: EventClickModel) {
    //console.log(event);
    this.onFeatureEventListener.emit(event)
  }

  exportExcel = false;

  modalExportFiles = false;

  exportToCustomFormat(format) {

    this.exportConfig.exportTo = format;

    this.modalExportFiles = true;

  }

  setCloseModalExportFiles() {
    this.modalExportFiles = false;
    this.loadingData = false;
    this.removeClass();
  }


  table_images_urls = [];
  setImagesModal(images) {
    this.table_images_urls = images;
    this.openModal("table_images")
  }

  table_config = {
    default_data: {
      headers: [],
      data: []
    }
  };
  setTableConfigModal(table_config) {
    this.table_config = table_config;
    this.openModal("show_modal_collects");
  }

  openModal(modal) {
    this.modals[modal] = true;
  }

  closeModal(modal) {
    this.modals[modal] = false;

    console.log('close modal:', modal);
    this.loadingData = false;

    this.removeClass();
  }

  removeClass() {
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.removeClass(document.documentElement, 'modal-open');
  }

  exportTable() {

    this.exportExcel = true;

    this.exportService.exportTable(JSON.stringify(this.exportData)).subscribe((response) => {

      this.saveBlob(response, `Pesquisa Spot Mibalba ${new Date().getTime()}.xlsx`);

      this.exportExcel = false;
    })


  }



  //salvar o arquivo em uma TAG A e baixalo.
  saveBlob(blob, fileName) {
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent('click'));
  }

}
