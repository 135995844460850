import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/users/users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationToolsGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let personal_info = JSON.parse(localStorage.getItem('personal_info'));

    let display_value = personal_info.hiear.display_value;

    let authorization = ["Admin", "Assistente de trade", "Líder de projeto", "Coordenador", "Coordenador de trade", "Supervisor de trade"];

    for (let i = 0; i < authorization.length; i++) {
      if (display_value == authorization[i]) {
        return true;
      }
    }

    this.router.navigate(['/home']);
    return false;
  }

}

