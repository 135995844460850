export const resizeTable = (document: Document) => {
    const tables = document.getElementsByClassName('table');

    for (let key in tables) {

        if (!isNaN(Number(key))) {

            let table = tables[key];

            let table1 = table.getElementsByClassName('table1')[0];
            let table2 = table.getElementsByClassName('table2')[0];

            let thead1 = table1.getElementsByTagName('thead')[0];
            let thead2 = table2.getElementsByTagName('thead')[0];

            let thTr1 = thead1.getElementsByTagName('tr')[0];
            let thTr2 = thead2.getElementsByTagName('tr')[0];

            const thTrHeight = thTr2.offsetHeight;

            if(thTr1.offsetHeight < thTrHeight){
                thTr1.style.height = `${thTrHeight}px`;
            }else{
                thTr2.style.height = `${thTr1.offsetHeight}px`
            }

            let tbody1 = table1.getElementsByTagName('tbody')[0];
            let tbody2 = table2.getElementsByTagName('tbody')[0];

            let tbTr1 = tbody1.getElementsByTagName('tr');
            let tbTr2 = tbody2.getElementsByTagName('tr');

            for (let key2 in tbTr2) {

                if (!isNaN(Number(key2))) {

                    let cTr = tbTr2[key2];

                    const tbTrHeight = cTr.offsetHeight;

                    if (tbTr1[key2].offsetHeight < tbTrHeight) {
                        tbTr1[key2].style.height = `${tbTrHeight}px`;
                    } else {
                        tbTr2[key2].style.height = `${tbTr1[key2].offsetHeight}px`;
                    }

                }

            }




        }


    }
}