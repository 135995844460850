import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { InterfaceModel } from './InterfaceModel';

@Component({
  selector: 'app-modal-new-search-block',
  templateUrl: './modal-new-search-block.component.html',
  styleUrls: ['./modal-new-search-block.component.scss']
})
export class ModalNewSearchBlockComponent implements OnInit {

  interfaceConfig: InterfaceModel[] = []

  newBlockConfig = {
    title: "",
    description: "",
    type: "",
    type_description: ""
  };

  @Output() onCloseModal = new EventEmitter();
  @Output() onTypeSelected = new EventEmitter();

  onSubmitCreate = false;

  constructor() { }

  ngOnInit(): void {

    this.interfaceConfig.push({
      name: "Básico",
      show: true,
      selected: true,
      type: "basic",
      template: {
        icon: "./assets/img/basic_search.png",
        title: "Bloco Básico",
        description: "Um bloco básico define um conjunto de perguntas a serem exibidas nos dispositivos móveis dos usuários em campo. As respostas coletadas através desse bloco não estão relacionadas a nenhuma dimensão. Selecione este tipo de bloco se você deseja coletar informações detalhadas em assuntos ou itens não listados em nenhuma dimensão."
      }
    },
      {
        name: "Lista",
        show: false,
        selected: false,
        type: "list",
        template: {
          icon: "./assets/img/list_search.png",
          title: "Bloco de Lista",
          description: "Um bloco Lista associada seu conjunto de perguntas a serem exibidas nos dispositivos móveis a uma dimensão, relacionado as respostas coletadas com um ou mais itens de uma lista.Selecione esse tipo de bloco se você irá realizar multiplas perguntas relacionadas a um ou mais itens pré selecionados de uma lista, como um conjunto de produtos, clientes ou outras dimensões."
        }
      })
  }

  resetInterfaces() {
    for (let key in this.interfaceConfig) {
      this.interfaceConfig[key].show = false;
      this.interfaceConfig[key].selected = false;
    }
  }

  selectInterface(type) {

    this.resetInterfaces();

    let c_interface = this.interfaceConfig.find(element => element.type == type);

    if (c_interface) {
      c_interface.show = true;
      c_interface.selected = true;
    }
  }



  typeSelected() {

    this.onSubmitCreate = true;

    if(!this.checkValid()){
      return;
    }


    let interfaceConfig = this.interfaceConfig.find(element => element.selected == true);

    this.newBlockConfig.type = interfaceConfig.type;
    this.newBlockConfig.type_description = interfaceConfig.name;

    this.onTypeSelected.emit(this.newBlockConfig);

    this.setCloseModal();

  }

  checkValid() {
    let errors = 0;

    if (this.newBlockConfig.title == '') {
      errors++;
    }

    if (this.newBlockConfig.description == '') {
      errors++;
    }

    return errors == 0;
  }

  setCloseModal() {
    this.onCloseModal.emit();
  }

}
