import { Component, Input, OnInit } from '@angular/core';
import { ComponentView } from '../ComponentView';

@Component({
  selector: 'app-input-price',
  templateUrl: './input-price.component.html',
  styleUrls: ['./input-price.component.scss']
})
export class InputPriceComponent extends ComponentView implements OnInit {


  constructor() {
    super()
   }

  ngOnInit(): void {

    if (!this.component.title) {
      this.component.title = "";
    }

    if (this.component.value == undefined) {
      this.component.value = "";
    }

    this.onCreated();
  }

}
