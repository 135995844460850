import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FilterSelectComponent } from "./filter-select/filter-select.component";
import { FilterTimeComponent } from "./filter-time/filter-time.component";
import { FilterComponent } from "./filter.component";

@NgModule({
    declarations: [
        FilterComponent,
        FilterSelectComponent,
        FilterTimeComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FilterComponent,
        FilterSelectComponent,
        FilterTimeComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FilterModule { }