<div class="card w-100 mt-3">

    <div class="card-body">

        <h5 class="card-title">Condicionais</h5>

        <div class="d-flex">
            <div>
                <ng-container *ngFor="let item of components; let position = index">
                    <div class="row mb-3">
                        <div class="col-auto">
                            <span>Se a resposta for</span>
                            <div class="mt-2">
                                <select name="" id="" [value]="components[position].type"
                                    (change)="onChangeTypeOfConditional($event.target.value,position)">
                                    <option value="==">Igual a</option>
                                    <option value="!=">Diferente de</option>
                                    <option value="<">Menor que</option>
                                    <option value="<=">Menor ou igual a</option>
                                    <option value=">">Maior que</option>
                                    <option value=">=">Maior ou igual a</option>
                                    <option value=">=<=">Entre</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-auto">
                            <span>{{components[position].type != '>=<=' ? 'Valor' : 'Valor 1' }}</span>
                            <div class="mt-2">
                                <input class="form-control" type="text" [value]="components[position].a"(change)="setValueA($event.target.value, position)">
                            </div>
                        </div>

                        <div class="col-auto" *ngIf="components[position].type == '>=<='">
                            <p style="margin-top: 38px;">E</p>
                        </div>


                        <div class="col-auto" *ngIf="components[position].type == '>=<='">
                            <span>Valor 2</span>
                            <div class="mt-2">
                                <input class="form-control" type="text" [value]="components[position].b"
                                    (change)="setValueB($event.target.value, position)">
                            </div>
                        </div>


                        <div class="col-auto">
                            <span>Pontos</span>
                            <div class="mt-2">
                                <input class="form-control" type="text" [value]="components[position].points"
                                    (change)="setConditionalPoints($event.target.value, position)">
                            </div>
                        </div>

                        <div class="col-auto">

                            <div style="margin-top: 32px;">
                                <button class="btn btn-primary material-icons"
                                    (click)="deleteToggle(position)">delete</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="d-flex w-100">
                    <div style="margin-left: auto;">
                        <button class="btn btn-primary" (click)="addNewToggle()">
                            <span class="material-icons mr-3" style="vertical-align: bottom;">
                                add
                            </span> Nova condicional
                        </button>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>