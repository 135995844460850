import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventClickModel } from './EventClickModel';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  @Input() features;
  @Input() position;
  @Input() item;
  @Input() default;
  @Output() onFeatureClickEvent = new EventEmitter<EventClickModel>();

  constructor() { }

  ngOnInit(): void {

    //console.log(this.item);
  }

  onClickEvent(event) {
    this.onFeatureClickEvent.emit({
      event: event,
      position: this.position,
      item: this.item
    });
  }


}
