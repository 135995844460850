<app-modal [cancelable]="true" (closeModal)="setCloseModal()" title="Criar novo bloco" widthContent="400">

    <div style="margin-bottom: 32px;">
        <span class="form-label">Nome</span>
        <input type="text" class="form-control mb-3 mt-3" placeholder="Digite o nome do bloco" (change)="newBlockConfig.title = $event.target.value">
        <p class="error-label" *ngIf="newBlockConfig.title == '' && onSubmitCreate">Digite o nome do bloco</p>
    </div>

    <div style="margin-bottom: 32px;">
        <span class="form-label">Descrição</span>
        <input type="text" class="form-control mb-3 mt-3" placeholder="Digite a descrição do bloco" (change)="newBlockConfig.description = $event.target.value">
        <p class="error-label" *ngIf="newBlockConfig.description == '' && onSubmitCreate">Digite a descrição do bloco</p>
    </div>

    <div class="mb-3">
        <span class="form-label mb-3">Tipo de bloco</span>

        <div class="d-flex" style="margin-top: 32px;">
            <div style="width: 200px;margin-right: -2px; z-index: 3;margin-top: 8px;">
                <ng-container *ngFor="let item of interfaceConfig">
                    <h5 class="w-100 search-type-button" [ngClass]="{'selected': item.show}"
                        (click)="selectInterface(item.type)">
                        {{item.name}}
                    </h5>
                </ng-container>


            </div>
            <div class="card w-100">
                <div class="card-body">
                    <ng-container *ngFor="let item of interfaceConfig">
                        <div class="d-flex" *ngIf="item.show">
                            <img src="{{item.template.icon}}" alt="" style="height: 450px;margin-right: 32px;">
                            <div>
                                <h3>{{item.template.title}}</h3>
                                <p>{{item.template.description}}</p>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>

    <div class="d-flex">
        <div class="ml-auto">
            <div class="btn btn-ligth mr-3" (click)="setCloseModal()">Cancelar</div>
            <div class="btn btn-primary" (click)="typeSelected()">Usar esse tipo</div>
        </div>
    </div>

</app-modal>