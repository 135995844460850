import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-dados',
  templateUrl: './modal-dados.component.html',
  styleUrls: ['./modal-dados.component.scss']
})
export class ModalDadosComponent implements OnInit {

  // @Input() idModalDetails = '';
  loading = true;
  error = false;

  loadingVisualizar = true;
  errorVisualizar = false;

  dataClaim: any;

  constructor() { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    //console.log('dados do modal chegou!');
    // this.loading = true;
    // this.error = false;
    // try {
    //   this.SinisterService.getSinistros(this.idModalDetails).subscribe((response) => {
    //       this.dataClaim = response;
    //       this.loading = false;
    //   },() => {this.msgError()});
    // } catch (error) {
    //   this.msgError();
    // }
  }

}
