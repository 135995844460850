import { Component, Input, OnInit, Output } from '@angular/core';
import { ComponentView } from '../ComponentView';

@Component({
  selector: 'app-component-view-manager',
  templateUrl: './component-view-manager.component.html',
  styleUrls: ['./component-view-manager.component.scss']
})
export class ComponentViewManagerComponent extends ComponentView implements OnInit{

 
  constructor() {
    super();
  }

  ngOnInit(): void {

    //console.log(this.components);

    console.log("view manager block:",this.block);

    this.onCreated();
  }

}
