<ng-template #content let-modal>
    <div class="modal-header">
        <span class="modal-title h4">Resultado da Importação</span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0" style="overflow-y: auto;">
        <div class="container-fluid">
            <div class="row" *ngIf="data.notAccepteds.length">
                <h2><span class="material-icons">warning</span> ALERTA!</h2>
                <p>Alguns itens não foram aceitos. Abaixo pode se visualizar uma lista com as linhas</p>
            </div>
            <div class="row">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-center">Total</th>
                            <th class="text-center">Adicionados</th>
                            <th class="text-center">Repetidos</th>
                            <th class="text-center">Excluídos</th>
                            <th class="text-center">Não aceitos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">{{data.count}}</td>
                            <td class="text-center">{{data.created}}</td>
                            <td class="text-center">{{data.found}}</td>
                            <td class="text-center">{{data.clear}}</td>
                            <td class="text-center">{{data.notAccepteds.length}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row" *ngIf="data.notAccepteds.length">
                <div class="text-center text-weight-bold">
                    <h4>Não aceitos</h4>
                </div>
                <table class="table" style="overflow-x: auto;">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th *ngFor="let header of headers">{{header.value}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let notAccepted of data.notAccepteds">
                            <td>{{notAccepted.index + 2}}</td>
                            <td *ngFor="let header of headers" [ngClass]="{'bg-danger': notAccepted.reason[header.key], 'text-white': notAccepted.reason[header.key]}" style="white-space: nowrap;">
                                {{notAccepted.item[header.key]}} <span class="badge badge-light" *ngIf="notAccepted.reason[header.key]">Não encontrado</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
    </div>
</ng-template>