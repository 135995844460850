export interface IExporters {
    exportData: { data: any[] },
    exportFiles: any[],
    exportNames: string[]
}

export class Exporters {

    abortExportFiles: boolean = false;
    exportData: { data: any[] };
    exportFiles: any[];
    exportNames: string[];

    constructor(props: IExporters) {

        const { exportData, exportFiles, exportNames } = props;

        this.exportData = exportData;
        this.exportFiles = exportFiles;
        this.exportNames = exportNames;

    }

    progressCallback: (progress) => {};
    finishCallback: (data, file_name) => {};

    start = () => {

    }

    setOnProgressCallback = (progressCallback) => {
        this.progressCallback = progressCallback;
        return this;
    }
    setOnFinishCallback = (finishCallback) => {
        this.finishCallback = finishCallback;
        return this;
    }

    abort = () => {
        this.abortExportFiles = true;
    }
}