import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { ModalDadosComponent } from './modal-dados/modal-dados.component';
import { IndicatorComponent } from './indicator/indicator.component';
import { MapsComponent } from './maps/maps.component';
import { AgmCoreModule } from '@agm/core';
import { DidacticIndicatorComponent } from './didactic-indicator/didactic-indicator.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginatedTableComponent } from './paginated-table/paginated-table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BoxItemComponent } from './box-item/box-item.component';
import { RouterModule } from '@angular/router';
import { ButtonToggleStatusComponent } from './button-toggle-status/button-toggle-status.component';
import { ButtonToggleStatusCustomComponent } from './button-toggle-status-custom/button-toggle-status-custom.component';
import { ComponentViewManagerComponent } from './custom-searches/component-view-manager/component-view-manager.component';
import { QuestionCheckComponent } from './custom-searches/question-check/question-check.component';
import { PicturesComponent } from './custom-searches/pictures/pictures.component';
import { ModalExportFilesComponent } from './modal-export-files/modal-export-files.component';
import { DropFileInputComponent } from './drop-file-input/drop-file-input.component';
import { FeaturesComponent } from './paginated-table/features/features.component';
import { InputTextComponent } from './custom-searches/input-text/input-text.component';
import { TextAreaComponent } from './custom-searches/text-area/text-area.component';
import { InputNumberComponent } from './custom-searches/input-number/input-number.component';
import { InputPriceComponent } from './custom-searches/input-price/input-price.component';
import { DropDownPointsComponent } from './custom-searches/drop-down-points/drop-down-points.component';
import { DropDownItemsComponent } from './custom-searches/drop-down-items/drop-down-items.component';
import { ToggleComponentsComponent } from './custom-searches/toggle-components/toggle-components.component';
import { ConditionalPointsComponent } from './custom-searches/conditional-points/conditional-points.component';
import { ModalNewSearchComponent } from './modal-new-search/modal-new-search.component';
import { ModalNewSearchBlockComponent } from './modal-new-search-block/modal-new-search-block.component';
import { FrequencyEditorComponent } from './custom-searches/frequency-editor/frequency-editor.component';
import { CustomFrequencyComponent } from './custom-searches/custom-frequency/custom-frequency.component';
import { FiltersConfigComponent } from './custom-searches/filters-config/filters-config.component';
import { CustomFilterContainerComponent } from './custom-searches/custom-filter-container/custom-filter-container.component';
import { ModalShowCollectedItemsComponent } from './modal-show-collected-items/modal-show-collected-items.component';
import { ModalConfirmDeleteComponent } from './modal-confirm-delete/modal-confirm-delete.component';
import { NumericConditionalComponent } from './custom-searches/numeric-conditional/numeric-conditional.component';
import { ModalNewComponentComponent } from './modal-new-component/modal-new-component.component';
// import { ImportNotAcceptedModalComponent } from './import-not-accepted-modal/import-not-accepted-modal.component';
import { ImportResultModalComponent } from './import-result-modal/import-result-modal.component';
import { ImportModalComponent } from './import-modal/import-modal.component';
import { LoadingCircleComponent } from './loading-circle/loading-circle.component';
import { ExportButtonDefaultComponent } from './export-button-default/export-button-default.component';
import { LoadingDefaultTabletComponent } from './loading-default-table/loading-default-table.component';
import { LoadingDefaultTableModule } from './loading-default-table/loading-default-table.module';
import { MapsModule } from './maps/maps.module';
import { ModalListWithPaginatedFilterModule } from './modal-list-with-paginated-filter/modal-list-with-paginated-filter.module';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    ModalComponent,
    ModalDadosComponent,
    IndicatorComponent,
    DidacticIndicatorComponent,
    PaginatedTableComponent,
    BoxItemComponent,
    ButtonToggleStatusComponent,
    ButtonToggleStatusCustomComponent,
    ComponentViewManagerComponent,
    QuestionCheckComponent,
    PicturesComponent,
    ModalExportFilesComponent,
    DropFileInputComponent,
    FeaturesComponent,
    InputTextComponent,
    TextAreaComponent,
    InputNumberComponent,
    InputPriceComponent,
    DropDownPointsComponent,
    DropDownItemsComponent,
    ToggleComponentsComponent,
    NumericConditionalComponent,
    ConditionalPointsComponent,
    ModalNewSearchComponent,
    ModalNewSearchBlockComponent,
    FrequencyEditorComponent,
    CustomFrequencyComponent,
    FiltersConfigComponent,
    CustomFilterContainerComponent,
    ModalShowCollectedItemsComponent,
    ModalConfirmDeleteComponent,
    ModalNewComponentComponent,
    // ImportNotAcceptedModalComponent,
    ImportResultModalComponent,
    ImportModalComponent,
    LoadingCircleComponent,
    ExportButtonDefaultComponent,
  ],
  imports: [
    DragDropModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAB3sd8hSP8jNeFJTsPSPNkdy149KA-dfA'
    }),
    NgbModule,
    NgxPaginationModule,
    CarouselModule,
    RouterModule,
    LoadingDefaultTableModule,
    MapsModule,
    ModalListWithPaginatedFilterModule
  ],
  exports: [
    BreadcrumbComponent,
    ModalComponent,
    ModalDadosComponent,
    IndicatorComponent,
    DidacticIndicatorComponent,
    PaginatedTableComponent,
    NgxPaginationModule,
    CarouselModule,
    BoxItemComponent,
    ButtonToggleStatusComponent,
    ButtonToggleStatusCustomComponent,
    DropFileInputComponent,
    ComponentViewManagerComponent,
    QuestionCheckComponent,
    PicturesComponent,
    ModalExportFilesComponent,
    FeaturesComponent,
    InputTextComponent,
    TextAreaComponent,
    InputNumberComponent,
    InputPriceComponent,
    DropDownPointsComponent,
    DropDownItemsComponent,
    ToggleComponentsComponent,
    NumericConditionalComponent,
    ConditionalPointsComponent,
    ModalNewSearchComponent,
    ModalNewSearchBlockComponent,
    FrequencyEditorComponent,
    CustomFrequencyComponent,
    FiltersConfigComponent,
    CustomFilterContainerComponent,
    ModalShowCollectedItemsComponent,
    ModalConfirmDeleteComponent,
    ModalNewComponentComponent,
    // ImportNotAcceptedModalComponent,
    ImportResultModalComponent,
    ImportModalComponent,
    LoadingCircleComponent,
    ExportButtonDefaultComponent,
  ],
  bootstrap: [DidacticIndicatorComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class DefaultComponentsModule { }
