import { Component, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'import-result-modal',
    templateUrl: './import-result-modal.component.html',
    styleUrls: [
        './import-result-modal.component.scss'
    ]
})
export class ImportResultModalComponent {
    @ViewChild('content') content;
    headers = [];
    data = {
        count: 0,
        created: 0,
        found: 0,
        clear: 0,
        notAccepteds: []
    };
    constructor(
        private modal: NgbModal,
    ) {}

    open(headers, data) {
        this.headers = headers;
        this.data = data;
        return this.modal.open(this.content, {centered: true, windowClass: 'auto-width'}).result;
    }
}