<nav class="navbar" clickOutside role="navigation" aria-label="main navigation" (clickOutside)="clickOutside()">
  <!-- <img src="../../../../assets/img/initial_logo.png" alt="Logo Minalba"> -->
  <p>{{today | date:'dd/MM/yyyy'}}, {{today | date:'h:mm a'}}</p>

  <div *ngIf="isLoggedIn" data-test="navbar-menu" class="navbar-menu" (click)="configs()">
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="cargo">
          <p>{{ dados.personal_info.complete_name }}</p>
          <p>{{ dados.hiear.display_value }}</p>
        </div>
        <div class="containerImg" *ngIf="dados.personal_info.avatar_url">
          <img [src]="dados.personal_info.avatar_url" alt="Imagem usuário" id="imgUser">
        </div>
        <div class="containerUser" *ngIf="!dados.personal_info.avatar_url">
          <span>
            {{sigla}}
          </span>
        </div>
        <span class="material-icons md-24 whitecolor">
          keyboard_arrow_down
        </span>
      </div>
    </div>
  </div>
</nav>

<nav class="menuPrincipal">
  <ul>
    <figure class="home">
      <img style="width: 50px; height: 50px;" src="../../../../assets/img/mMinalba.png" alt="Logo Minalba" id="mMinalba">
      <h3>
        <img style="width: 180px;" src="../../../../assets/img/logo_azul.svg" alt="Logo Minalba">
      </h3>
    </figure>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/home']">
        <span>
          <img src="../../../../assets/icons/gestao.svg" alt="Ícone tela gestao">
        </span>
        <h3>Gestão</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/produtividade']">
        <span class="material-icons md-24 whitecolor">
          assignment_turned_in
        </span>
        <h3>Produtividade</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/visitas']">
        <span class="material-icons md-24 whitecolor">
          search
        </span>
        <h3>Visitas</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/rotina-de-trabalho']">
        <span class="material-icons md-24 whitecolor">
          work
        </span>
        <h3>Rotina de Trabalho</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/ruptura']">
        <span class="material-icons md-24 whitecolor">
          broken_image
        </span>
        <h3>Rupturas</h3>
      </a>
    </li>
    <li *ngIf="authorization[1].authorized">
      <a [routerLink]="['/preco']">
        <span class="material-icons md-24 whitecolor">
          monetization_on
        </span>
        <h3>Preços</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/lojaperfeita']">
        <span class="material-icons md-24 whitecolor">
          local_convenience_store
        </span>
        <h3>Loja Perfeita</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/shelf']">
        <span class="material-icons md-24 whitecolor">
          event_available
        </span>
        <h3>Shelf</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/concorrencia']">
        <span class="material-icons md-24 whitecolor">
          sports_mma
        </span>
        <h3>Concorrência</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/ocasioes-de-consumo']">
        <span class="material-icons md-24 whitecolor">
          shopping_cart
        </span>
        <h3>Ocasiões de Consumo</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/extra-points']">
        <span class="material-icons md-24 whitecolor">
          shopping_cart
        </span>
        <h3>Pontos extras</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/merchandising']">
        <span class="material-icons md-24 whitecolor">
          local_activity
        </span>
        <h3>Merchandising</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/exposition-share']">
        <span class="material-icons md-24 whitecolor">
          share
        </span>
        <h3>Share</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/cooling']">
        <span class="material-icons md-24 whitecolor">
          kitchen
        </span>
        <h3>Geladeira</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/bonus']">
        <span class="material-icons md-24 whitecolor">
          star_rate
        </span>
        <h3>Bônus</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/estoque_virtual']">
        <span class="material-icons md-24 whitecolor">
          widgets
        </span>
        <h3>Estoque Virtual</h3>
      </a>
    </li>
    <!-- <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/coaching']">
        <span class="material-icons md-24 whitecolor">
          search
        </span>
        <h3>Coaching</h3>
      </a>
    </li> -->
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/pesquisa-spot']">
        <span class="material-icons md-24 whitecolor">
          search
        </span>
        <h3>Pesquisa Spot</h3>
      </a>
    </li>
    <li *ngIf="authorization[0].authorized">
      <a [routerLink]="['/cartao-ponto']">
        <span class="material-icons md-24 whitecolor">
          schedule
        </span>
        <h3>Cartão de Ponto</h3>
      </a>
    </li>
    <!-- <li>
      <a [routerLink]="['/rota']">
        <span class="material-icons md-24 whitecolor">
          alt_route
        </span>
        <h3>Rota</h3>
      </a>
    </li> -->
    <!-- <li>
      <a>
        <span class="material-icons md-24 whitecolor">
          assignment
        </span>
        <h3>Material PDV</h3>
      </a>
    </li> -->
    <!-- <li>
      <a>
        <span class="material-icons md-24 whitecolor">
          assignment_late
        </span>
        <h3>Anomalias</h3>
      </a>
    </li> -->
  </ul>

  
</nav>

<div id="modal-notification" *ngIf="notification" class="notificationBox">
  <p id="titleperson">Olá, {{ dados.personal_info.complete_name }} !</p>
  <div class="content">
    <a [routerLink]="['/ferramentas']" *ngIf="authorization[0].authorized">
      <span class="material-icons md-24 darkcolor">
        build
      </span>
      <p>Ferramentas</p>
    </a>
    <a *ngIf="authorization[0].authorized">
      <span class="material-icons md-24 darkcolor" >
        games
      </span>
      <p>Gameficação</p>
    </a>
    <button (click)="logOut()" class="btnLogout">
      <span class="material-icons md-24 darkcolor">
        logout
      </span>
      <p>Sair</p>
    </button>
  </div>
</div>