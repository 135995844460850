import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'export-button-default',
  templateUrl: './export-button-default.component.html',
  styleUrls: ['./export-button-default.component.scss']
})
export class ExportButtonDefaultComponent implements OnInit {

  constructor() { }

  @Input() text: string = "Exportar";
  @Input() loadingExport: boolean = false;
  @Output() export = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  exportItems(){
    this.export.emit(true);
  }
}
