import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface ModalListWithPaginatedFilterProps {
  title: string,
  allData: any[],
  selectAll: boolean,
  blockSelectAll: boolean,
  onlySelected: boolean,
  searchValue: string,
  onFilterRemoved: () => any
  onUpdate: (props: ModalListWithPaginatedFilterProps) => any,
  keysSelected: string[],
  targetKey: string,
  exportKey: string
}

@Component({
  selector: 'app-modal-list-with-paginated-filter',
  templateUrl: './modal-list-with-paginated-filter.component.html',
  styleUrls: ['./modal-list-with-paginated-filter.component.scss'],
})
export class ModalListWithPaginatedFilterComponent implements OnInit {


  @Input() props = <ModalListWithPaginatedFilterProps>{
    title: "",
    url: "",
    allData: [],
    selectAll: true,
    blockSelectAll: false,
    onlySelected: false,
    searchValue: "",
    keysSelected: [],
    onFilterRemoved: () => { },
    onUpdate: (props: ModalListWithPaginatedFilterProps) => { },
    targetKey: "name",
    exportKey: "id"
  };

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {


  }


  setCloseModal(result: boolean) {

    this.activeModal.close({
      success: result
    })
  }

  searchValueChangeEvent(event: string) {
    this.props.searchValue = event;
    // this.props.onUpdate(this.props);
  }

  selectAllChangeEvent(event: boolean) {
    this.props.selectAll = event;

    // this.props.onUpdate(this.props)
  }

  onlySelectedChangEvent(event: boolean) {
    this.props.onlySelected = event;

    // this.props.onUpdate(this.props)

  }

  removeFiltersChange() {
    this.props.onFilterRemoved();

    this.setCloseModal(true);
  }

  keysSelectedChange(event: string[]) {
    this.props.keysSelected = event;

    this.props.onUpdate(this.props)

    this.setCloseModal(true);
  }

}
