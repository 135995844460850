import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentView } from '../ComponentView';

@Component({
  selector: 'app-toggle-components',
  templateUrl: './toggle-components.component.html',
  styleUrls: ['./toggle-components.component.scss']
})
export class ToggleComponentsComponent extends ComponentView implements OnInit, OnChanges {

  constructor() {
    super()
  }

  parent_position = 0;
  toggleAllComponentsId = [];

  ngOnInit(): void {

    if (this.components == undefined) {
      this.components = []
    }

    for (let c_item of this.components) {

      if(!c_item.toggle_type_action){
        c_item.toggle_type_action = 'question'
      }
      c_item.parent_id = this.parent.component_id;
    }
    

    //console.log(this.components);

    this.parent_position = this.parent.position;

    this.defineComponentsId();

    this.onCreated();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes);
    this.defineComponentsId();
  }

  defineComponentsId() {
    if (this.allComponentsId) {

      this.toggleAllComponentsId = JSON.parse(JSON.stringify(this.allComponentsId));

      this.removeCurrentParentComponentIdFromList();

      //console.log(this.toggleAllComponentsId);
    }

  }

  removeCurrentParentComponentIdFromList() {

    let parent_component_id = this.parent.component_id;

    let search_id_parent_id = this.toggleAllComponentsId.find(element => element == parent_component_id);

    if (search_id_parent_id) {

      //console.log(parent_component_id, this.toggleAllComponentsId.indexOf(search_id_parent_id));

      this.toggleAllComponentsId.splice(this.toggleAllComponentsId.indexOf(search_id_parent_id), 1);
    }

  }

  addNewToggle() {
    this.components.push({
      parent_id: this.parent.component_id,
      toggle_type: "active",
      component_id: "",
      value: 0,
      type: "==",
      toggle_type_action:'question',
      a: 1,
      b: 0
    })

    if (this.componentUpdateCallback) {
      this.componentUpdateCallback(this.component);
    }

  }

  deleteToggle(position: number) {
    this.components.splice(position, 1);
  }

  onChangeYesOrNo(event, position) {
    let component = this.components[position];

    component.a = parseInt(event.target.value);

  }

  onChangeAction(event, position) {
    let component = this.components[position];

    let action = event.target.value;

    if(action == 'disable'){
      component.toggle_type_action = 'question';
    }

    component.toggle_type = action;
  }

  onChangeTypeAction(event, position) {

    let component = this.components[position];

    let action = event.target.value;

    if (action == "alert") {
      if (!component.alert) {
        component.alert = {
          color: "",
          value: "",
          alert_id:`alert_${this.parent.component_id}_${position}`
        }
      }
    } else {
      if (component.alert) {
        delete component.alert;
      }
    }

    component.toggle_type_action = action;

  }

  setAlertColor(color, position) {
    let component = this.components[position];

    component.alert.color = color;
  }

  setAlertValue(value, position) {
    let component = this.components[position];

    component.alert.value = value;
  }

  onChangeComponentId(event, position) {
    let component = this.components[position];

    component.component_id = event.target.value;
  }

  setSelectComponentIdValue(position, component_id) {

    let select = <HTMLSelectElement>document.getElementById(`select_component_${this.parent.component_id}_${position}`);

    select.value = component_id;
  }

}


