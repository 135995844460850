import * as JSZip from "jszip";
import PptxGenJS from 'pptxgenjs';
import StringManager from "src/app/utils/StringManager";
import { Exporters, IExporters } from "./Exporters";
import { SlideModel } from "./SlideModel";


export default class PowerPointManager extends Exporters {

    abortExportFiles: boolean = false;
    exportSize = 0;
    exportProgress = 0;
    zip: JSZip;

    progressCallback: (progress) => {};
    finishCallback: (data, file_name) => {};

    constructor(props: IExporters) {
        super(props);

        this.zip = new JSZip();
    }

    setOnProgressCallback = (progressCallback) => {
        this.progressCallback = progressCallback;
        return this;
    }
    setOnFinishCallback = (finishCallback) => {
        this.finishCallback = finishCallback;
        return this;
    }

    //Criando objeto de slides
    start = () => {

        let stringManager = new StringManager();
        let slides = {};

        for (let index in this.exportData.data) {

            if (this.abortExportFiles) {
                break;
            }

            let c_export_item = this.exportData.data[index];

            let unique_key = stringManager.normalize(c_export_item.key_2);
            unique_key = unique_key.replace(/ /g, "_");
            unique_key = unique_key.toUpperCase();

            let c_slide: SlideModel;

            if (!slides[unique_key]) {

                c_slide = {
                    images: [],
                    name: c_export_item.key_2,
                    unique_key: unique_key,
                    channel: c_export_item.key_4,
                    region: c_export_item.key_5,
                    pdv_name: c_export_item.key_3,
                    document_name: `${c_export_item.key_2} Spot Images`
                };

                slides[unique_key] = c_slide;

            }

            c_slide = slides[unique_key];


            for (let key in c_export_item) {

                if (this.abortExportFiles) {
                    break;
                }

                let c_item = c_export_item[key];

                if (typeof c_item == "object" && c_item.type) {

                    if (c_item.type == "images") {

                        let images = c_item.images;

                        for (let key in images) {
                            let c_image = images[key];
                            c_slide.images.push(c_image)
                        }

                    }
                }
            }

            if (!c_slide.images.length) {
                delete slides[unique_key]
                continue;
            }

            if (!this.exportNames.includes(c_slide.document_name)) {
                this.exportNames.push(c_slide.document_name);
            }

            this.exportFiles.push({
                unique_key: c_slide.unique_key,
                file_name: c_slide.document_name,
                status: false
            })

        }

        let current_index = 0;
        let size = Object.keys(slides).length;

        this.exportSize = size;
        this.exportProgress = current_index;

        if (this.progressCallback)
            this.progressCallback({
                exportSize: this.exportSize,
                exportProgress: this.exportProgress
            })

        for (let key in slides) {

            if (this.abortExportFiles) {
                break;
            }

            let c_slide = slides[key];

            const pptx = new PptxGenJS();

            this.addSlideByExportItem(pptx, c_slide)

            pptx.write()
                .then((data) => {

                    let c_export = this.exportFiles.find(element => element.unique_key == c_slide.unique_key);
                    c_export.status = true;

                    this.zip.file(`${c_slide.document_name}.pptx`, data);

                    current_index++;
                    this.exportProgress = current_index;

                    this.progressCallback({
                        exportSize: this.exportSize,
                        exportProgress: this.exportProgress
                    })

                    this.compareAndExportZip();

                })
                .catch((err) => {
                    current_index++;
                    //console.log(err);
                })

        }

        return this;
    }

    compareAndExportZip = () => {

        if (this.abortExportFiles) {
            return;
        }

        if (this.exportProgress < this.exportSize) {
            return;
        }

        this.zip.generateAsync({ type: "blob" })
            .then((data) => {
                // this.saveBlob(data, "Pesquisa Spot.zip")
                if (this.finishCallback)
                    this.finishCallback(data, "Pesquisa Spot.zip");
            })
            .catch((err) => {
                //console.log(err);
            })

        //this.toastr.success('Arquivo gerado com Sucesso!!', 'Exportação PPTX', { timeOut: 4000 });
        //this.setCloseModalExportFiles();
    }

    addSlideByExportItem = (pptx, c_slide) => {

        let user_name = c_slide.name;
        let channel = c_slide.channel;
        let region = c_slide.region;
        let pdv_name = c_slide.pdv_name;
        let images = c_slide.images;

        let slide = pptx.addSlide()

        slide.addText(user_name, { x: 0, y: 0.2 })
        slide.addText(`${channel.toUpperCase()} / ${region.toUpperCase()} | ${pdv_name}`, { x: 0, y: 0.5, fontSize: 13 })

        this.addImages(pptx, slide, user_name, channel, region, images);

    }

    addSlideByExtraImages = (pptx, user_name, channel, region, images) => {

        let slide = pptx.addSlide()

        slide.addText(user_name, { x: 0, y: 0.2 })
        slide.addText(`${channel.toUpperCase()} / ${region.toUpperCase()}`, { x: 0, y: 0.5, fontSize: 13 })

        this.addImages(pptx, slide, user_name, channel, region, images);

    }

    addImages = (pptx, slide, user_name, channel, region, images) => {

        let extra_images = [];

        if (images.length > 1) {

            for (let position = 0; position < images.length; position++) {

                if (this.abortExportFiles) {
                    break;
                }


                let c_image = images[position];

                let x_position = 0;

                switch (position) {
                    case 0:
                        x_position = 0.1
                        break;
                    case 1:
                        x_position = 3.2
                        break;
                    case 2:
                        x_position = 6.3
                        break;
                }

                if (position < 3) {
                    slide.addImage({ path: c_image, x: x_position, y: 0.8, w: 3.0, h: 4.5 })
                } else {
                    extra_images.push(c_image)
                }

            }

        } else if (images.length == 1) {
            let c_image = images[0];

            slide.addImage({ path: c_image, x: '25%', y: 0.8, w: 3.0, h: 4.5 })
        } else {
            slide.addText(`Nenhuma imagem encontrada`, { x: 0, y: 0.8, fontSize: 22 })
        }


        if (extra_images.length > 0) {
            this.addSlideByExtraImages(pptx, user_name, channel, region, extra_images);
        }

    }


}