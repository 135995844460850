import { Component, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FilterService } from "src/app/core/services/filters/filters.service";
import { FilterSelectComponent } from "./filter-select/filter-select.component";

@Component({
    selector: 'filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
    @Input() people: boolean;
    @Input() locations: boolean;
    @Input() pdvs: boolean;
    @Input() callback;

    filterLoading = true;

    fromDate = null;
    toDate = null;
    promoters = [];

    @ViewChildren(FilterSelectComponent) filterSelects: QueryList<FilterSelectComponent>;
    constructor(private filterService: FilterService) {
        
    }

    ngOnInit() {
        this.filterService.getFilters(true, this.locations, this.locations, 
            this.people, this.people, this.people, this.people, this.people,
            this.pdvs, this.pdvs, this.pdvs, this.pdvs).subscribe(filters => {
            this.filterSelects.forEach(filterSelect => {
                let items = filters[filterSelect.name] as any[];
                if(items && Array.isArray(items)) {
                    items.sort((a, b) => a.name > b.name ? 1 : -1);
                    items.forEach(item => {
                        filterSelect.items.push({
                            ...item,
                            showing: true,
                            selected: false
                        });
                    });
                } else {
                    filterSelect.root.nativeElement.remove();
                }
            });

            this.filterLoading = false;
        });
    }


}