export class FilterSearch{

  search(value, input, method){
    let array = [];

    // search = retornar varios objetos com nomes parecidos com o input
    // verification = retornar se existe aquele valor

    //for no array de objetos
    for (let i = 0; i < value.length; i++) {

      let object_complete = value[i];

      //busca somente valores do objeto e converter em array
      let convert_values_objet_array = Object.values(value[i]);

      //checa se cada valor do objeto possui o valor pesquisado, se isto for verdadeiro é feito o push para variavel pdvs
      for (let j = 0; j < convert_values_objet_array.length; j++) {

        let value_only_object = this.stringNormalize(convert_values_objet_array[j])
        const data_object = convert_values_objet_array[j];

        switch (method) {
          case 'search':
            // Verificando se ele o input existe
            if(value_only_object.includes(this.stringNormalize(input))){

              if(!array.includes(object_complete)){
                array.push(object_complete)
              }
            }

            // Verificando se o input existe dentro do objeto
            try{
              // if(typeof data_object === "object"){
              //   const value_object = Object.values(data_object)  
              //   const value_only_object = this.stringNormalize(value_object)
                
              //   if(value_only_object.includes(this.stringNormalize(input))){
  
              //     // Se já tiver no array então não grave
              //     if(!array.includes(object_complete)){
              //       array.push(object_complete)
              //     }
              //   }
              // } 
            }catch(error){

            }

            break;

          case 'verification':
            if (value_only_object == input.toLocaleLowerCase()) {
              value_only_object.includes(this.stringNormalize(input)) ? array.push(object_complete) : false;
            }
            break;
        }
      }
    }

    return array;
  }

  stringNormalize(value) {
    //método para remover acentos e converter letras em minúsculas

    return String(value).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").trimEnd();
  }
}

