export default class FormatFilterData {

    formated_filters;
    default_filters_data;

    constructor(formated_filters, default_filters_data) {
        this.formated_filters = JSON.parse(JSON.stringify(formated_filters));
        this.default_filters_data = JSON.parse(JSON.stringify(default_filters_data));
    }

    formatData() {

        for (let c_formated of this.formated_filters) {

            let current_selected = c_formated.current_selected;
            let filter_type = c_formated.filter_type;

            switch (current_selected) {
                case "channel_list":
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "regions",
                        target_default_key: "name",
                        target_default_data: "channel_list",
                        target_default_data_key: undefined
                    })
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "networks",
                        target_default_key: "name",
                        target_default_data: "channel_list",
                        target_default_data_key: undefined
                    })
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "pdvs",
                        target_default_key: "channel",
                        target_default_data: undefined,
                        target_default_data_key: undefined
                    })
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "users",
                        target_default_key: "name",
                        target_default_data: "channel_list",
                        target_default_data_key: undefined
                    })

                    break;
                case "region_list":
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "channels",
                        target_default_key: "name",
                        target_default_data: "region_list",
                        target_default_data_key: undefined
                    })
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "networks",
                        target_default_key: "name",
                        target_default_data: "region_list",
                        target_default_data_key: undefined
                    })
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "pdvs",
                        target_default_key: "region",
                        target_default_data: undefined,
                        target_default_data_key: undefined
                    })
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "users",
                        target_default_key: "name",
                        target_default_data: "region_list",
                        target_default_data_key: undefined
                    })
                    break;
                case "network_list":
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "channels",
                        target_default_key: "name",
                        target_default_data: "network_list",
                        target_default_data_key: undefined
                    })
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "regions",
                        target_default_key: "name",
                        target_default_data: "network_list",
                        target_default_data_key: undefined
                    })
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "pdvs",
                        target_default_key: "network",
                        target_default_data: undefined,
                        target_default_data_key: undefined
                    })
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "users",
                        target_default_key: "name",
                        target_default_data: "network_list",
                        target_default_data_key: undefined
                    })
                    break;
                case "pdv_list":
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "channels",
                        target_default_key: "name",
                        target_default_data: "pdv_list",
                        target_default_data_key: undefined
                    })
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "regions",
                        target_default_key: "name",
                        target_default_data: "pdv_list",
                        target_default_data_key: undefined
                    })
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "networks",
                        target_default_key: "name",
                        target_default_data: "pdv_list",
                        target_default_data_key: undefined
                    })
                    break;
                case "hiear_list":
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "users",
                        target_default_key: "name",
                        target_default_data: "hiear_list",
                        target_default_data_key: undefined
                    })
                    break;
                case "user_list":

                    
                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "hiears",
                        target_default_key: "name",
                        target_default_data: "users_list",
                        target_default_data_key: undefined
                    })

                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "pdvs",
                        target_default_key: "name",
                        target_default_data: "users_list",
                        target_default_data_key: undefined
                    })

                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "channels",
                        target_default_key: "name",
                        target_default_data: "users_list",
                        target_default_data_key: undefined
                    })

                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "regions",
                        target_default_key: "name",
                        target_default_data: "users_list",
                        target_default_data_key: undefined
                    })

                    this.formatOtherFilters({
                        formated_filter_type: filter_type,
                        formated_filter_list: c_formated.filter_list,
                        target_default_filter: "networks",
                        target_default_key: "name",
                        target_default_data: "users_list",
                        target_default_data_key: undefined
                    })


                    break;
            }

        }

        return this.default_filters_data;

    }

    formatOtherFilters(props) {

        const { formated_filter_type, formated_filter_list, target_default_filter, target_default_key, target_default_data, target_default_data_key } = props;

        if (!formated_filter_list.length) {
            return;
        }

        let new_data = [];

        let c_default_filter = this.default_filters_data[target_default_filter];

        for (let item of formated_filter_list) {

            for (let c_data of c_default_filter) {

                if (target_default_data) {

                    let c_default_data = c_data[target_default_data];

                    if (Array.isArray(c_default_data)) {

                        if (target_default_data_key) {

                            if (c_default_data.find(element => element[target_default_data_key] == item)) {
                                new_data.push(c_data);
                            }

                        } else {

                            if (c_default_data.includes(item)) {

                                if (!new_data.find(element => element[target_default_key] == c_data[target_default_key]))
                                    new_data.push(c_data);

                            }

                        }

                    } else {

                    }

                } else {

                    if (target_default_data_key) {

                        let c_default_data = c_data[target_default_data_key];

                        if (c_default_data == item) {
                            new_data.push(c_data);
                        }

                    } else {

                        let c_default_data = c_data[target_default_key];

                        if (c_default_data == item) {
                            new_data.push(c_data);
                        }
                    }



                }
            }

        }

        this.default_filters_data[target_default_filter] = new_data;
    }

}