import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'drop-file-input',
    templateUrl: './drop-file-input.component.html',
    styleUrls: [
        './drop-file-input.component.scss'
    ]
})
export class DropFileInputComponent {
    @Output() change = new EventEmitter<any>();
    @Input() accept;
    @Input() icon = "upload_file";
    @Input() multiple = false;
    @Input() id = "file";

    file = null;
    hover = false;

    constructor(
        private toastr: ToastrService,
    ) { }

    changeFile(event) {
        if(event.target.files.length) {
            this.file = event.target.files[0];
            this.change.emit(this.multiple ? event.target.files : this.file);
        }
    }
    dropFile(event) {
        event.preventDefault();
        if(event.dataTransfer.files.length) {
            let file = event.dataTransfer.files[0];
            let acceptTypes = new Set([this.accept]);

            if(acceptTypes.has(file.type)) {
                this.file = file;
                this.change.emit(this.multiple ? event.dataTransfer.files : this.file);
            } else {
                this.toastr.error('Tipo de arquivo inválido');
            }
        }
        this.hover = false;
    }

    dragover(event) {
        event.preventDefault();
        this.hover = true;
    }

    dragend(event) {
        this.hover = false;
    }
}