
<div class="w-100 card mt-3 bg-light" [ngClass]="{'border-danger': component.title ==''}" cdkDrag [cdkDragDisabled]="inputFocus">
    <div class="card-header d-flex" (click)="toggleView($event.target.parentElement.parentElement)">
        <h2 class="m-0 w-100" *ngIf="component.title != ''">{{component.title}}</h2>
        <h2 class="m-0 w-100 error-label" *ngIf="component.title == ''">Digite a pergunta</h2>
        <h2 class="w-100 m-0" style="text-align: end;user-select: none;">#{{component.component_id}}</h2>
    </div>
    <div class="card-body">
        <h5 class="card-title">Pergunta Controle Deslizante (Sim / Não)</h5>
        <h6 class="card-subtitle mb-2 text-muted">Preencha as informações do controle deslizante</h6>

        <label for="basic-url" class="form-label">Pergunta</label>
        <div class="input-group mb-3">
            <span class="input-group-text" id="input_1_span_{{component.component_id}}">?</span>

            <input (mousedown)="$event.stopPropagation()"  id="input_title_{{position}}" type="text" class="form-control" (change)="setComponentTitle($event.target.value)" aria-describedby="input_1_span" value="{{component.title}}">
        </div>

        <div>
            <input (mousedown)="$event.stopPropagation()"  type="checkbox" id="checkConditionalPoints_{{component.component_id}}" (change)="toggleConditionalPoints($event)">
            <label for="checkConditionalPoints_{{component.component_id}}" class="ml-3">Adicionar pontos a essa pergunta?</label>

        </div>

        <div *ngIf="component.question_points">
            <app-conditional-points [component]="component.question_points" [parent]="component">

            </app-conditional-points>
        </div>
       

        <div class="container_components" *ngIf="component.components" >
            <app-component-view-manager [components]="component.components" [emmitUpdates]="true" [componentUpdateCallback]="componentUpdateCallback" [allComponentsId]="allComponentsId">
    
            </app-component-view-manager>
        </div>

        <div>
            <input (mousedown)="$event.stopPropagation()"  type="checkbox" id="checkToggleComponents_{{component.component_id}}" (change)="toggleComponentsQuestions($event)">
            <label for="checkToggleComponents_{{component.component_id}}" class="ml-3">Lógica de condicionais.</label>

        </div>
    
        <div class="container_components" *ngIf="component.toggle_components" >
            <app-toggle-components  [components]="component.toggle_components" [emmitUpdates]="true" [componentUpdateCallback]="componentUpdateCallback" [allComponentsId]="allComponentsId" [parent]="component">
    
            </app-toggle-components>
        </div>
        
        <!-- <div>
            <button type="button" class="btn btn-outline-secondary">Copiar</button>
            <button type="button" class="btn btn-outline-secondary">Publicar</button>
            <button type="button" class="btn btn-primary">Salvar</button>
        </div> -->

    </div>
    <div class="card-footer">
        <div class="d-flex">
            <div class="ml-auto">
                <button class="btn btn-danger" (click)="deleteCurrentComponent()">Remover</button>
            </div>
        </div>
    </div>
</div>