import { Component, Input, OnInit } from '@angular/core';
import { ComponentView } from '../ComponentView';

@Component({
  selector: 'app-question-check',
  templateUrl: './question-check.component.html',
  styleUrls: ['./question-check.component.scss']
})
export class QuestionCheckComponent extends ComponentView implements OnInit {


  constructor() {
    super()
  }

  ngOnInit(): void {

    if (Object.keys(this.component).length == (1 || 0)) {
      this.component = Object.assign(this.component, {
        title: "",
        checked: false,
      })
    }

    if (this.component.checked == undefined) {
      this.component.checked = false;
    }


    this.onCreated();
  }

  ngAfterViewInit() {

     
    let checkConditionalPoints = <HTMLInputElement>document.getElementById(`checkConditionalPoints_${this.component.component_id}`);
    let checkToggleComponents = <HTMLInputElement>document.getElementById(`checkToggleComponents_${this.component.component_id}`);

    if (checkConditionalPoints)
      checkConditionalPoints.checked = (this.component.conditional_points != undefined);

    if (checkToggleComponents)
      checkToggleComponents.checked = (this.component.toggle_components != undefined);

  }

  checkConditionalPoints = {
    checked: false
  }
  toggleConditionalPoints(event) {

    let inputToggleConditionalPoints = <HTMLInputElement>event.target;

    //console.log(inputToggleConditionalPoints.checked);

    if (inputToggleConditionalPoints.checked) {
      this.component.question_points = {}
    } else {
      delete this.component.question_points;
    }
  }
  

  toggleComponentsQuestions(event) {

    let inputToggleComponents = <HTMLInputElement>event.target;


    if (inputToggleComponents.checked) {
      this.component.toggle_components = []
    } else {
      delete this.component.toggle_components;
    }

  }

}
