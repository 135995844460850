<ng-template #content let-modal>
    <div class="modal-header">
        <span class="modal-title h4">Importando {{name}}</span>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h2><span class="material-icons">warning</span> ALERTA!</h2>
        <p>Ao importar é necessário que o arquivo siga o exemplo abaixo. Preste atenção nas duas formas de importação, para que não haja problemas dentro do sistema.</p>
        <p>No caso de haver vínculo com outra coleção a informação deve estar identico ao registrado no sistema, caso contrário a linha será ignorada pelo sistema</p>
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let header of headers">{{header.value}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let i of [0, 1, 2, 3]">
                    <th *ngFor="let description of descriptions">{{description.value}}</th>
                </tr>
            </tbody>
        </table>
        <drop-file-input (change)="loadFile($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></drop-file-input>
        <div class="field-control" style="width: 100%;" *ngIf="workbook">
            <div class="field">
                <select name="sheetName" id="sheetName" (change)="selectSheet($event.target.value)">
                    <option value="">Selecione um planilha</option>
                    <option *ngFor="let sheet of sheets" value="{{sheet.id}}">{{sheet.name}}</option>
                </select>
                <img src="../../../../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
            </div>
        </div>
        <div class="field-control" style="width: 100%;" *ngIf="items.length">
            <div class="field">
                <select name="importType" id="importType" (change)="changeImportType($event.target.value)">
                    <option value="">Selecione um tipo de importação</option>
                    <option value="add">Adicionar a lista existente</option>
                    <option value="clear">Excluir lista atual e importar</option>
                </select>
                <img src="../../../../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-primary" (click)="modal.close()">Cancelar</button>
        <button class="btn btn-primary" [ngClass]="{disabled: submitting || !items.length || importType == ''}" (click)="submit()">
            <div class="spinner-border spinner-border-sm mr-2 my-auto" role="status" *ngIf="submitting">
                <span class="visually-hidden">Loading...</span>
            </div>
            {{ submitting ? 'Enviando' : 'Enviar' }}
        </button>
    </div>
</ng-template>