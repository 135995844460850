<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous">

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
    crossorigin="anonymous"></script>

<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<div class="card w-100 mt-3" [ngClass]="{'border-danger': component.title ==''}" cdkDrag [cdkDragDisabled]="inputFocus">
    <div class="card-header d-flex" (click)="toggleView($event.target.parentElement.parentElement)">
        <h2 class="m-0 w-100" *ngIf="component.title != ''">{{component.title}}</h2>
        <h2 class="m-0 w-100 error-label" *ngIf="component.title == ''">Digite a pergunta</h2>
        <h2 class="w-100 m-0" style="text-align: end;user-select: none;">#{{component.component_id}}</h2>
    </div>
    <div class="card-body">
        <h5 class="card-title">Pergunta de Seleção única</h5>
        <label for="basic-url" class="form-label">Pergunta</label>
        <div class="input-group mb-3">
            <span class="input-group-text" id="input_{{position}}_span">?</span>
            <input (mousedown)="$event.stopPropagation()"  id="input_title_{{position}}" type="text" class="form-control"  aria-describedby="input_1_span">
        </div>
    </div>
</div>