<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ props.title }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="setCloseModal(false)"></button>
</div>

<div class="modal-body">
    <app-list-with-search-paginated-filter (onlySelectedChangEvent)="onlySelectedChangEvent($event)"
        (selectAllChangeEvent)="selectAllChangeEvent($event)" (keysSelectedChangeEvent)="keysSelectedChange($event)"
        (removeFiltersChangeEvent)="removeFiltersChange()" (searchValueChangeEvent)="searchValueChangeEvent($event)"
        [selectAll]="props.selectAll" [blockSelectAll]="props.blockSelectAll" [searchValue]="props.searchValue"
        [onlySelected]="props.onlySelected" [targetKey]="props.targetKey" [exportKey]="props.exportKey"
        [keysSelected]="props.keysSelected" [allData]="props.allData">

    </app-list-with-search-paginated-filter>
</div>