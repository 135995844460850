import { Component, Input } from '@angular/core';

@Component({
  selector: 'didactic-indicator',
  templateUrl: './didactic-indicator.component.html',
  styleUrls: ['./didactic-indicator.component.scss']
})
export class DidacticIndicatorComponent {
  @Input() title: string;
  @Input() value: string;
  @Input() description: string;
  @Input() loading: boolean;
  constructor() { }
}
