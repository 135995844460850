<div class="google-maps">
    <agm-map class="google-maps" [styles]='styles' [style]="setWitdh()" [latitude]='lat' [longitude]='lng' [mapTypeId]='googleMapType' (mapReady)="mapReadyHandler($event)">
        <ng-container *ngFor="let marker of markers">

            <agm-marker [latitude]="marker.position.lat" [longitude]="marker.position.lng"
                [iconUrl]="marker.icon" [agmFitBounds]="true">
                <agm-info-window>
                    <div>
                        <p>{{marker.name}}</p>

                    </div>
                </agm-info-window>
            </agm-marker>
            
        </ng-container>
    </agm-map>
</div>
