export default class StringManager{

    normalize(string){
        return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9]/g, " ")
    }

    returnUndefineFromEmpty(string){
        if(string == ""){
            return undefined;
        }else{
            return string;
        }
    }
    
}