import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { NgxMaskModule, IConfig } from 'ngx-mask'

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DefaultInterceptor } from './core/interceptors/default.interceptor';
import { NgCircleProgressModule } from 'ng-circle-progress';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common'
import { CookieService } from 'ngx-cookie-service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const config: SocketIoConfig = {
  url: 'http://localhost:4000/',
  options: {
    requestTimeout: 0,
    query: {
      "x-access-token":"m0G7WB4ZzXqgM1Twq5MzKcc6aLzN6sBi81eLwFxSQgbARqOb3Ulceh2dhXQWpSXqXD"
    },
    withCredentials: true

  }
}

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    ChartsModule,
    FormsModule,
    CoreModule,
    AppRoutingModule,
    MatIconModule,
    SharedModule,
    SocketIoModule.forRoot(config),
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    MatProgressBarModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgCircleProgressModule.forRoot({
      // deafult values
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300
    }),
    NgbModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    CookieService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }