import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  headers: HttpHeaders;
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "x-access-token": "m0G7WB4ZzXqgM1Twq5MzKcc6aLzN6sBi81eLwFxSQgbARqOb3Ulceh2dhXQWpSXqXD"
    });
  }

  filtersSelect(data = ''): Observable<any>{
    return this.http.post(`http://smartperformance.vseven.com.br/api/endpoint/filter/component?user_id=${data}`, {});
  }

  getFilters(
    filials = true,

    states = false, 
    cities = false, 
    
    directors = false,
    managers = false,
    coordinators = false,
    supervisors = false,
    promoters = false,
    
    markets = false,
    networks = false,
    channels = false,
    pdvs = false): Observable<any> {
    return this.http.get(`${this.environment.smartPerformanceApi}/filters?` +
      'filials=' + filials + 
      '&states=' + states + 
      '&cities=' + cities +
      '&directors=' + directors +
      '&managers=' + managers + 
      '&coordinators=' + coordinators + 
      '&supervisors=' + supervisors + 
      '&promoters=' + promoters,
        {headers: this.headers});
  }
  getOldFilters(data) {
    return this.http.post(`${this.environment.smartPerformanceApi}/filters/old`, data, {headers: this.headers});
  }

  filters(data): Observable<any> {
    return this.http.post(`${this.environment.smartPerformanceApi}/filter`, data, {headers: this.headers});
  }
}
