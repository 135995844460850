<!-- <div class="loading" *ngIf="loading">
    <wiz-loading type="spinner-continuous"> </wiz-loading>
  </div> -->
  <div class="buttonHeader">
    <button>
      <img src="../../../assets/icons/downloadIcon.svg" alt="Ícone de Download">
      Baixar PDF
    </button>
  </div>
  <div class="content" >
    <div class="infoDados">
      <div class="boxInfos">
        <label>Marca</label>
        <p>SAN PELLEGRINO</p>
      </div>
      <div class="boxInfos">
        <label>Categoria</label>
        <p>ÁGUA IMPORTADA</p>
      </div>
      <div class="boxInfos finishCollumn">
        <label>Sub Categoria</label>
        <p>-MVb4Wy0G73qfqIRT2L1</p>
      </div>
    </div>
    <div class="infoDados">
      <div class="boxInfos">
        <label>SKU Descrição</label>
        <p>AROMATIZADAS S. PELLEGRINO ESSENZA LATA 330ML TANGERINA</p>
      </div>
      <div class="boxInfos finishCollumn">
        <label>SKU Descrição Móvel</label>
        <p>Água Aromatizada 330ML</p>
      </div>
      <div class="boxInfos">
        <label>Concorrente</label>
        <p>Não</p>
      </div>
    </div>
    <div class="infoDados">
      <div class="boxInfos">
        <label>Coleta Regular</label>
        <p>Sim</p>
      </div>
      <div class="boxInfos">
        <label>Coleta Infovendas</label>
        <p>Não</p>
      </div>
      <div class="boxInfos finishCollumn">
        <label>Preço Mínimo</label>
        <p>Não definido</p>
      </div>
    </div>
    <div class="infoDados">
      <div class="boxInfos">
        <label>Preço Máximo</label>
        <p>Não definido</p>
      </div>
      <div class="boxInfos">
        <label>Código de Barras</label>
        <p>Não definido</p>
      </div>
      <div class="boxInfos">
        <label>Estado</label>
        <p>Ativo</p>
      </div>
    </div>
  </div>
  
  <!-- <section class="error" *ngIf="!loading && error">
    <span
      ><img src="../../../assets/timeline/Information.svg" alt="" /> Erro ao
      buscar dados</span
    >
    <button (click)="getData()">Recarregar</button>
  </section> -->
  