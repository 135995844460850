<div class="indicator" item="item">
    <div class="header">{{title}}</div>
    <div class="body">
        <p class="indicator-value"*ngIf="!loading">
            {{value}}</p>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
