import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  serverMode: boolean = !window.location.hostname.includes("localhost");

  smartPerformanceApi: string = this.serverMode ? environment.smartPerformanceApiServer : environment.smartPerformanceApiLocal;
  apiUrl: string = this.serverMode ? environment.smartPerformanceApiServer : environment.smartPerformanceApiLocal;
  accessToken = environment.accessToken;

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'x-access-token': environment.accessToken
  });
}