<div class="card w-100 mt-3">

    <div class="card-body">

        <h5 class="card-title">Condicionais</h5>

        <div class="d-flex">
            <div>
                <ng-container *ngFor="let item of components; let position = index">
                    <div class="row mb-3">
                        <div class="col-auto">
                            <span>Se a resposta for</span>
                            <div class="mt-2">
                                <select name="" id="" value="{{item.a == 1 ? '1' : '0'}}"
                                    (change)="onChangeYesOrNo($event,position)">
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-auto">
                            <span>Então</span>
                            <div class="mt-2">
                                <select name="" id="" value="{{item.toggle_type}}"
                                    (change)="onChangeAction($event,position)">
                                    <option value="active">Exibir</option>
                                    <option value="disable">Ocultar</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-auto">
                            <span>O Item</span>
                            <div class="mt-2">
                                <select name="" id="" [value]="item.toggle_type_action"
                                    (change)="onChangeTypeAction($event,position)">
                                    <option value="question">Questão</option>
                                    <option *ngIf="item.toggle_type == 'active'" [attr.selected]="item.toggle_type_action == 'alert' ? true : null" value="alert">Alerta</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-auto" *ngIf="item.toggle_type_action == 'alert'">
                            <div class="d-flex">
                                <div class="mr-3">
                                    <span>Cor do alerta</span>
                                    <div class="mt-2 d-flex">
                                        <input class="form-control" type="color" [value]="item.alert.color"
                                            (change)="setAlertColor($event.target.value,position)">
                                    </div>
                                </div>
                                <div>
                                    <span>Texto do alerta</span>
                                    <div class="mt-2 d-flex">
                                        <input class="form-control" type="text" placeholder="Digite o alerta" [value]="item.alert.value"
                                            (change)="setAlertValue($event.target.value,position)">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-auto" *ngIf="item.toggle_type_action == 'question'">
                            <span>A Questão</span>
                            <div class="mt-2">
                                <select name="" (change)="onChangeComponentId($event,position)"
                                    id="{{'select_component_'}}{{item.parent_id}}{{'_'+position}}"
                                    [value]="components[position].component_id">

                                    <ng-container *ngFor="let item2 of toggleAllComponentsId">

                                        <option [value]="item2.id"
                                            [attr.selected]="components[position].component_id == item2.id ? true : null">
                                            {{item2.title != ''? item2.title : item2.id}}
                                        </option>

                                    </ng-container>

                                </select>
                            </div>
                        </div>
                        <div class="col-auto">

                            <div style="margin-top: 32px;">
                                <button class="btn btn-primary material-icons"
                                    (click)="deleteToggle(position)">delete</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="d-flex w-100">
                    <div style="margin-left: auto;">
                        <button class="btn btn-primary" (click)="addNewToggle()">
                            <span class="material-icons mr-3" style="vertical-align: bottom;">
                                add
                            </span> Nova condicional
                        </button>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>