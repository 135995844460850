import { Component, Input } from '@angular/core';

@Component({
  selector: 'indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent {
  @Input() title: string;
  @Input() value: string;
  @Input() loading: boolean;
  constructor() { }
}
