import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';


@Component({
  selector: 'filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: [
    './filter-select.component.scss',
    '../filter.component.scss'
  ]
})
export class FilterSelectComponent implements OnChanges {

  @Input() name: string;
  @Input() loading: boolean;
  @Input() title: string;
  items = [];
  @ViewChild('select') select: HTMLSelectElement;

  constructor(
    public root: ElementRef) {
  }
  ngOnChanges() {
  }

  getSelectedLength() {
    return this.items.filter(item => item.selected).length;
  }

  getShowingLength() {
    return this.items.filter(item => item.showing).length;
  }

  getShowings() {
    let showings = this.items.filter(item => {
      if(item.showing) return item;
    });

    return showings;
  }

  changeSelected(item_id) {
    this.items.map(item => {
      if(item.id == item_id) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    this.items = [].concat(this.items);
  }
}
