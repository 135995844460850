import { Component, Input, OnInit } from '@angular/core';
import { ComponentView } from '../ComponentView';

@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss']
})
export class PicturesComponent extends ComponentView implements OnInit {


  constructor() {
    super()
  }

  ngOnInit(): void {

    if (!this.component.title) {
      this.component.title = "";
    }
    
    if (!this.component.images) {
      this.component.images = {};
    }

    this.onCreated();
  }

  number(value) {
    return Number(value);
  }

  setMinRequired(value) {


    if (this.number(value) > this.component.size) {
      value = this.component.size;
    }

    console.log(this.number(value), this.component.size);

    this.component.min_required = this.number(value);
  }
}
