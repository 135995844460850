import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'button-toggle-status-custom',
    templateUrl: './button-toggle-status-custom.component.html'
})
export class ButtonToggleStatusCustomComponent {
    @Input() status;
    labels = ["Aguardando","Aprovado","Não aprovado"];
    @Output() onClick = new EventEmitter();
    
    loading = false;
}