import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SearchManagmentService } from 'src/app/core/services/search-managment-service/search-managment.service';
import { FilterFormatedModel } from './FilterFormatedModel';
import { FilterModel } from './FilterModel';
import FormatFilterData from './FormatFilterData';

@Component({
  selector: 'app-filters-config',
  templateUrl: './filters-config.component.html',
  styleUrls: ['./filters-config.component.scss']
})
export class FiltersConfigComponent implements OnInit, OnDestroy {

  @Input() currentSearch;


  default_filters_names = [
    "channel_list", "region_list", "network_list", "pdv_list", "hiear_list", "user_list"
  ];

  filters_convert = [
    {
      default_key: "",
      convert_to: "Selecionar",
      filter_type: ""
    },
    {
      default_key: "channel_list",
      convert_to: "Canais",
      filter_type: "channels"
    },
    {
      default_key: "region_list",
      convert_to: "Regionais",
      filter_type: "regions"
    },
    {
      default_key: "network_list",
      convert_to: "Redes",
      filter_type: "networks"
    },
    {
      default_key: "pdv_list",
      convert_to: "Ponto de vendas",
      filter_type: "pdvs"
    },
    {
      default_key: "hiear_list",
      convert_to: "Hierarquia",
      filter_type: "hiears"
    },
    {
      default_key: "user_list",
      convert_to: "Colaborador",
      filter_type: "users"
    }
  ];

  default_filters_data;

  formated_filters: FilterFormatedModel[] = [];

  //filters_not_availables = [];


  constructor(
    private searchManagementService: SearchManagmentService
  ) { }

  updateListener = () => { }

  ngOnInit(): void {

    this.searchManagementService.management_read_filter_spot_search().subscribe((response) => {

      this.default_filters_data = response;


      this.setAllFilters(this.default_filters_data, true);

    })


  }

  setAllFilters(filters_data, first_seted) {

    this.formated_filters = [];

    let default_filters_data = JSON.parse(JSON.stringify(filters_data));

    for (let filter of this.currentSearch.filters) {

      try {
        this.filters_convert.find(element => element.default_key == filter.name).convert_to

      } catch (err) {
        console.log(filter.name);
        continue;
      }

      let filter_type = this.filters_convert.find(element => element.default_key == filter.name).filter_type;

      let c_filter_formated: FilterFormatedModel = {
        available_filters: [],
        current_selected: filter.name,
        filter_type: filter_type,
        current_selected_title: this.filters_convert.find(element => element.default_key == filter.name).convert_to,
        filter_list: filter.filter_list,
        data: default_filters_data[filter_type],
        onItemUpdate: () => { }
      }

      for (let c_default of this.default_filters_names) {
        c_filter_formated.available_filters.push({
          name: c_default,
          title: this.filters_convert.find(element => element.default_key == c_default).convert_to
        });
      }

      this.formated_filters.push(c_filter_formated);

    }


    if (first_seted) {

      let formatFilterData = new FormatFilterData(this.formated_filters, this.default_filters_data);
      let default_filters_data = formatFilterData.formatData();

      this.setAllFilters(default_filters_data, false);
      return;

    }
    this.formatFilters();

    this.updateListener();

  }


  setValueFrom(value, element_id) {
    (<HTMLSelectElement>document.getElementById(element_id)).value = value;
  }

  formatFilters() {

    for (let c_formated of this.formated_filters) {

      this.removeMyFilterNameAnotherFilters(c_formated);

      for (let position in c_formated.filter_list) {

        let c_filter = c_formated.filter_list[position];

        let data_by_item = c_formated.data.find(element => element.id == c_filter)

        if (!data_by_item) {
          continue;
        }

        if (data_by_item.name) {

          c_formated.filter_list[position] = data_by_item.name;

        }
      }


    }

    let all_filters_added = this.currentSearch.filters.length >= this.default_filters_names.length;// || this.filters_not_availables.length >= this.default_filters_names.length;

    (<HTMLButtonElement>document.getElementById('btnAddFilter')).disabled = all_filters_added;

  }

  removeMyFilterNameAnotherFilters(formated: FilterFormatedModel) {

    for (let c_formated of this.formated_filters) {

      if (c_formated == formated) {
        continue;
      }

      let find = c_formated.available_filters.find(element => element.name == formated.current_selected);

      if (find) {
        let index = c_formated.available_filters.indexOf(find);
        c_formated.available_filters.splice(index, 1);

      }


    }
  }

  showDataByFilter(position) {

    let c_filter = this.formated_filters[position];

    let filter_type = c_filter.filter_type;

    let filter_list = c_filter.filter_list;

    let new_filter_list = [];

    for (let filter of c_filter.filter_list) {
      new_filter_list.push({
        value: filter
      })
    }

    filter_list = new_filter_list;

  }

  setCurrentFilter(name, position) {

    let c_filter = this.currentSearch.filters[position];

    c_filter.name = name;

    c_filter.filter_list = [];

    let formatFilterData = new FormatFilterData(this.formated_filters, this.default_filters_data);
    let default_filters_data = formatFilterData.formatData();

    this.setAllFilters(default_filters_data, false);

  }

  addNewFilter() {

    this.currentSearch.filters.push(<FilterModel>{
      filter_list: [],
      name: ""
    })

    let formatFilterData = new FormatFilterData(this.formated_filters, this.default_filters_data);
    let default_filters_data = formatFilterData.formatData();

    this.setAllFilters(default_filters_data, false);
  }


  deleteFilter(position) {
    this.currentSearch.filters.splice(position, 1);

    let formatFilterData = new FormatFilterData(this.formated_filters, this.default_filters_data);
    let default_filters_data = formatFilterData.formatData();

    this.setAllFilters(default_filters_data, false);
  }

  deleteInvalidFiltersAndConvert(convert_by_contain_id) {

    let new_filters = [];

    for (let c_formated of this.formated_filters) {

      let filter_list = JSON.parse(JSON.stringify(c_formated.filter_list));

      for (let position in c_formated.filter_list) {

        let c_filter = c_formated.filter_list[position];

        let data_by_item = c_formated.data.find(element => element.name == c_filter)

        if (!data_by_item) {
          delete filter_list[position];
          continue;
        }

        if (convert_by_contain_id) {
          if (data_by_item.id) {

            filter_list[position] = data_by_item.id;

          }
        }

      }

      let new_filter_list = [];

      for (let key in filter_list) {
        if (filter_list[key] && filter_list[key] != null) {
          new_filter_list.push(filter_list[key])
        }
      }

      new_filters.push({
        name: c_formated.current_selected,
        filter_list: new_filter_list
      })


    }

    this.currentSearch.filters = new_filters;
  }

  onFilterChangedListener() {

    this.deleteInvalidFiltersAndConvert(false);

    let formatFilterData = new FormatFilterData(this.formated_filters, this.default_filters_data);
    let default_filters_data = formatFilterData.formatData();

    this.setAllFilters(default_filters_data, false)
  }

  checkValids() {
    let errors = 0;

    for (let filter of this.currentSearch.filters) {
      if (filter.name == '') {
        errors++;
      }
    }

    return errors;
  }

  ngOnDestroy(): void {
    this.deleteInvalidFiltersAndConvert(true);
  }

}
