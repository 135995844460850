<div class="container-fluid">
    <div class="row">
        <filter-select class="col-3" name="filials" title="Filial" [loading]="filterLoading"></filter-select>
        <filter-select #directorFilterSelect class="col-3" name="directors" title="Diretor" [loading]="filterLoading"></filter-select>
        <filter-select #managerFilterSelect class="col-3" name="managers" title="Gerente" [loading]="filterLoading"></filter-select>
        <filter-select #coordinatorFilterSelect class="col-3" name="coordinators" title="Coordenador" [loading]="filterLoading"></filter-select>
        <filter-select #supervisorFilterSelect class="col-3" name="supervisors" title="Supervisor" [loading]="filterLoading"></filter-select>
        <filter-select #promoterFilterSelect class="col-3" name="promoters" title="Promotor" [loading]="filterLoading"></filter-select>
    </div>
    <div class="row">
        <filter-time></filter-time>
    </div>
</div>