import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ComponentView } from '../ComponentView';

@Component({
  selector: 'app-numeric-conditional',
  templateUrl: './numeric-conditional.component.html',
  styleUrls: ['./numeric-conditional.component.scss']
})
export class NumericConditionalComponent extends ComponentView implements OnInit {

  constructor() {
    super()
  }

  parent_position = 0;
  toggleAllComponentsId = [];

  ngOnInit(): void {


    if (this.components == undefined) {
      this.components = []
    }

    for (let c_item of this.components) {
      c_item.parent_id = this.parent.component_id;
    }

    //console.log(this.components);

    this.parent_position = this.parent.position;

    this.onCreated();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }


  addNewToggle() {
    this.components.push({
      parent_id: this.parent.component_id,
      toggle_type: "active",
      indicator:this.block.indicator_id,
      value: 0,
      type: "==",
      a: 0,
      b: 0,
      points: 0
    })

    if (this.componentUpdateCallback) {
      this.componentUpdateCallback(this.component);
    }

  }

  deleteToggle(position: number) {
    this.components.splice(position, 1);
  }


  onChangeTypeOfConditional(value, position) {
    let component = this.components[position];
    component.type = value;
  }

  setValueA(value, position) {
    let component = this.components[position];
    component.a = Number(value);
  }

  setValueB(value, position) {
    let component = this.components[position];
    component.b = Number(value);
  }

  setConditionalPoints(value, position) {
    let component = this.components[position];
    component.points = Number(value);
  }


}
