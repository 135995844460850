import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  AfterViewChecked,
  Output,
  Renderer2,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() cancelable = true;
  @Input() title = '';
  @Input() widthContent = '';
  @Input() controllCloseModal = true;
  @Output() closeModal = new EventEmitter();
  @ViewChild('contentModal') contentModal; 

  public alignContentModal = false;

  constructor(private renderer: Renderer2, private cdRef:ChangeDetectorRef) {}
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): any {
    this.onCloseModal();
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'modal-open');
    this.renderer.addClass(document.documentElement, 'modal-open');
  }

  ngAfterViewChecked() {
    const modalElement = this.contentModal.nativeElement.getBoundingClientRect().height;
    if(modalElement > window.innerHeight){
      // this.alignContentModal = true;
      this.cdRef.detectChanges();
    }
  }

  closeAllModal(event: any): any {

    if(!this.cancelable){
      return;
    }

    if (event.srcElement.className === 'wiz-modal' || event.srcElement.className === 'wiz-modal noCenter'){
      this.onCloseModal();
    }
  }

  setWidth(): string {
    return this.widthContent ? `width: ${this.widthContent}px;` : ''
  }
  
  onCloseModal(): any {
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.removeClass(document.documentElement, 'modal-open');
    this.closeModal.emit();
  }

}
