<app-modal [cancelable]="true" (closeModal)="setCloseModal()" title="Criar novo componente" widthContent="400">

    <h5 class="card-title">{{componentConfig.title}}</h5>
    <!-- <h6 class="card-subtitle mb-2 text-muted">Preencha as informações do controle deslizante</h6> -->

    <div style="margin-bottom: 32px;">
        <span class="form-label">Pergunta</span>
        <div class="input-group mb-3 mt-3">
            <span class="input-group-text" >?</span>
            <input type="text" class="form-control" placeholder="Digite a pergunta"
                (change)="componentConfig.title = $event.target.value">

        </div>

        <p class="error-label" *ngIf="componentConfig.title == '' && onSubmitCreate">Digite a pergunta</p>
    </div>


    <div class="d-flex">
        <div class="ml-auto">
            <div class="btn btn-ligth mr-3" (click)="setCloseModal()">Cancelar</div>
            <div class="btn btn-primary" (click)="onConfirm()">Aplicar</div>
        </div>
    </div>

</app-modal>