import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { PaginateInterface } from 'src/app/base/paginate.interface';
import { ClickOutsideWindowsEvent } from 'src/app/utils/click-outside-component-event';
import PaginateUtils, { QueryType } from 'src/app/utils/paginate.utils';

interface ListDataType {
  key: string,
  value: string,
  visible: boolean,
  checked?: boolean
}

export interface ListWithSearchPaginatedFilterConfig {
  searchValue: string,
  selectAll: boolean,
  onlySelected: boolean,
  keysSelected: string[],
  targetKey: string,
  exportKey: string,
  paginateConfig: { [key: string]: PaginateInterface<any> }
}

@Component({
  selector: 'app-list-with-search-paginated-filter',
  templateUrl: './list-with-search-paginated-filter.component.html',
  styleUrls: ['./list-with-search-paginated-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListWithSearchPaginatedFilterComponent extends ClickOutsideWindowsEvent implements OnInit {

  @Input() allData: any[] = [];
  @Output() onClick = new EventEmitter();
  @Output() onClickOutSide = new EventEmitter();

  @Input() searchValue: string = "";

  @Input() selectAll = <boolean>false;
  @Input() blockSelectAll = <boolean>false;
  @Input() onlySelected: boolean = false;

  @Output() onDataChange = new EventEmitter<boolean>();
  @Output() selectAllChangeEvent = new EventEmitter<boolean>();
  @Output() onlySelectedChangEvent = new EventEmitter<boolean>();
  @Output() removeFiltersChangeEvent = new EventEmitter<boolean>();
  @Output() searchValueChangeEvent = new EventEmitter<string>();
  @Output() keysSelectedChangeEvent = new EventEmitter<string[]>();
  @Output() configChange = new EventEmitter<{
    selectAll: boolean,
    onlySelected: boolean
  }>();


  @Input() keysSelected: string[] = [];
  @Input() preKeysSelected: string[] = [];
  @Input() targetKey: string = "name";//O nome da key no array que será exibido na lista.
  @Input() exportKey: string = "_id";//O nome da key no array qye será selecionado.

  @Input() paginateConfig: { [key: string]: PaginateInterface<any> } = {
    default: {
      page: 1,
      perPage: 10,
      countPage: this.allData.length,
      sortBy: this.targetKey,
      sort: "desc",
      total: 10,
      items: []
    },
    search: {
      page: 1,
      perPage: 10,
      countPage: this.allData.length,
      sortBy: this.targetKey,
      sort: "desc",
      total: 10,
      items: []
    }
  }


  listData: ListDataType[] = [];
  paginateUtils = <PaginateUtils><unknown>undefined;
  loadingData: boolean = false;


  constructor(
    renderer: Renderer2,
    private cdr: ChangeDetectorRef
    ) {
    super(renderer)


  }

  ngOnInit(): void {

    // deactivate the change detection for this component and its children
    this.cdr.detach();

    this.paginateUtils = new PaginateUtils();

    // console.log("on init keysSelected", this.keysSelected);

    for (let keySelected of this.keysSelected) {
      let data = this.allData.find(element => element[this.exportKey] == keySelected)

      if (!data || !data[this.targetKey]) {
        this.keysSelected.splice(this.keysSelected.indexOf(keySelected), 1)
        continue
      }

      if (typeof data[this.targetKey] == "string") {

        this.preKeysSelected.push(data[this.targetKey]);


      } else if (typeof data[this.targetKey] == "object") {

        if (!data[this.targetKey]) {
          continue;
        }

        let { type, value: dataValue } = data[this.targetKey];

        this.preKeysSelected.push(dataValue);
      }

      // this.preKeysSelected.push(data[this.targetKey])
    }

    // this.preKeysSelected = <string[]>getAttributes(this.keysSelected);

    this.preLoadData();

  }

  setSelectAll(event: Event) {
    let checked = (<HTMLInputElement>event.target).checked;

    this.selectAll = checked ? true : false;

    this.selectAllChangeEvent.emit(checked);

    if (!checked) {

      this.preKeysSelected.splice(0)
    }

    this.preLoadData();

  }

  preLoadData() {
    if (this.searchValue == "") {
      this.loadData(this.paginateConfig.default, { exportKey: this.exportKey, onlySelected: this.onlySelected ? { keysSelected: this.getIdsFromPreKeysSelected() } : undefined })
    } else {
      this.onSearch(this.searchValue);
    }
    this.cdr.detectChanges();

  }

  loadData(paginate: PaginateInterface<any>, extraQuery: QueryType) {

    let response = this.paginateUtils.getData(this.allData, paginate, extraQuery);
    paginate.countPage = response.countPage;

    this.listData = [];

    for (let data of response.items) {
      // console.log(data);

      if (!data[this.targetKey])
        continue

      let cData = data[this.targetKey];

      if (typeof cData == "object") {
        let { type, value } = cData;
        switch (type) {

          case "question":
            data[this.targetKey] = value;
            break;
        }
      }

      if (!this.listData.find(element => element.value == data[this.targetKey])) {

        this.listData.push({
          key: data[this.exportKey],
          value: data[this.targetKey],
          visible: true,
          checked: this.getIdsFromPreKeysSelected().includes(data[this.exportKey]) || this.selectAll
        });
      }


    }

    if (this.selectAll) {

      this.preKeysSelected.splice(0)

      if (this.searchValue != "") {

        this.preKeysSelected.push(...response.items.map((value) => {
          return value[this.targetKey]//value[this.exportKey];
        }))

      } else if (this.keysSelected.length == 0) {

        this.preKeysSelected.push(...this.allData.map((value) => {
          return value[this.targetKey];
        }))

      }

    }

  }

  onSearchEvent(event: Event) {

    let value = (<HTMLInputElement>event.target).value;

    this.searchValue = value;

    this.searchValueChangeEvent.emit(this.searchValue);

    this.onSearch(value);
  }

  onSearch(value: string) {

    if (value != "") {
      this.loadData(this.paginateConfig.search, {
        search: {
          value: this.searchValue,
          searchByKey: "name"
        },
        exportKey: this.exportKey,
        onlySelected: this.onlySelected ? { keysSelected: this.getIdsFromPreKeysSelected() } : undefined
      })
    } else {
      this.loadData(this.paginateConfig.default, { exportKey: this.exportKey, onlySelected: this.onlySelected ? { keysSelected: this.getIdsFromPreKeysSelected() } : undefined });
      this.resetSearchPaginate();
    }

    this.cdr.detectChanges();
  }

  resetSearchPaginate() {
    this.paginateConfig.search = {
      page: 1,
      perPage: 10,
      countPage: 1,
      sortBy: this.targetKey,
      sort: "desc",
      total: 10,
      items: []
    }
  }

  public clickOutSideElement(): void {
    this.onClickOutSide.emit(true);
  }

  onItemSelected(event: Event, item: any) {
    let value = (<HTMLInputElement>event.target).value;
    let checked = (<HTMLInputElement>event.target).checked;

    if (checked) {

      this.preKeysSelected.push(item.value)

    } else {

      let index = this.preKeysSelected.findIndex(element => element == item.value);

      this.preKeysSelected.splice(index, 1)

    }

    this.cdr.detectChanges();
  }

  setOnlySelected(event: Event) {
    let checked = (<HTMLInputElement>event.target).checked;

    this.onlySelected = checked ? true : false;

    this.onlySelectedChangEvent.emit(checked);

    this.preLoadData();


  }

  onScroll(event: Event) {

    let div = (<HTMLDivElement>event.target);

    if (div.offsetHeight + div.scrollTop >= div.scrollHeight && !this.loadingData) {

      // console.log("is end")

      this.loadingData = true;

      this.cdr.detectChanges();

      setTimeout(() => {

        if (this.searchValue == "") {

          if (this.paginateConfig.default.page < this.paginateConfig.default.countPage) {
            this.paginateConfig.default.page += 1
          }

          this.loadData(this.paginateConfig.default, { exportKey: this.exportKey, onlySelected: this.onlySelected ? { keysSelected: this.getIdsFromPreKeysSelected() } : undefined })
        } else {

          if (this.paginateConfig.search.page < this.paginateConfig.search.countPage) {
            this.paginateConfig.search.page += 1
          }

          this.onSearch(this.searchValue);

        }

        this.loadingData = false;

        this.cdr.detectChanges();

      }, 500);
    }

  }

  removeFilters() {
    this.keysSelected = [];
    this.removeFiltersChangeEvent.emit()
  }

  getIdsFromPreKeysSelected(): string[] {
    const keysSelected = [];

    for (let value of this.preKeysSelected) {
      for (let data of this.allData) {

        if (typeof data[this.targetKey] == "string") {
          if (data[this.targetKey] == value)

            keysSelected.push(data[this.exportKey]);

        } else if (typeof data[this.targetKey] == "object") {

          if (!data[this.targetKey]) {
            continue;
          }

          let { type, value: dataValue } = data[this.targetKey];

          if (dataValue && String(dataValue) === String(value)) {

            keysSelected.push(data[this.exportKey]);

          }
        }

      }
    }

    // console.log("preKeysSelected", this.preKeysSelected)
    // console.log("keysSelected", keysSelected)

    return keysSelected;
  }

  applyFilters() {

    // console.log(this.preKeysSelected);

    this.keysSelected = this.getIdsFromPreKeysSelected();


    this.keysSelectedChangeEvent.emit(this.keysSelected);
  }

}
