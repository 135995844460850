<ul class="drop-filters list-group drop-item" id="drop_filters_{{position}}" *ngIf="show_data_filter">
    <ng-container *ngFor="let item of item.data;let position1 = index;">
        <li class="list-group-item drop-item">
            <input (mousedown)="$event.stopPropagation()"  class="form-check-input me-1 drop-item" [attr.checked]="item.checked == true ? true : null" type="checkbox" value=""
                (change)="checkItemDataChecked($event.target.checked,item,position1)" aria-label="..."
                >
            {{item.name}}
        </li>
    </ng-container>

</ul>

<div class="container-filter-list d-flex">
    <div class="mr-auto container-itens">
        <ng-container *ngFor="let item3 of item.filter_list;let position2 = index;">
            <div class="filter-list-item d-flex" *ngIf="item3 && item3.length">
                <span class="material-icons drop-item" (click)="removeFilter(position2)">close</span>
                <h5>{{item3}}</h5>
            </div>
        </ng-container>
    </div>
    <button class="select-button d-flex" (click)="showDataList()" id="btn_data_filter_{{position}}">
        <span class="material-icons m-auto" style="pointer-events: none;">arrow_drop_down</span>
    </button>
</div>