<div class="card list-container {{key}}">
    <div class="card-header p-2 {{key}}">
        <input type="text" class="form-control {{key}}" placeholder="Pesquisar" [value]="searchValue"
            (input)="onSearchEvent($event)">
        <div class="d-flex mt-2" style="align-items: center;">
            <div class="{{key}} me-2" *ngIf="!blockSelectAll">
                <input class="me-2 {{key}}" [attr.checked]="selectAll ? true : null" type="checkbox"
                    id="input_select_all" (change)="setSelectAll($event)" />
                <label class="m-0 input-check {{key}}" for="input_select_all">Selecionar todos</label>
            </div>
            <div class="{{key}}">
                <input class="me-2 {{key}}" [attr.checked]="onlySelected ? true : null" type="checkbox"
                    id="input_only_select" (change)="setOnlySelected($event)" />
                <label class="m-0 input-check {{key}}" for="input_only_select">Apenas selecionados</label>
            </div>
            <div class="d-flex" style="flex:1;justify-content: end;">
                <h3><b>{{listData.length}} / {{allData.length}}</b></h3>
            </div>
        </div>
        
    </div>
    <div class="card-body p-0 list-content {{key}}" (scroll)="onScroll($event)">


        <ng-container *ngFor="let item of listData;let position = index;">

            <div class="card rounded-0 {{key}}" *ngIf="item.visible">

                <div class="card-body p-2 item-content {{key}}">
                    <input (change)="onItemSelected($event,item)" [attr.checked]="item.checked ? true : null"
                        [id]="'input_select_'+position" class="me-2 input-check {{key}}" type="checkbox"
                        [value]="item.key" />
                    <label class="m-0 input-check {{key}}" for="input_select_{{position}}">{{item.value}}</label>
                </div>

            </div>

        </ng-container>

        <div class="loading-data" *ngIf="loadingData">
            <div class="spinner-border text-danger" role="status">
                <span class="sr-only"></span>
            </div>
        </div>

    </div>

    <div style="width: 100%;display: flex;justify-content: end;padding: 8px;">
        <button class="btn btn-danger me-3" (click)="removeFilters()">
            Limpar filtros
        </button>
        <button class="btn btn-primary" (click)="applyFilters()">
            Aplicar Filtros
        </button>
    </div>
</div>