import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingDefaultTabletComponent } from './loading-default-table.component';


@NgModule({
    imports: [
        CommonModule
    ],
    exports: [LoadingDefaultTabletComponent],
    declarations: [LoadingDefaultTabletComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoadingDefaultTableModule { }
