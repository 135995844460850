<div class="card w-100">

    <div class="card-body">
        <h3 class="card-title">
            {{title}}
        </h3>

        <div class="mb-3">
            <label class="form-label">Tipo de frequencia</label>
            <div>
                <select name="" id="" value="{{publishSearchSetup.frequency_type}}"
                    (change)="updateFrequency($event.target.value)">
                    <option value="indeterminate">Nunca expira</option>
                    <option value="date_range">Periodo de datas</option>
                    <option value="custom">Frequencia personalizada</option>
                </select>
            </div>
        </div>

        <div class="row mb-3" *ngIf="publishSearchSetup">

            <ng-container *ngIf="publishSearchSetup.frequency_group == 'dates'">

                <div class="col">
                    <label class="form-label">Data de publicação</label>
                    <input (mousedown)="$event.stopPropagation()"  type="date" class="form-control" placeholder="Digite o nome da pesquisa"
                        [value]="getFormattedDateToInputDateFormat(publishSearchSetup.init_date)" (change)="setInitDate($event.target.value)">
                </div>
                <div class="col">
                    <label class="form-label">Data de expiração</label>
                    <input (mousedown)="$event.stopPropagation()" [attr.disabled]="publishSearchSetup.never_expires ? true : null" type="date" class="form-control mb-2" placeholder="Digite o nome da pesquisa"
                        id="endDateExpires" [value]="getFormattedDateToInputDateFormat(publishSearchSetup.end_date)"
                        (change)="setEndDate($event.target.value)">
                    <div>
                        <input (mousedown)="$event.stopPropagation()"  type="checkbox" class="mr-2" (change)="setExpires($event.target)" id="checkboxExpires" [attr.checked]="publishSearchSetup.never_expires ? true : null">
                        <label for="checkboxExpires">Nunca expira</label>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="publishSearchSetup.frequency_group == 'custom'">
                <app-custom-frequency [publishSearchSetup]="publishSearchSetup"
                    (onPublishListener)="onPublishListener($event)">

                </app-custom-frequency>
            </ng-container>
        </div>
    </div>
</div>