export const Authorization = [
    { card: "produtos", authorization: false, hiears: ["Admin", "Líder de projeto"] }, //0,
    { card: "pessoas", authorization: false, hiears: ["Admin", "Líder de projeto", "Coordenador", "Coordenador de trade", "Assistente de trade"] }, //1,
    { card: "pdv", authorization: false, hiears: ["Admin", "Líder de projeto", "Coordenador", "Coordenador de trade", "Assistente de trade"] }, //2,
    { card: "roteiro", authorization: false, hiears: ["Admin", "Líder de projeto", "Coordenador", "Coordenador de trade", "Assistente de trade", "Supervisor de trade"] }, //3,
    { card: "coleta de preços", authorization: false, hiears: ["Admin", "Líder de projeto", "Coordenador", "Coordenador de trade", "Assistente de trade", "Supervisor de trade"] }, //4,
    { card: "lista de materiais de pontos de venda", authorization: false, hiears: [] }, //5,
    { card: "pesquisas", authorization: false, hiears: [] }, //6,
    { card: "importar", authorization: false, hiears: [] }, //7,
    { card: "conteudo", authorization: false, hiears: [] }, //8,
    { card: "conteudo", authorization: false, hiears: [] }, //9,
    { card: "pesquisas", authorization: false, hiears: ["Admin", "Coordenador", "Líder de projeto", "Coordenador de trade", "Assistente de trade"] }, //10,
    { card: 'acoes', authorization: false, hiears: ['Admin'] },//11
    { card: 'edição de pessoas', authorization: false, hiears: ['Admin'] },//12
    { card: 'pacote de arquivos', authorization: false, hiears: ['Admin'] }, // 13
    { card: 'configuração do aplicativo', authorization: false, hiears: ['Admin'] } // 14
]

export const AuthorizationHome = [
    { authorized: false, whiteList: ["$all"], blackList: ["Gerente"] },
    { authorized: false, whiteList: ["Gerente", "$all"], blackList: [] }
]