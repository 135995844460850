<div style="display: flex;">
    <button *ngIf="features.buttons.edit" class="btn btn-primary mr-3" (click)="onClickEvent('edit')">
        <span class="material-icons">
            mode_edit
        </span>
    </button>
    <button *ngIf="features.buttons.copy" class="btn btn-primary  mr-3" (click)="onClickEvent('copy')">
        <span class="material-icons">
            content_copy
        </span>
    </button>
    <button *ngIf="features.buttons.delete" class="btn btn-danger  mr-3" (click)="onClickEvent('delete')">
        <span class="material-icons">
            delete
        </span>
    </button>
</div>