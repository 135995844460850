<app-modal (closeModal)="setCloseModal()" widthContent="450">
    <div class="contentSuccess">
        <div class="contentSuccess">
            <img src="../../../../../../assets//icons/iconDelete.svg" style="margin-bottom: 20px;"
                alt="Ícone de lixeira" />
            <h1>Tem certeza que deseja excluir</h1>
            <h3>{{item.name}}</h3>
            <div class="ButtonModal">
                <button (click)="confirmDelete()">Excluir</button>
                <button (click)="setCloseModal()">Não</button>
            </div>
        </div>

        
    </div>
</app-modal>