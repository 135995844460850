import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MapStyle } from 'src/app/utils/MapStyle';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit {

  // lat = 28.704060;
  // long = 77.102493;
  googleMapType = 'roadmap';
  @Input() widthContent = '';
  @Input() lat: any;
  @Input() lng: any;
  @Input() markers: {
    position: {
      lat: 0.0,
      lng: 0.0
    },
    icon: ""
  }[];

  @Output() onMapClickListener = new EventEmitter();

  //Array de estilo do mapa
  styles = MapStyle;

  constructor() { }

  ngOnInit(): void {
  }

  setWitdh(): string {
    return this.widthContent ? `width: ${this.widthContent}px; min-width: ${this.widthContent}px;` : ''
  }

  mapClick(event) {
    let { coords } = event;

    //console.log(coords);
  }

  map;
  mapClickListener;

  public mapReadyHandler(map: google.maps.Map): void {
    this.map = map;
    this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => {
      this.onMapClickListener.emit(e);
    });
  }

  public ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }

}
