import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import PptxGenJS from 'pptxgenjs';
import * as JSZip from 'jszip';
import StringManager from 'src/app/utils/StringManager';
import ExportPowerPoint from '../paginated-table/ExportPowerPoint';
import { Exporters } from '../paginated-table/Exporters';
import SortList from 'src/app/utils/SortList';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-export-files',
  templateUrl: './modal-export-files.component.html',
  styleUrls: ['./modal-export-files.component.scss']
})


export class ModalExportFilesComponent implements OnInit {

  @Input() exportConfig;
  @Input() exportData;
  @Output() onCloseModal = new EventEmitter();

  exportSize = 0;
  exportProgress = 0;
  exportNames: string[] = [];
  exportFiles: {
    unique_key: any
    file_name: string,
    status: boolean
  }[] = [];

  abortExportFiles = false;

  constructor(
    private toastr: ToastrService
  ) {
  }

  setCloseModalExportFiles() {
    this.onCloseModal.emit()
  }

  onAbortExportFiles() {
    this.abortExportFiles = true;
    this.abortAllExporters();
    this.setCloseModalExportFiles();
  }

  //salvar o arquivo em uma TAG A e baixalo.
  saveBlob(blob, fileName) {
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent('click'));
  }

  exporters: Exporters[] = [];
  registerExporters(manager: Exporters) {
    this.exporters.push(manager);
  }

  abortAllExporters() {
    for (let key in this.exporters) {
      let c_exporter = this.exporters[key];

      c_exporter.abort();
    }

    this.exporters = [];
  }

  ngOnInit(): void {
    SortList.decreasing(this.exportData.data, "key_2");

    switch (this.exportConfig.exportTo) {
      case 'pptx':

        let exportPowerPoint = new ExportPowerPoint({
          exportData: this.exportData,
          exportFiles: this.exportFiles,
          exportNames: this.exportNames
        });

        exportPowerPoint
          .setOnProgressCallback((progress) => {
            this.exportSize = progress.exportSize;
            this.exportProgress = progress.exportProgress;

            if (progress.exportSize == 0) {
              this.toastr.warning("Nenhuma imagem encontrada");
              this.setCloseModalExportFiles();
            }
          })
          .setOnFinishCallback((data, file_name) => {
            this.saveBlob(data, !file_name.includes('.zip') ? file_name + ".pptx" : file_name);
            this.setCloseModalExportFiles();
          })
          .start();


        this.registerExporters(exportPowerPoint);


        break
    }

  }

  checkAllStatusOkay(fileName: string) {
    let counts = 0;
    let okay = 0;

    counts = this.exportFiles.filter(element => element.file_name == fileName).length;

    okay = this.exportFiles.reduce((a, b) => { return a += b.status ? 1 : 0 }, 0);

    return okay >= counts;
  }

}
