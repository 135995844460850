import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { AuthorizationToolsGuard } from "./core/guards/authorization-tools.guard";
import { WorkRoutineModule } from "./pages/work-routine/work-routine.module";

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'resetPassword',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'splash',
    loadChildren: () =>
      import('./pages/splash/splash.module').then((m) => m.SplashModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ferramentas',
    loadChildren: () =>
      import('./pages/ferramentas/ferramentas.module').then((m) => m.FerramentasModule),
    canActivate: [AuthGuard, AuthorizationToolsGuard]
  },
  {
    path: 'produtividade',
    loadChildren: () =>
      import('./pages/produtividade/produtividade.module').then((m) => m.ProdutividadeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'visitas',
    loadChildren: () =>
      import('./pages/visitas/visitas.module').then((m) => m.VisitasModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rotina-de-trabalho',
    loadChildren: () => WorkRoutineModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'preco',
    loadChildren: () =>
      import('./pages/preco/preco.module').then((m) => m.PrecoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'lojaperfeita',
    loadChildren: () =>
      import('./pages/perfect-store/perfect-store.module').then((m) => m.PerfectStoreModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ruptura',
    loadChildren: () =>
      import('./pages/ruptura/ruptura.module').then((m) => m.RupturaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'shelf',
    loadChildren: () =>
      import('./pages/shelf/shelf.module').then((m) => m.ShelfModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'concorrencia',
    loadChildren: () =>
      import('./pages/concorrencia/concorrencia.module').then((m) => m.ConcorrenciaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ocasioes-de-consumo',
    loadChildren: () =>
      import('./pages/ocasioes-consumo/ocasioes-consumo.module').then((m) => m.OcasioesConsumoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'extra-points',
    loadChildren: () =>
      import('./pages/extra-point/extra-point.module').then((m) => m.ExtraPointModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'merchandising',
    loadChildren: () =>
      import('./pages/merchandising/merchandising.module').then((m) => m.MerchandisingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'exposition-share',
    loadChildren: () =>
      import('./pages/exposition-share/exposition-share.module').then((m) => m.ExpositionShareModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cooling',
    loadChildren: () =>
      import('./pages/cooling/cooling.module').then((m) => m.CoolingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'bonus',
    loadChildren: () =>
      import('./pages/bonus/bonus.module').then((m) => m.BonusModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'estoque_virtual',
    loadChildren: () =>
      import('./pages/virtual_stock/virtual_stock.module').then((m) => m.VirtualStockModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'coaching',
    loadChildren: () =>
      import('./pages/coaching/coaching.module').then((m) => m.CoachingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pesquisa-spot',
    loadChildren: () =>
      import('./pages/spot-search/spot-search.module').then((m) => m.SpotSearchModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cartao-ponto',
    loadChildren: () =>
      import('./pages/timecard/timecard.module').then((m) => m.CartaoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rota',
    loadChildren: () => import('./pages/trace/trace.module').then((m) => m.TraceModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }