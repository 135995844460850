import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkRoutinePage } from './work-routine.page';
import { DefaultComponentsModule } from 'src/app/components/components.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common'
import { RouterModule, Routes } from '@angular/router';
import { FilterModule } from '../../components/filter/filter.module'
registerLocaleData(localePt, 'pt');

const routes: Routes = [
  {path: '', component: WorkRoutinePage}
];

@NgModule({
  declarations: [WorkRoutinePage],
  imports: [
    CommonModule,
    DefaultComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    RouterModule.forChild(routes),
    FilterModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    })
  ],
  exports: [RouterModule],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
  ]
})
export class WorkRoutineModule { }
