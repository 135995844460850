import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BlockModel } from "src/app/pages/ferramentas/search-manager/components/search-editor/components/search-blocks/BlockModel";
import { Md5 } from "ts-md5";
import { ComponentIdModel } from "./ComponentIdModel";
@Component({
    template: ''
})
export abstract class ComponentView {

    @Input() block: BlockModel;
    @Input() allComponentsId: ComponentIdModel[];
    @Input() position;
    @Input() parent;
    @Input() emmitUpdates: boolean = false;
    @Input() components: any[];
    @Input() component;
    @Output() onComponentsUpdate = new EventEmitter();
    @Input() componentUpdateCallback: (component) => {}

    inputFocus = false;

    inputs: HTMLInputElement[] = [];

    constructor() {
    }

    ngAfterViewInit() {


    }

    onCreated(): void {

        if (this.component && !this.component.component_id) {

            //let id = new Md5().appendStr(`${new Date().getTime()}`).end();

            this.component.component_id = `question_${this.position}`;
            //this.component.component_id = id;

        }

        if (this.component && this.component.component_id) {

            if (this.allComponentsId) {
                if (!this.allComponentsId.find(element => element.id == this.component.component_id)) {
                    this.allComponentsId.push({
                        id: this.component.component_id,
                        title: this.component.title
                    });
                }
            }

        }

        if (this.component) {

            this.component.active = true;

        }



    }


    setComponentTitle(title) {
        this.component.title = title;
    }

    setOkayPoints(points) {
        this.component.okay_points = points;
    }

    setNotOkayPoints(points) {
        this.component.not_okay_points = points;
    }

    setComponentPoints(points) {
        this.component.points = points;
    }

    onUpdate() {

        if (this.emmitUpdates) {
            this.onComponentsUpdate.emit(this.component);
        }

        if (this.componentUpdateCallback) {
            this.componentUpdateCallback({
                action: 'update',
                component: this.component,
                position: this.position
            })
        }
    }

    deleteCurrentComponent() {
        this.componentUpdateCallback({
            action: 'delete',
            component: this.component,
            position: this.position
        })
    }


    toggleView(parent: HTMLElement) {

        parent.classList.contains('body-hide') ? parent.classList.remove('body-hide') : parent.classList.add('body-hide')
    }

}