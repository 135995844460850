import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/users/users.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  public showMenu: boolean = false;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.userService.isLogged.subscribe(status => {
      this.showMenu = window.location.href.includes('visitas/exportar') ? false : status;
    });
  }

}
