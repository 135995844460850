<button class="btn"
    [ngClass]="{'btn-success': status == 1, 'btn-danger': status == 2, 'btn-primary': status == 0, 'disabled': loading}"
    (click)="onClick.emit(this)">
    <div class="d-flex">
        <span class="material-icons mr-2" *ngIf="!loading">
            {{ status > 0 ? status == 1 ? 'check_box' : 'check_box_outline_blank' : 'watch_later'}}
        </span>
        <div class="spinner-border spinner-border-sm mr-2 my-auto" role="status" *ngIf="loading">
            <span class="visually-hidden">Loading...</span>
        </div>
        <span>
            {{ loading ? 'Carregando' : (labels[status]) }}
        </span>
    </div>
</button>