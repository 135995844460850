import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SearchManagmentService } from 'src/app/core/services/search-managment-service/search-managment.service';
import { InterfaceModel } from './InterfaceModel';

@Component({
  selector: 'app-modal-new-search',
  templateUrl: './modal-new-search.component.html',
  styleUrls: ['./modal-new-search.component.scss']
})
export class ModalNewSearchComponent implements OnInit {

  interfaceConfig: InterfaceModel[] = []

  search_config = {
    name: "",
    description: ""
  }

  onSubmitCreate = false;

  @Output() onCloseModal = new EventEmitter();
  @Output() onCreateEventListener = new EventEmitter();

  constructor(
  ) { }

  ngOnInit(): void {


  }

  createNewSearch(button) {

    this.onSubmitCreate = true;

    if(!this.checkValid()){
      return;
    }

    button.disabled = true;

    this.onCreateEventListener.emit(this.search_config)
  }

  checkValid(){
    let errors = 0;

    if(this.search_config.name == ''){
      errors++;
    }

    if(this.search_config.description == ''){
      errors++;
    }

    return errors == 0;
  }

  setCloseModal() {
    this.onCloseModal.emit();
  }
}
