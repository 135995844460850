<div class="tableData">

    <div class="loading text-center" *ngIf="loadingData">
        <h2>Carregando informações, isso pode demorar varios segundos.</h2>
        <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <ng-container>

        <div class="field" *ngIf="!loadingData">
            <input type="text" placeholder="Pesquisar" class="campos" (input)="search($event.target.value)"
                [ngClass]="{cssClass: expression}" />
            <img src="../../../assets/icons/search.svg" alt="Ícone de lupa">
        </div>

        <div class="title-empty" *ngIf="default_data.data.length==0">
            <h3 *ngIf="!loadingData && !errorData">Registros não encontrados</h3>
        </div>

        <div class="table" *ngIf="!loadingData && default_data.data.length > 0" (window:resize)="resizeTr()">
            <div class="table1" [ngClass]="{duoTable: custom_data.data.length > 0}">
                <table>
                    <thead id="header_1_{{uniqueKey}}">
                        <tr>

                            <th *ngIf="modal_view"></th>

                            <th
                                *ngIf="!custom_data.data.length && features &&  features.show && features.align == 'left'">
                            </th>

                            <th *ngFor="let c_item of default_data.headers; let i = index;"
                                (click)="sortData('key_'+i)">
                                {{c_item}}
                                <img class="arrow" src="../../../assets/icons/arrow-table.svg"
                                    alt="ícone de seta para ordenar tabela"
                                    [class.sortIcon]="item['key_'+i] && !item['key_'+i].sort" />

                            </th>

                            <th
                                *ngIf="!custom_data.data.length && features && features.show && features.align == 'right'">
                            </th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let item of dump_data.default | paginate: { itemsPerPage: 5, currentPage: p }; let i = index"
                            [attr.id]="'tr1_'+i+'_'+uniqueKey">

                            <td *ngIf="features && features.show && features.align == 'left'">
                                <app-features (onFeatureClickEvent)="onFeatureClickEvent($event)" [position]="i"
                                    [features]="features" [item]="item" [default]="true">

                                </app-features>
                            </td>

                            <td *ngFor="let item2 of item">
                                {{item2.value}}

                            </td>

                            <td
                                *ngIf="!custom_data.data.length && features &&  features.show && features.align == 'right'">
                                <app-features (onFeatureClickEvent)="onFeatureClickEvent($event)" [position]="i"
                                    [features]="features" [item]="item" [default]="true">

                                </app-features>
                            </td>

                        </tr>



                    </tbody>
                </table>
            </div>
            <div class="table2" *ngIf="custom_data.data.length > 0">
                <table>
                    <thead id="header_2_{{uniqueKey}}">

                        <tr>
                            <th *ngFor="let item of custom_data.headers; let i = index;">
                                {{item}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let item of dump_data.custom | paginate: { itemsPerPage: 5, currentPage: p }; let i = index"
                            [attr.id]="'tr2_'+i+'_'+uniqueKey">
                            {{resizeTr(i)}}

                            <ng-container *ngFor="let item2 of item;">

                                <td *ngIf="item2.type == 'text'">
                                    {{item2.value}}

                                </td>

                                <td *ngIf="item2.type == 'items'"
                                    >
                                    
                                    <button class="btn btn-primary" (click)="setTableConfigModal(item2.table_config)">
                                        <span class="material-icons mr-3" style="vertical-align: bottom;">visibility</span>
                                        {{item2.value}}
                                    </button>
                                </td>

                                <td class="photo-number" *ngIf="item2.type == 'images' "
                                    (click)="setImagesModal(item2.images)" align="center">
                                    <span class="material-icons">
                                        photo_camera
                                    </span>
                                    <p>{{item2.images ? item2.images.length : 0}}</p>
                                </td>

                                

                            </ng-container>

                            <td *ngIf="features && features.show && features.align == 'right'">
                                <app-features (onFeatureClickEvent)="onFeatureClickEvent($event)" [position]="i"
                                    [features]="features" [item]="item" [default]="true">

                                </app-features>
                            </td>
                            
                        </tr>



                    </tbody>
                </table>
            </div>
        </div>

        <div class="finishTable" *ngIf="!loadingData && dump_data.custom.length">
            <h3>Total: {{dump_data.custom.length}}</h3>
            <div class="paginator" id="paginator">
                <pagination-controls (pageChange)="p = $event" nextLabel="Próximo" previousLabel="Anterior">
                </pagination-controls>
            </div>
            <div>
                <button (click)="exportToCustomFormat('pptx')" class="btn-export mr-3" *ngIf="exportConfig.ppt">
                    Exportar Power Point
                </button>
                <button (click)="exportTable()" [disabled]="exportExcel" *ngIf="exportConfig.excel">
                    <img src="../../../../../../assets/icons/downloadIcon.svg" alt="Ícone de Download"
                        *ngIf="!exportExcel" />
                    <div class="spinner-border spinner-border-sm mr-2 my-auto" role="status" *ngIf="exportExcel">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    {{ exportExcel ? 'Exportando' : 'Exportar Excel' }}
                </button>
            </div>
        </div>
    </ng-container>


</div>

<app-modal id="modal_table_images" *ngIf="modals.table_images" (closeModal)="closeModal('table_images')"
    title="Fotos da pesquisa" widthContent="700">
    <div class="modal-content" style="height: 720px;">

        <carousel [attr.id]="carrousel_table_images" *ngIf="table_images_urls.length > 0" style="height: 720px;">
            <slide *ngFor="let url of table_images_urls; let position = index;" style="height: 720px;">
                <div class="item" style="height: 720px;overflow: auto;">
                    <h3
                        style="position: absolute;top: 0;left: 16px;padding: 16px;color: white;border: 3px solid;border-radius: 8px; background-color: rgba(0, 0, 0, 0.50);">
                        Imagem {{position + 1}}
                    </h3>
                    <img src={{url}} alt="first slide" style="width: 100%;">
                </div>
            </slide>
        </carousel>

    </div>
</app-modal>

<app-modal-show-collected-items *ngIf="modals.show_modal_collects" [table_config]="table_config" (closeModal)="closeModal('show_modal_collects')">

</app-modal-show-collected-items>

<app-modal-export-files *ngIf="modalExportFiles" [exportConfig]="exportConfig" [exportData]="exportData"
    (onCloseModal)="setCloseModalExportFiles()">

</app-modal-export-files>