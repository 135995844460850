import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PaginatedTableComponent } from '../paginated-table/paginated-table.component';

@Component({
  selector: 'app-modal-show-collected-items',
  templateUrl: './modal-show-collected-items.component.html',
  styleUrls: ['./modal-show-collected-items.component.scss']
})
export class ModalShowCollectedItemsComponent implements OnInit {

  @ViewChild(PaginatedTableComponent) paginatedTable: PaginatedTableComponent;

  @Output() closeModal = new EventEmitter();
  
  @Input() table_config;
  @Input() exportEvent;

  loadingTable;
  exportConfig = {
    excel: true
  };

  constructor() { }

  ngOnInit(): void {

    console.log(this.table_config);

  }

  setCloseModal() {
    console.log("on close modal collect");
    this.closeModal.emit('close');
  }

  onExportDataCreated(event) {

  }

  exportTable(event) {
    this.exportEvent(event)
  }

}
