import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Util } from 'src/app/shared/util';
import { UserService } from '../services/users/users.service';
import { EnvironmentService } from '../services/environment/environment.service';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private userService: UserService,
    private environment: EnvironmentService
  ) {}
  //toda minha requisiscao passa pelo intercept
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if(!request.headers.keys().length) {
      if(!request.headers.has('Content-Type')) {
        request = request.clone({headers: request.headers.set('Content-Type', 'application/json') });
      }
      
      if(!request.headers.has('x-access-token')) {
        request = request.clone({headers: request.headers.set('x-access-token', this.environment.accessToken) });
      }
    }
    request = request.clone({
      withCredentials: true,
    });
    //quando  da bom
    return next.handle(request).pipe( tap((response) => {
      // debugger
    },
      //quando da erro
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 ) {
          
          Util.logout();
          this.router.navigate(['/login']);
          this.userService.setIsLogged(false);
          return;
        }
      }
    }));
  }
}
