<app-modal [cancelable]="true" (closeModal)="setCloseModal()" title="Criar nova pesquisa" widthContent="400">

    <div style="margin-bottom: 32px;">
        <span class="form-label">Nome</span>
        <input type="text" class="form-control mb-3 mt-3" placeholder="Digite o nome da pesquisa"
            (change)="search_config.name = $event.target.value">
        <p class="error-label" *ngIf="search_config.name == '' && onSubmitCreate">Digite o nome da pesquisa</p>
    </div>

    <div style="margin-bottom: 32px;">
        <span class="form-label">Descrição</span>
        <input type="text" class="form-control mb-3 mt-3" placeholder="Digite o nome da pesquisa"
            (change)="search_config.description = $event.target.value">
        <p class="error-label" *ngIf="search_config.description == '' && onSubmitCreate">Digite a descrição da pesquisa
        </p>
    </div>

    <div class="d-flex">
        <div class="ml-auto">
            <button class="btn btn-ligth mr-3" (click)="setCloseModal()">Cancelar</button>
            <button class="btn btn-primary" (click)="createNewSearch($event.target)">Criar</button>
        </div>
    </div>

</app-modal>