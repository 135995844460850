import { PaginateInterface } from "src/app/base/paginate.interface";

export interface QueryType {
    search?: {
        value: string,
        searchByKey: string
    },
    exportKey: string,
    onlySelected?: {
        keysSelected: any[],
    }
}

class PaginateUtils {


    getData = (data: any[], paginate: PaginateInterface<any>, extraQuery: QueryType): PaginateInterface<any> => {

        let response = []

        let countPage = 0;

        let { search, exportKey, onlySelected } = extraQuery;

        let newData = <any[]>JSON.parse(JSON.stringify(data));

        if (onlySelected) {

            let { keysSelected } = onlySelected;

            newData = newData.filter((value) => {
                return keysSelected?.includes(value[exportKey]);
            })
        }

        if (search) {

            let { value, searchByKey } = search;

            try {

                response = newData.filter(element => {
                    return element[searchByKey] && value && (<string>element[searchByKey]).toLowerCase().includes(value.toLowerCase());
                })

                countPage = response.length;

            } catch (err) { }

            response = response.slice(
                0,
                paginate.page * paginate.perPage

            )
        } else {

            countPage = newData.length;

            response = newData.slice(
                0,
                (paginate.page * paginate.perPage) + 1

            )
        }

        response = response.map((value) => { return { ...value, ...{ _id: value[exportKey] ?? value._id["$oid"] ?? "" } } });

        return JSON.parse(JSON.stringify({ ...paginate, ...{ countPage, items: response } }));

    }

}

export default PaginateUtils;