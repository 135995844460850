<div class="card w-100">

    <div class="card-body">
        <h5 class="card-title">Critérios de Publicação</h5>

        <div class="d-flex">
            <div>
                <ng-container *ngFor="let item of formated_filters; let position = index">
                    <div class="row mb-3">
                        <div class="col-auto">
                            <!-- <span>Semana</span> -->
                            <div>
                                <select name="" id="select_filter_{{position}}" value="{{item.current_selected}}"
                                    (change)="setCurrentFilter($event.target.value,position)">
                                    <ng-container *ngFor="let item2 of item.available_filters">
                                        {{setValueFrom(item.current_selected,"select_filter_"+position)}}
                                        <option value="{{item2.name}}">{{item2.title}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-auto">
                            <app-custom-filter-container [item]="item" [position]="position" (onFilterChangedListener)="onFilterChangedListener()" >

                            </app-custom-filter-container>
                           

                        </div>
                        <div class="col-auto">

                            <div style="margin-top: 16px;">
                                <button class="btn btn-primary material-icons"
                                    (click)="deleteFilter(position)">delete</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="d-flex w-100">
                    <div style="margin-left: auto;">
                        <button class="btn btn-primary" (click)="addNewFilter()" id="btnAddFilter">
                            <span class="material-icons mr-3" style="vertical-align: bottom;">
                                add
                            </span> Novo Filtro
                        </button>
                    </div>
                </div>

            </div>

        </div>
    </div>

</div>