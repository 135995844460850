export default {
    increasing(list, key_value) {

        var c_list = list;

        try {
            if (c_list.length > 0) {
                c_list.sort(compare);

                function compare(a, b) {
                    if ((key_value ? a[key_value] : a) < (key_value ? b[key_value] : b)) {
                        return -1;
                    }
                    if ((key_value ? a[key_value] : a) > (key_value ? b[key_value] : b)) {
                        return 1;
                    }
                    return 0;
                }

                return c_list;
            }
        } catch (err) {
            console.log(err)
        }

        return [];
    },

    decreasing(list, key_value) {

        var c_list = list;

        try {
            if (c_list.length > 0) {
                c_list.sort(compare);

                function compare(a, b) {
                    if ((key_value ? b[key_value] : b) < (key_value ? a[key_value] : a)) {
                        return -1;
                    }
                    if ((key_value ? b[key_value] : b) > (key_value ? a[key_value] : a)) {
                        return 1;
                    }
                    return 0;
                }

                return c_list;
            }
        } catch (err) {
            console.log(err)
        }

        return [];
    }
}