import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComponentsInfos } from './ComponentsInfos';
import { NewComponentConfig } from './NewComponentConfig';

@Component({
  selector: 'app-modal-new-component',
  templateUrl: './modal-new-component.component.html',
  styleUrls: ['./modal-new-component.component.scss']
})
export class ModalNewComponentComponent implements OnInit {

  @Input() componentConfig: NewComponentConfig;

  @Output() onCloseModal = new EventEmitter();
  @Output() onConfirmComponent = new EventEmitter();

  onSubmitCreate = false;

  constructor() { }

  ngOnInit(): void {

    let type = this.componentConfig.type;

    let componentConfigInfos = ComponentsInfos.find(element => element.type == type);

    this.componentConfig = Object.assign(this.componentConfig, componentConfigInfos);

  }

  setCloseModal() {
    this.onCloseModal.emit();
  }

  onConfirm() {

    this.onSubmitCreate = true;

    if(this.componentConfig.title == ''){
      return;
    }

    this.onConfirmComponent.emit(this.componentConfig);
  }
}
