import { FilterSearch } from "./SearchItens";

//compara nomes para setar ordem alfabética
export class CompareNames {
  
    compare(a, b, sort: boolean){
      const filterSearch = new FilterSearch();

      a = filterSearch.stringNormalize(String(a).toLowerCase());
      b = filterSearch.stringNormalize(String(b).toLowerCase());

      if (!sort) {
        return (a > b) ? -1 : (a < b) ? 1 : 0;
      }
  
      return (a < b) ? -1 : (a > b) ? 1 : 0;
    }
  }