<div class="filter mt-3">
    <label>{{title}}</label>
    <div class="field-select" *ngIf="!loading">
        <select class="fields" (change)="changeSelected($event.target.value)">
            <option value="">Todos</option>
            <ng-container *ngFor="let item of items">
                <option *ngIf="item.showing" [value]="item.id">{{item.name}}</option>
            </ng-container>
        </select>
        <span class="select-range">{{getSelectedLength()}} / {{getShowingLength()}}</span>
        <img class="select-icon" src="../../../assets/icons/arrowDown.svg" alt="Ícone seta para baixo">
    </div>
    <div class="loading" *ngIf="loading">
        <div class="spinner-border spinner-border-sm text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <p>Carregando</p>
    </div>
</div>