import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() data = [];

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  
  goTo(item): void{
    const validLastItem = this.data[this.data.length - 1].path !== item.path;
    if(validLastItem)
      this.router.navigate([item.path]);
  }

}
