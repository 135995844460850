import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-frequency',
  templateUrl: './custom-frequency.component.html',
  styleUrls: ['./custom-frequency.component.scss']
})
export class CustomFrequencyComponent implements OnInit {

  @Input() publishSearchSetup = {
    custom_frequency_list: []
  };

  @Output() onPublishListener = new EventEmitter();


  constructor() { }

  ngOnInit(): void {

    this.publishSearchSetup.custom_frequency_list;

    console.log(this.publishSearchSetup);
  }

  changeWeek(item, week) {
     item.week = week;
  }

  onChangeFrequency(frequency_type, position) {

    this.publishSearchSetup.custom_frequency_list[position].frequency_type = frequency_type;
  }

  deleteFrequency(position) {
    this.publishSearchSetup.custom_frequency_list.splice(position, 1);

    this.onPublishListener.emit(this.publishSearchSetup);
  }

  addNewCustomFrequency() {
    this.publishSearchSetup.custom_frequency_list.push({
      frequency_type: "only_day_of_week",
      week: 1,
      init_day: 1,
      end_day: 5
    })

    this.onPublishListener.emit(this.publishSearchSetup);
  }

  number(value) {
    return Number(value)
  }
}
