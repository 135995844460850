import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-confirm-delete',
  templateUrl: './modal-confirm-delete.component.html',
  styleUrls: ['./modal-confirm-delete.component.scss']
})
export class ModalConfirmDeleteComponent implements OnInit {

  @Input() item: any = {
    name: ""
  };

  @Output() onCloseModal = new EventEmitter();

  @Output() onConfirmListener = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  confirmDelete(){
    this.onConfirmListener.emit();
  }

  
  setCloseModal(){
    this.onCloseModal.emit();
  }

}
