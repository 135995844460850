import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { element } from 'protractor';

@Component({
  selector: 'app-custom-filter-container',
  templateUrl: './custom-filter-container.component.html',
  styleUrls: ['./custom-filter-container.component.scss']
})
export class CustomFilterContainerComponent implements OnInit {

  @Input() item;
  @Input() position;

  @Output() onFilterChangedListener = new EventEmitter();

  @Input() onFilterChange;

  show_data_filter: boolean = false;
  back_list = [];

  constructor(
    private renderer: Renderer2
  ) {

    this.renderer.listen('window', 'click', (event) => {

      if (this.show_data_filter) {

        let buttonOpenData = document.getElementById(`btn_data_filter_${this.position}`);
        let dropFilters = document.getElementById(`drop_filters_${this.position}`);

        if (event.target != buttonOpenData && event.target != dropFilters && !(<HTMLElement>event.target).classList.contains('drop-item')) {

          this.defineFilterList();

          this.closeDataList();

          this.onFilterChangedListener.emit();

        }

      }
    })

    


  }

  ngOnInit(): void {


  }

  

  ngAfterContentInit() {
  }

  defineFilterList() {

    this.item.filter_list = [];

    for (let item of this.back_list) {
      this.item.filter_list.push(item.name);

    }
  }

  showDataList() {

    this.back_list = [];

    for (let c_data of this.item.data) {

      c_data.checked = false;

      if (this.item.filter_list.includes(c_data.name)) {

        c_data.checked = true;
        this.addToBackList(c_data)

      }

    }

    this.show_data_filter = true;

  }

  closeDataList() {
    this.show_data_filter = false;

    this.onFilterChangedListener.emit()
  }

  checkItemDataChecked(checked, item, position) {

    if (checked) {
      this.addToBackList(item);
      return;
    }

    let c_filter_name = this.item.data[position].name;

    let index = this.item.filter_list.indexOf(c_filter_name);

    this.item.filter_list.splice(index, 1);
    this.removeToBackList(c_filter_name);

  }

  addToBackList(c_data) {

    if (!this.back_list.find(element => element.name == c_data.name)) {
      this.back_list.push(c_data);
    }

  }

  removeToBackList(name) {

    let data = this.back_list.find(element => element.name == name);

    if (data) {

      let index = this.back_list.indexOf(data);


      this.back_list.splice(index, 1)
    }

    console.log('back list:', this.back_list);


  }

  removeFilter(position) {
    this.item.filter_list.splice(position, 1);

    this.onFilterChangedListener.emit();
  }
}
