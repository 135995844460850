import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Util } from 'src/app/shared/util';
import { AuthorizationHome } from 'src/app/utils/Authorization';
import { UserService } from '../../services/users/users.service';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  isLoggedIn: boolean = false;
  notification: boolean = false;
  public user: any;
  name: any;
  lastName: any;
  imgUser: string;
  sigla: any;
  dados: any;
  public cookieValue;

  today: number = Date.now();

  constructor(
    private router: Router,
    private userService: UserService,
    private cookieService: CookieService
  ) {
    // this.cookieValue = this.cookieService.get('session.id');
    // console.log(this.cookieValue)
  }

  ngOnInit(): void {
    this.isLoggedIn = Util.verifyLogged();

    let dados = JSON.parse(localStorage.getItem('personal_info'));
    this.dados = dados;
    this.name = dados.personal_info.name.split('');
    this.lastName = dados.personal_info.last_name.split('');
    this.imgUser = dados.personal_info.avatar_url;
    this.verifyIsAuthorization();
    this.getSigla();

  }

  authorization = AuthorizationHome;

  verifyIsAuthorization() {
    let personal_info = JSON.parse(localStorage.getItem('personal_info'));

    let display_value = personal_info.hiear.display_value;

    this.authorization.map((cAuthorization, index) => {

      const { authorized: authorization, whiteList, blackList } = cAuthorization;

      if (whiteList.includes("$all") && !blackList.includes(display_value)) {
        cAuthorization.authorized = true;
      } else if (whiteList.includes(display_value)) {
        cAuthorization.authorized = true;
      }


    })

  }





  getSigla(): void {
    const sigla1 = this.name[0];
    const sigla2 = this.lastName[0];
    this.sigla = sigla1 + sigla2;
  }

  logOut() {
    localStorage.removeItem('workgroup');
    localStorage.removeItem('personal_info');
    localStorage.removeItem('detailvisits');
    this.userService.logout().subscribe((response) => {
      console.log(response);
    });


    // localStorage.removeItem('minalba:access_token');
    this.userService.setIsLogged(false);

    setTimeout(() => {

      this.cookieService.delete('session.id', '/');
      this.cookieService.deleteAll();
      console.log(this.cookieService.getAll());

      location.reload();

    }, 500);
  }

  configs() {
    this.notification = !this.notification;
  }

  clickOutside() {
    this.notification = false;
  }


}
