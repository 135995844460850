<app-modal [cancelable]="true" (closeModal)="setCloseModal()" title="Itens coletados" widthContent="1000">

    <div>
        <h1>Detalhes da coleta</h1>
    </div>

    <app-paginated-table [tableConfig]="table_config" [loadingData]="loadingTable"
        (onExportDataCreated)="onExportDataCreated($event)" [exportConfig]="exportConfig"
        (onExportEvent)="exportTable($event)"></app-paginated-table>

</app-modal>