<div class="w-100">

    <h5 class="card-title">Condicionais</h5>

    <div class="d-flex">
        <div>
            <ng-container *ngFor="let item of publishSearchSetup.custom_frequency_list; let position = index">
                <div class="row mb-3">
                    <div class="col-auto">
                        <span>Semana</span>
                        <div class="mt-2">
                            <select name="" id="" value="{{item.week}}" (change)="changeWeek(item,number($event.target.value))">
                                <option value="-1">Todas as semanas</option>
                                <option value="1">Semana 1</option>
                                <option value="2">Semana 2</option>
                                <option value="3">Semana 3</option>
                                <option value="4">Semana 4</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-auto">
                        <span>Dia da semana</span>
                        <div class="mt-2">
                            <select name="" id="" value="{{item.init_day}}"
                                (change)="item.init_day = number($event.target.value)">
                                <option value="0">Domingo</option>
                                <option value="1">Segunda</option>
                                <option value="2">Terça</option>
                                <option value="3">Quarta</option>
                                <option value="4">Quinta</option>
                                <option value="5">Sexta</option>
                                <option value="6">Sábado</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-auto">
                        <span>Frequencia</span>
                        <div class="mt-2">
                            <select name="" value="{{item.frequency_type}}"
                                (change)="onChangeFrequency($event.target.value,position)">
                                <option value="only_day_of_week">Somente um dia</option>
                                <option value="range_day_of_week">Até o dia</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div *ngIf="item.frequency_type == 'range_day_of_week'">
                            <span>Dia da semana</span>
                            <div class="mt-2">
                                <select name="" id="" value="{{item.end_day}}"
                                    (change)="item.end_day = number($event.target.value)">
                                    <option value="0">Domingo</option>
                                    <option value="1">Segunda</option>
                                    <option value="2">Terça</option>
                                    <option value="3">Quarta</option>
                                    <option value="4">Quinta</option>
                                    <option value="5">Sexta</option>
                                    <option value="6">Sábado</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">

                        <div style="margin-top: 32px;">
                            <button class="btn btn-primary material-icons"
                                (click)="deleteFrequency(position)">delete</button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="d-flex w-100">
                <div style="margin-left: auto;">
                    <button class="btn btn-primary" (click)="addNewCustomFrequency()">
                        <span class="material-icons mr-3" style="vertical-align: bottom;">
                            add
                        </span> Nova Frequencia
                    </button>
                </div>
            </div>

        </div>

    </div>

</div>