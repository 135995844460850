<app-modal [cancelable]="false" (closeModal)="setCloseModalExportFiles()" title="Gerando Arquivos" widhContent="400">

    <h3 class="mb-3">Gerando documentos de exportação power points</h3>
    <div class="d-flex mb-3">

        <div class="d-flex">
            <div class="spinner-border text-primary mr-3 mt-auto mb-auto" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <p class="mt-auto mb-auto">Isso pode demorar vários segundos.</p>
        </div>

        <h2 class="ml-auto">Progresso {{exportProgress}}/{{exportSize}}</h2>
    </div>
    <div class="modal-content p-3">

        <div class="document-progress w-100" style="height: 300px;overflow: auto;">
            <div class="d-flex mb-3" *ngFor="let item of exportNames">
                <div *ngIf="!checkAllStatusOkay(item)" class="spinner-border text-primary mr-2" role="status"
                    style="height: 24px;width: 24px;">
                    <span class="sr-only">Loading...</span>
                </div>
                <span *ngIf="checkAllStatusOkay(item)" class="material-icons mt-auto mb-auto mr-2"
                    style="font-size:24px;color:green">
                    check_circle
                </span>
                <p class="mt-auto mb-auto">{{item}}</p>

            </div>

        </div>

    </div>

    <div class="d-flex mt-3">
        <button class="btn btn-danger ml-auto" (click)="onAbortExportFiles()">
            Abortar
        </button>
    </div>
</app-modal>