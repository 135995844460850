import { NewComponentConfig } from "./NewComponentConfig";

export const ComponentsInfos: NewComponentConfig[] = [
    {
        type: "question_check",
        title: "Pergunta Controle Deslizante (Sim / Não)",
        description: ""
    },
    {
        type: "input_number",
        title: "Pergunta de Número",
        description: ""
    },
    {
        type: "input_price",
        title: "Pergunta de Preço",
        description: ""
    },
    {
        type: "input_text",
        title: "Pergunta de Texto",
        description: ""
    },
    // {
    //     type: "drop_down_items",
    //     title: "Pergunta de seleção Única",
    //     description: ""
    // },
    {
        type: "pictures",
        title: "Pergunta de imagens",
        description: ""
    },
    {
        type: "calendar",
        title: "Pergunta de data",
        description: ""
    },
]