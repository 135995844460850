<div *ngFor="let component of components; let position = index;">
    
    <ng-container *ngIf="component.type == 'rule_component'">
        <app-conditional [component]="component" [position]="position">

        </app-conditional>
    </ng-container>

    <ng-container *ngIf="component.type == 'container'">
        <app-container [component]="component" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback">

        </app-container>
    </ng-container>

    <ng-container *ngIf="component.type == 'description'">
        <app-description [component]="component" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback">

        </app-description>
    </ng-container>

    <ng-container *ngIf="component.type == 'title'">
        <app-title [component]="component" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback">

        </app-title>
    </ng-container>

    <ng-container *ngIf="component.type == 'drop_down_points'">
        <app-drop-down-points [component]="component" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback">

        </app-drop-down-points>
    </ng-container>

    <ng-container *ngIf="component.type == 'drop_down_items'">
        <app-drop-down-items [component]="component" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback">

        </app-drop-down-items>
    </ng-container>

    <ng-container *ngIf="component.type == 'input_text'">
        <app-input-text [component]="component" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback">

        </app-input-text>
    </ng-container>

    <ng-container *ngIf="component.type == 'input_number'">
        <app-input-number [component]="component" [block]="block" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback">

        </app-input-number>
    </ng-container>

    <ng-container *ngIf="component.type == 'input_price'">
        <app-input-price [component]="component" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback">

        </app-input-price>
    </ng-container>

    <ng-container *ngIf="component.type == 'input_area'">
        <app-text-area [component]="component" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback">

        </app-text-area>
    </ng-container>

    <ng-container *ngIf="component.type == 'question_check'">
        <app-question-check [component]="component" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback" [allComponentsId]="allComponentsId">

        </app-question-check>
    </ng-container>

    <ng-container *ngIf="component.type == 'pictures'">
        <app-pictures [component]="component" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback">

        </app-pictures>
    </ng-container>

    <ng-container *ngIf="component.type == 'products_list'">
        <app-products-list [component]="component" [position]="position">

        </app-products-list>
    </ng-container>

    <!-- <ng-container *ngIf="component.type == 'toggle_component'" >
        <app-products-list [component]="component" [position]="position" [emmitUpdates]="true" [componentUpdateCallback]="componentUpdateCallback" [allComponentsId]="allComponentsId">

        </app-products-list>
    </ng-container> -->

    <div class="container_components" *ngIf="component.components">
        <app-component-view-manager [components]="component.components" [block]="block" [position]="position" [emmitUpdates]="true"
            [componentUpdateCallback]="componentUpdateCallback" [allComponentsId]="allComponentsId">

        </app-component-view-manager>
    </div>



</div>